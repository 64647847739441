import { functionsApp } from "../firebase";
import {
    chooseAmongOptionsRandomly,
    getBase64,
    temporaryAsyncMaker,
} from "../utils/functions";
import { dummyMessages } from "./dummy/DummyData";
import { ProjectInformation } from "./Project";
import {
    AdminLevel,
    AdminLevelSummary,
    PostAdminLevel,
    PostLevelStep,
} from "./ProjectInterfaces";

/** Just a placeholder for now */
export interface Message {
    hour: string;
    sender: string;
    message: string;
}

/** The project summary of a project, with its image, title and id */
export interface ProjectSummary {
    imageUrl: string;
    title: StringTranslation;
    id: number;
}

/** The default language is catalan and must be there. Other languages are possible */
export interface StringTranslation {
    catalan: {
        language: string;
        text: string;
    };
    [key: string]: {
        language: string;
        text: string;
    };
}

/** Used to do API Calls */
export class ApiService {
    /** Returns a bunch of messages randomly. Not correctly implemented yet */
    static async getOutstandingMessages(): Promise<Array<Message>> {
        const desiredResult = chooseAmongOptionsRandomly([[], dummyMessages]);

        return await temporaryAsyncMaker(desiredResult);
    }

    /** Returns a bunch of projects from dummies. Not correctly implemented yet */
    /*     static async getListProjects(): Promise<Array<ProjectSummary>> {
        const listProjects = dummyProjectSummaries;
        return await temporaryAsyncMaker(listProjects);
    }
 */
    /** Tries to post a new project.
     * TODO: The error should be managed in the component
     * @returns Returns "success" or "error" depending on the response from the API   */
    static async postNewProject(project: ProjectInformation) {
        let succeded = false;
        await functionsApp
            .httpsCallable("projects-postProject")(project)
            .then((res) => {
                succeded = true;
            })
            .catch((err) => {
                succeded = false;
            });

        return succeded ? "success" : "error";
    }

    /** Returns a list of all the projects information.
     * TODO: Add some pagination control    */
    static async getListAllProjects(): Promise<ProjectInformation[]> {
        return await functionsApp
            .httpsCallable("projects-getAllProjectsInformation")()
            .then((res) => {
                return res.data;
            });
    }

    /** Returns the project information of a project from its id */
    static async getProjectInformationFromId(id: string) {
        const downloadedProject: ProjectInformation = await functionsApp
            .httpsCallable("projects-getProjectInformation")({ id: id })
            .then((res) => {
                return res.data;
            });

        return downloadedProject;
    }

    /** Returns the level details of a level
     * @param projectId The id of the project to which it belongs
     * @param levelId The id of the level     */
    static async getLevelDetails(projectId: string, levelId: string) {
        const downloadedLevel: AdminLevel = await functionsApp
            .httpsCallable("levels-getLevelDetails")({
                projectId: projectId,
                levelId: levelId,
            })
            .then((res) => {
                return res.data;
            });

        return downloadedLevel;
    }

    /**  Posts a dummy level, used to test things
     * @param projectId The project to which the level will be uploaded @deprecated   */
    static async temporalPostDummyLevel(projectId: string) {
        await functionsApp.httpsCallable("levels-postDummyLevel")({
            projectId: projectId,
        });
    }

    /** Overrides the previous levelSteps of a particular level.  */
    static async postAllLevelStepUpdates(
        projectId: string,
        levelId: string,
        levelSteps: PostLevelStep[]
    ) {
        await functionsApp.httpsCallable("levels-postLevelStepsUpdate")({
            projectId: projectId,
            levelId: levelId,
            levelSteps: levelSteps,
        });
        return;
    }

    /** Finds the particular levelStep of a level and only overrides it, leaving
     * the rest untouched */
    static async postLevelStep(
        projectId: string,
        levelId: string,
        levelStep: PostLevelStep
    ) {
        await functionsApp.httpsCallable("levels-postLevelStep")({
            projectId: projectId,
            levelId: levelId,
            levelStep: levelStep,
        });
    }

    /** Every project has common images. Gets the list of their names */
    static async getProjectCommonImagesNames(projectId: string) {
        const response = await functionsApp.httpsCallable(
            "projects-getProjectCommonImagesNames"
        )({
            projectId: projectId,
        });
        return response.data as string[];
    }

    /** Every project has common images. Posts a new image.
     * @param image Can be a File or a base64 string. If it's a file, it will convert it
     * @param projectId The project that the image belongs to
     * @param fileName The name of the file. Must have the correspondant extension.
     * For example: ```"bot_background.png"```
     */
    static async postProjectCommonImage(
        image: File | string,
        projectId: string,
        fileName: string
    ) {
        let base64Image: string;
        if (image instanceof File) {
            base64Image = await getBase64(image);
        } else base64Image = image;

        const response = await functionsApp.httpsCallable(
            "projects-postProjectCommonImage"
        )({
            image: base64Image,
            projectId: projectId,
            fileName: fileName,
        });

        return "Image uploaded";
    }

    /** Every project has common images. Gets the image passed on the params
     * @param projectId The id of the project to which the image belongs
     * @param filename Must include the extension. For example ```"bot_background2.png"```
     * @returns A base64 image string. It will throw if it does not exist
     */
    static async getProjectCommonImage(projectId: string, filename: string) {
        const response = await functionsApp.httpsCallable(
            "projects-getProjectCommonImage"
        )({
            projectId: projectId,
            fileName: filename,
        });
        return response.data;
    }

    /** Gets a file that belongs to a determined level.
     * @param projectId @param levelId @param stepId
     * @param filename Must include the extension. For example ```"bot_background2.png"```
     * @returns A base64 file string.
     * @throws If the file does not exist
     */
    static async getStepFile(
        projectId: string,
        levelId: string,
        stepId: string,
        filename: string
    ) {
        const response = await functionsApp.httpsCallable("levels-getStepFile")(
            {
                projectId: projectId,
                filename: filename,
                levelId: levelId,
                stepId: stepId,
            }
        );
        return response.data;
    }

    /** It will override all the level summaries of the project with the passed on the params
     * @param projectId The project to which the change will be applied
     * @param levels An object whose properties names are the level ids and its content
     * the AdminLevelSummary. Will override the previous ones. */
    static async postUpdateMultipleLevelSummaries(
        projectId: string,
        levels: { [key: string]: AdminLevelSummary }
    ) {
        const response = await functionsApp.httpsCallable(
            "projects-postUpdateMultipleLevelSummaryOnProject"
        )({
            projectId: projectId,
            levels: levels,
        });
        return response.data;
    }

    /** Posts a new level to a project */
    static async postNewLevel(projectId: string, level: PostAdminLevel) {
        const response = await functionsApp.httpsCallable(
            "levels-postNewLevel"
        )({
            projectId: projectId,
            level: level,
        });
        return response.data;
    }

    /** Posts a new level description to an existing level. Although an AdminLevel
     * is provided on the params, only the competences, instructorInfo and title properties
     * will be used and updated
     * TODO: Only accept the params that will be used */
    static async postLevelDescriptionUpdate(
        projectId: string,
        levelId: string,
        level: AdminLevel
    ) {
        delete level.steps;
        const response = await functionsApp.httpsCallable(
            "levels-postLevelDescriptionUpdate"
        )({
            projectId: projectId,
            levelId: levelId,
            level: level,
        });
        return response.data;
    }

    /** Posts a new step file to an exiting step.
     * TODO: Accept both strings and files in the param base64File
     */
    static async postStepFile(
        projectId: string,
        levelId: string,
        stepId: string,
        base64File: string,
        filename: string
    ) {
        const response = await functionsApp.httpsCallable(
            "levels-postLevelStepFile"
        )({
            projectId: projectId,
            levelId: levelId,
            stepId: stepId,
            base64File: base64File,
            filename: filename,
        });
        return response.data;
    }
}
