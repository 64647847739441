import {
    BackCardDataProps,
    FrontCardDataProps,
} from "../pages/administration/projects/newProject/AdminNewProject";
import {
    arrayToObjectKeysWithDefaultValue,
    getBase64,
} from "../utils/functions";
import { ApiService } from "./ApiService";
import { AdminLevelSummary, MultiLanguage } from "./ProjectInterfaces";

export interface ProjectInformation {
    /**
     * TODO: Id should not be optional at all */
    id?: string;
    projectName: MultiLanguage<string>;

    description: {
        shortDescription: MultiLanguage<string>;
        longDescription: MultiLanguage<string>;
    };
    /** Temporary property. Should be changed to base64Images when uploading */
    fileImages?: {
        summaryImage: File;
        backgroundImage: File;
    };

    base64Images?: {
        summaryImage: string;
        backgroundImage: string;
    };

    /** The url will expire in a few days, so they are always temporary */
    urlImages?: {
        summaryImage: string;
        backgroundImage: string;
    };

    /** Stores the reference to the Google Storage location of the file */
    _storageImages?: {
        summaryImage: string;
        backgroundImage: string;
    };

    minVersion: number;
    difficulty: number;
    type: "robotics" | "programming" | "robotics and programming";
    roboticsTypes: string[];
    programmingTypes: string[];
    recommendedAges: [number, number];
    languages: {
        defaultLanguage: string;
        possibleLanguages: {
            [key: string]: "ok" | "pending";
        };
    };
    levels?: {
        [key: string]: AdminLevelSummary;
    };
}

/** ProjectDataTable are used to show the summary of the projects in a table format. Only
 * have some of the details of the project */
export interface ProjectDataTable {
    id?: string;
    /** The project name is a string because it should already be in the correct language */
    projectName: string;
    /**
     * TODO: Should be a [number, number], not a string */
    recommendedAges: string;
    type: "robotics" | "programming" | "robotics and programming";
    roboticsProgrammingTypes: string;
}

export class Project implements ProjectInformation {
    id?: string;
    projectName: MultiLanguage<string>;
    description: {
        shortDescription: MultiLanguage<string>;
        longDescription: MultiLanguage<string>;
    };
    fileImages?: { summaryImage: File; backgroundImage: File };
    base64Images?: {
        summaryImage: string;
        backgroundImage: string;
    };
    urlImages?: {
        summaryImage: string;
        backgroundImage: string;
    };

    minVersion: number;
    type: "robotics" | "programming" | "robotics and programming";
    recommendedAges: [number, number];
    languages: {
        defaultLanguage: string;
        possibleLanguages: { [key: string]: "ok" | "pending" };
    };
    difficulty: number;
    roboticsTypes: string[];
    programmingTypes: string[];
    levels?: {
        [key: string]: AdminLevelSummary;
    };

    constructor(project: ProjectInformation) {
        this.id = project.id;
        this.projectName = project.projectName;
        this.description = project.description;
        this.fileImages = project.fileImages;
        this.base64Images = project.base64Images;
        this.urlImages = project.urlImages;
        this.minVersion = project.minVersion;
        this.type = project.type;
        this.recommendedAges = project.recommendedAges;
        this.languages = project.languages;
        this.difficulty = project.difficulty;
        this.roboticsTypes = project.roboticsTypes;
        this.programmingTypes = project.programmingTypes;
        this.levels = project.levels;
    }

    /** Used to create a Project class from a form.
     * TODO: Id should not be optional at all
     */
    static fromForm(
        frontCardData: FrontCardDataProps,
        backCardData: BackCardDataProps
    ) {
        return new this({
            projectName: {
                [backCardData.defaultLanguage]: frontCardData.projectName,
            },
            description: {
                shortDescription: {
                    [backCardData.defaultLanguage]:
                        backCardData.projectShortDescription,
                },
                longDescription: {
                    [backCardData.defaultLanguage]:
                        backCardData.projectLongDescription,
                },
            },
            fileImages: {
                backgroundImage: frontCardData.backgroundImage[0],
                summaryImage: frontCardData.summaryImage[0],
            },
            minVersion: +backCardData.minVersion,
            type: backCardData.projectType,
            recommendedAges: backCardData.recommendedAges,
            languages: {
                defaultLanguage: backCardData.defaultLanguage,
                possibleLanguages: arrayToObjectKeysWithDefaultValue(
                    frontCardData.possibleLanguages,
                    "pending"
                ),
            },
            difficulty: backCardData.difficulty,
            roboticsTypes: backCardData.roboticsTypes,
            programmingTypes: backCardData.programmingTypes,
            levels: {},
        });
    }

    /** Will try to upload the images to firebase after converting the images to base64 */
    async uploadToFirebase() {
        await this.convertImagesToBase64AndDeleteFiles();
        return await ApiService.postNewProject(this);
    }

    /** If the project has fileImages, it will transform them to base64 and delete the files */
    async convertImagesToBase64AndDeleteFiles() {
        if (this.fileImages) {
            const backgroundImagePromise = getBase64(
                this.fileImages.backgroundImage
            );
            const summaryImagePromise = getBase64(this.fileImages.summaryImage);

            const [newBackgroundImage, newSummaryImage] = await Promise.all([
                backgroundImagePromise,
                summaryImagePromise,
            ]);
            if (
                typeof newBackgroundImage === "string" &&
                typeof newSummaryImage === "string"
            )
                this.base64Images = {
                    backgroundImage: newBackgroundImage,
                    summaryImage: newSummaryImage,
                };
            delete this.fileImages;
        }
    }

    /** Returns a ProjectDataTable with the asked for language
     * @param language: The wished language */
    getDataTableFormat(language: string): ProjectDataTable {
        let projectDataTable: ProjectDataTable = {
            projectName:
                this.projectName[language] ??
                this.projectName[this.languages.defaultLanguage],
            recommendedAges: `${this.recommendedAges[0]} - ${this.recommendedAges[1]}`,
            roboticsProgrammingTypes:
                this.roboticsTypes.join(", ") +
                ", " +
                this.programmingTypes.join(", "),
            type: this.type,
            id: this.id,
        };
        return projectDataTable;
    }
}
