import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ApiService } from "../../../../../../../apis/ApiService";
import {
    PostFileUploadData,
    PostLevelStep,
} from "../../../../../../../apis/ProjectInterfaces";
import { FileInput } from "../../../../../../../components/ui/input/fileInput/FileInput";
import {
    SelectInput,
    SelectOptionProps,
} from "../../../../../../../components/ui/input/selectInput/SelectInput";
import { TextInput } from "../../../../../../../components/ui/input/textInput/TextInput";
import { Loading } from "../../../../../../../components/ui/loading/Loading";
import { checkObjectEquality } from "../../../../../../../utils/functions";
import { LoadingSaveInputDiscardButton } from "../../StepModal";

import styles from "./FileUploadCreator.module.css";

let currentFile: any;
let currentSelectedImage: string;

export const FileUploadCreator = (props: {
    step: PostLevelStep;
    mandatoryLanguages: string[];
    possibleLanguages: string[];
    onSend: (newStep: PostLevelStep) => Promise<boolean>;
    onChanges: (newChanges: boolean) => void;
}) => {
    const { projectId } = useParams<{ projectId: string }>();
    const { mandatoryLanguages, possibleLanguages, onSend, onChanges } = props;

    const [imageOptions, setImageOptions] = useState<string[]>([]);
    const [imageUploadResult, setImageUploadResult] = useState(<p></p>);
    const [imagePreview, setImagePreview] = useState<string>();

    const [uploaded, setUploaded] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [changes, setChanges] = useState(false);

    const { t } = useTranslation();

    const imageForm = useForm();
    const form = useForm({
        mode: "onBlur",
    });

    let { step } = props;

    const onDiscard = () => {
        form.reset();
        if (changes) {
            setChanges(false);
            onChanges(false);
        }
    };
    const onSubmit = form.handleSubmit(onSave);

    async function updateImageOptions() {
        try {
            await ApiService.getProjectCommonImagesNames(projectId).then(
                (res) => {
                    setImageOptions(res);
                }
            );
        } catch {
            setImageUploadResult(
                <p className="error">
                    {t("The image obtention service does not work")}
                </p>
            );
        }
    }

    function checkChanges() {
        formatFileInputCreatorResults(form.watch()).then((results: any) => {
            if (
                !checkObjectEquality(step.data, results) &&
                results !== undefined &&
                !changes
            ) {
                setChanges(true);
                onChanges(true);
            } else if (checkObjectEquality(step.data, results) && changes) {
                setChanges(false);
                onChanges(false);
            }
        });
    }

    async function onSave(data: any) {
        if (uploaded || uploading) return;
        setUploading(true);
        let newData = await formatFileInputCreatorResults(data);
        let newStep: PostLevelStep = {
            type: step.type,
            data: newData as any,
            id: step.id,
        };
        const success = await onSend(newStep);
        if (success) setUploaded(true);
        setUploading(false);
    }

    function getSelectOptions(options: string[]): SelectOptionProps[] {
        return options.map((val) => {
            return {
                display: val,
                value: val,
            };
        });
    }

    useEffect(() => {
        updateImageOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (step.type !== "FileUpload") return <> </>;

    if (imageForm.watch("image") && imageForm.watch("image") !== currentFile) {
        currentFile = imageForm.watch("image");

        let file = currentFile[0] as File;

        ApiService.postProjectCommonImage(file, projectId, file.name)
            .then((res) => {
                setImageUploadResult(<p>{res}</p>);
                updateImageOptions();
            })
            .catch((err) =>
                setImageUploadResult(<p className="error">{err}</p>)
            )
            .finally(() => imageForm.reset());
    }

    if (form.watch("imageSelect") !== currentSelectedImage) {
        currentSelectedImage = form.watch("imageSelect");
        if (currentSelectedImage !== "")
            ApiService.getProjectCommonImage(projectId, currentSelectedImage)
                .then((res) => setImagePreview(res))
                .catch(() => {});
    }

    checkChanges();

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit}>
                <h2>{t("Message")}</h2>
                <div className={styles.textInputsContainer}>
                    {possibleLanguages.map((lang, ind) => (
                        <div
                            key={ind}
                            className={styles.contentTextInputContainer}
                        >
                            <label>{t(lang)}</label>
                            <TextInput
                                name={`${lang}Text`}
                                defaultValue={
                                    step.type === "FileUpload"
                                        ? step.data.message[lang]
                                        : ""
                                }
                                mandatory={mandatoryLanguages.includes(lang)}
                                multiline
                                rows={3}
                                key={ind}
                            />
                        </div>
                    ))}
                </div>
                <h2>{t("Image")}</h2>
                <div className={styles.imageGroupContainer}>
                    <div className={styles.imageGroupContainerFirstRow}>
                        <div className={styles.imageSelectContainer}>
                            <SelectInput
                                name="imageSelect"
                                options={getSelectOptions(imageOptions)}
                                box
                                defaultValue={step.data.imageName}
                            />
                        </div>

                        <FormProvider {...imageForm}>
                            <form className={styles.imageInputContainer}>
                                <FileInput
                                    height={50}
                                    inputProps={{
                                        name: "image",
                                        accept: "image/jpeg, image/png",
                                    }}
                                    button
                                    className={styles.imageInput}
                                    placeholder={t("Upload a file")}
                                />
                            </form>
                        </FormProvider>
                        <div className={styles.imageUploadResult}>
                            {imageUploadResult}
                        </div>
                    </div>
                    {imagePreview ? (
                        <img
                            src={imagePreview}
                            className={styles.imgPreview}
                            alt="Selected preview"
                        />
                    ) : currentSelectedImage !== "" && currentSelectedImage ? (
                        <Loading />
                    ) : undefined}
                </div>
                <LoadingSaveInputDiscardButton
                    onDiscard={onDiscard}
                    loadingVisible={uploading}
                    saveVisible={changes}
                />
            </form>
        </FormProvider>
    );
};

async function formatFileInputCreatorResults(results: { [key: string]: any }) {
    let data: PostFileUploadData;
    data = {
        message: {},
    };

    if (JSON.stringify(results) === "{}") return;

    for (const key in results) {
        if (results[key] instanceof File) return;
        if (key.search("Text") !== -1) {
            let lan = key.slice(0, key.search("Text"));
            if (results[lan + "Text"] !== "")
                data.message[lan] = results[lan + "Text"];
        }
    }
    data.imageName = results["imageSelect"];
    return data;
}
