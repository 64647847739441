import { Message, ProjectSummary } from "../ApiService";
import { ProjectInformation } from "../Project";
import { AdminLevelSummary, PostLevelStep } from "../ProjectInterfaces";
import { Subscription } from "../User";

export const dummyMessages: Array<Message> = [
    {
        hour: "17:00",
        message: "El meu fill està malalt i no vindrà",
        sender: "Patricia",
    },
];
/* 
export const dummyProjectSummaries: Array<ProjectSummary> = [
    {
        imageUrl:
            "https://s3.amazonaws.com/fllcasts/content_pictures/pictures/000/001/516/4c772ae432379736100a2cff1ec4e771cb7ea68eLego-Mindstorms-Motorcycle-Sports-Robot-Ev3-Fllcasts.png?1521639736",
        title: {
            catalan: {
                language: "catalan",
                text: "Construïm una motocicleta!",
            },
            spanish: {
                language: "spanish",
                text: "Construimos una moto!",
            },
        },
        id: 0,
    },
    {
        imageUrl:
            "https://i.pinimg.com/736x/a8/f6/b9/a8f6b9bcff757bdf5be54c01967e65b7.jpg",
        title: {
            catalan: {
                language: "catalan",
                text: "Marxem cap a l'espai exterior!",
            },
            spanish: {
                language: "spanish",
                text: "Nos vamos al espacio exterior!",
            },
        },
        id: 0,
    },
    {
        imageUrl:
            "https://s3.amazonaws.com/fllcasts/content_pictures/pictures/000/001/516/4c772ae432379736100a2cff1ec4e771cb7ea68eLego-Mindstorms-Motorcycle-Sports-Robot-Ev3-Fllcasts.png?1521639736",
        title: {
            catalan: {
                language: "catalan",
                text: "Construïm una motocicleta!",
            },
            spanish: {
                language: "spanish",
                text: "Construimos una moto!",
            },
        },
        id: 0,
    },
    {
        imageUrl:
            "https://i.pinimg.com/736x/a8/f6/b9/a8f6b9bcff757bdf5be54c01967e65b7.jpg",
        title: {
            catalan: {
                language: "catalan",
                text: "Marxem cap a l'espai exterior!",
            },
            spanish: {
                language: "spanish",
                text: "Nos vamos al espacio exterior!",
            },
        },
        id: 0,
    },
    {
        imageUrl:
            "https://s3.amazonaws.com/fllcasts/content_pictures/pictures/000/001/516/4c772ae432379736100a2cff1ec4e771cb7ea68eLego-Mindstorms-Motorcycle-Sports-Robot-Ev3-Fllcasts.png?1521639736",
        title: {
            catalan: {
                language: "catalan",
                text: "Construïm una motocicleta!",
            },
            spanish: {
                language: "spanish",
                text: "Construimos una moto!",
            },
        },
        id: 0,
    },
    {
        imageUrl:
            "https://i.pinimg.com/736x/a8/f6/b9/a8f6b9bcff757bdf5be54c01967e65b7.jpg",
        title: {
            catalan: {
                language: "catalan",
                text: "Marxem cap a l'espai exterior!",
            },
            spanish: {
                language: "spanish",
                text: "Nos vamos al espacio exterior!",
            },
        },
        id: 0,
    },
];

export const dummyLanguages = ["spanish", "catalan", "norwegan"];

export const levelsDummy: AdminLevelSummary[] = [
    {
        id: "fasidfnasdf-fasndfz-qasd",
        number: 1,
        title: { catalan: "Anem a l'espai exterior!" },
    },
    {
        number: 2,
        id: "casasidfnasdf-fasndfz-qasd",
        title: { catalan: "Anem a l'espai exterior!" },
    },
    {
        number: 3,
        id: "qqweidfnasdf-fasndfz-qasd",
        title: { catalan: "Anem a l'espai exterior!" },
    },
    {
        number: 4,
        id: "bzxcsidfnasdf-fasndfz-qasd",
        title: { catalan: "Anem a l'espai exterior!" },
    },
    {
        number: 5,
        id: "mpuidfnasdf-fasndfz-qasd",
        title: { catalan: "Anem a l'espai exterior!" },
    },
    {
        number: 6,
        id: "lghjdfnasdf-fasndfz-qasd",
        title: { catalan: "Anem a l'espai exterior!" },
    },
    {
        number: 7,
        id: "qweridfnasdf-fasndfz-qasd",
        title: { catalan: "Anem a l'espai exterior!" },
    },
    {
        number: 8,
        id: "zxcdfnasdf-fasndfz-qasd",
        title: { catalan: "Anem a l'espai exterior!" },
    },
    {
        number: 9,
        id: "apwidfnasdf-fasndfz-qasd",
        title: { catalan: "Anem a l'espai exterior!" },
    },
];

export const dummySteps: PostLevelStep[] = [
    {
        type: "SimpleText",
        id: "fasdf11a9q",
        data: {
            text: {
                catalan:
                    "Hola, em dic David i sóc el millor programador del món!",
                english:
                    "Hi, my name is David and I am the best programmer in the world",
                french: "Salut, asdfasdfasdfasdfasdfadsf",
                basque:
                    "Kaixo, David dut izena eta munduko programatzailerik onena naiz",
            },
            imageUrl:
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F1_1x.png?alt=media&token=c4c04a37-97bf-444f-85be-9266549cfb0a",
            audioUrl: {
                catalan:
                    "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/audioTest%2Fcatala.mp3?alt=media&token=eb132dab-93df-4fd8-988d-8bfb4683548c",
                spanish:
                    "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",

                english:
                    "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_2MG.mp3",

                basque:
                    "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3",

                galician:
                    "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3",
            },
        },
    },
    {
        type: "SimpleVideo",
        id: "fasdf13asdfqewr",
        data: {
            videos: [
                {
                    language: "catalan",
                    subtitles: "catalan",
                    url: `https://mega.nz/embed/MFkCXayT#DGQ0SAWzGPVgFafROu_YNec56Vqhd-5R_NafmPkx2MM`,
                },
                {
                    language: "spanish",
                    subtitles: "spanish",
                    url: `https://mega.nz/embed/MFkCXayT#DGQ0SAWzGPVgFafROu_YNec56Vqhd-5R_NafmPkx2MM`,
                },
                {
                    language: "galician",
                    subtitles: "galician",
                    url: `https://mega.nz/embed/MFkCXayT#DGQ0SAWzGPVgFafROu_YNec56Vqhd-5R_NafmPkx2MM`,
                },
            ],
        },
    },
    {
        type: "ImageOrVideoAttachment",
        id: "fvaos1",
        data: {
            video: `https://mega.nz/embed/MFkCXayT#DGQ0SAWzGPVgFafROu_YNec56Vqhd-5R_NafmPkx2MM`,
        },
    },
    {
        type: "SimpleInstructions",
        id: "jhvzxcv14",
        data: {
            imageGallery: [
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F1_1x.png?alt=media&token=c4c04a37-97bf-444f-85be-9266549cfb0a",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F2_1x.png?alt=media&token=46437775-f31d-4725-bb0c-f5937fedaadc",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F3_1x.png?alt=media&token=2abc5b7b-dbe5-4ec0-bc84-5777a83f7c53",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F4_1x.png?alt=media&token=fa16d4d5-ba48-48f8-a9b5-27e78491f436",
            ],
        },
    },
    {
        type: "SimpleInstructions",
        id: "pqwernvzx",
        data: {
            imageGallery: [
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F1_1x.png?alt=media&token=c4c04a37-97bf-444f-85be-9266549cfb0a",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F2_1x.png?alt=media&token=46437775-f31d-4725-bb0c-f5937fedaadc",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F3_1x.png?alt=media&token=2abc5b7b-dbe5-4ec0-bc84-5777a83f7c53",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F4_1x.png?alt=media&token=fa16d4d5-ba48-48f8-a9b5-27e78491f436",
            ],
        },
    },
    {
        type: "SimpleInstructions",
        id: "zpqewr",
        data: {
            imageGallery: [
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F1_1x.png?alt=media&token=c4c04a37-97bf-444f-85be-9266549cfb0a",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F2_1x.png?alt=media&token=46437775-f31d-4725-bb0c-f5937fedaadc",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F3_1x.png?alt=media&token=2abc5b7b-dbe5-4ec0-bc84-5777a83f7c53",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F4_1x.png?alt=media&token=fa16d4d5-ba48-48f8-a9b5-27e78491f436",
            ],
        },
    },
    {
        type: "SimpleInstructions",
        id: "aq134n123m",
        data: {
            imageGallery: [
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F1_1x.png?alt=media&token=c4c04a37-97bf-444f-85be-9266549cfb0a",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F2_1x.png?alt=media&token=46437775-f31d-4725-bb0c-f5937fedaadc",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F3_1x.png?alt=media&token=2abc5b7b-dbe5-4ec0-bc84-5777a83f7c53",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F4_1x.png?alt=media&token=fa16d4d5-ba48-48f8-a9b5-27e78491f436",
            ],
        },
    },
    {
        type: "SimpleInstructions",
        id: "m1nfn1pvp",
        data: {
            imageGallery: [
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F1_1x.png?alt=media&token=c4c04a37-97bf-444f-85be-9266549cfb0a",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F2_1x.png?alt=media&token=46437775-f31d-4725-bb0c-f5937fedaadc",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F3_1x.png?alt=media&token=2abc5b7b-dbe5-4ec0-bc84-5777a83f7c53",
                "https://firebasestorage.googleapis.com/v0/b/roboclick-15109.appspot.com/o/InstructionsGallery%2F4_1x.png?alt=media&token=fa16d4d5-ba48-48f8-a9b5-27e78491f436",
            ],
        },
    },
];

export const dummySubscription = new Subscription({
    creationDate: new Date(),
    startingDate: new Date(Date.now()),
    paymentType: "recurrent",
    subscriptionId: "fauishdfopaishdufasd",
    product: ["papapa"],
    type: "extraescolar",
    price: 14,
    state: "active",
    finishingDate: new Date(Date.now() + 12096e5 + 12096e5),
    period: "month",
    lastPaymentDate: new Date(Date.now()),
});

export const dummyProjectInformationArray: ProjectInformation[] = [
    {
        minVersion: 1,
        languages: {
            possibleLanguages: {
                spanish: "pending",
                catalan: "pending",
                english: "pending",
            },
            defaultLanguage: "catalan",
        },
        description: {
            longDescription: {
                catalan:
                    "Ens convertirem en els millors astronautes del mon i construirem un munt de robots que ens permetran aconseguir el nostre objectiu: arribar a la Lluna!",
            },
            shortDescription: {
                catalan: "Tenim una missió: Aconseguir arribar a la Lluna!",
            },
        },
        roboticsTypes: ["ARTEC Blocks", "ARTEC Components"],
        type: "robotics and programming",
        difficulty: 2,
        _storageImages: {
            backgroundImage:
                "projects/Kl0HQRAOkO61QCHnk9Pa/images/backgroundImage",
            summaryImage: "projects/Kl0HQRAOkO61QCHnk9Pa/images/summaryImage",
        },
        recommendedAges: [5, 8],
        programmingTypes: ["Codespark"],
        id: "Kl0HQRAOkO61QCHnk9Pa",
        projectName: {
            catalan: "Anem a l'espai exterior",
        },
        levels: {
            eY5JKAeh1SH4ZNZKVApn: {
                title: {
                    spanish: "Este nivel es la hostiaa",
                    catalan: "Aquest nivell és la hostiaaaa",
                    english: "This level is awesome",
                },
                number: 1,
                id: "eY5JKAeh1SH4ZNZKVApn",
            },
            uvNFOx7ODyu5RrURjln5: {
                title: {
                    spanish: "Este nivel no es tan bueno",
                    catalan: "Aquest nivell és regulinxi",
                },
                number: 2,
                id: "uvNFOx7ODyu5RrURjln5",
            },
        },
    },
    {
        minVersion: 1,
        languages: {
            possibleLanguages: {
                spanish: "pending",
                catalan: "pending",
                english: "pending",
            },
            defaultLanguage: "catalan",
        },
        description: {
            longDescription: {
                catalan:
                    "Construïrem una motocicleta i farem curses amb la resta de companys. Això sí: també tindrem un mando!",
            },
            shortDescription: {
                catalan: "Construïrem una motocicleta i farem curses!",
            },
        },
        roboticsTypes: ["LEGO Mindstorms", "Makey Makey"],
        type: "robotics",
        difficulty: 4,
        _storageImages: {
            backgroundImage:
                "projects/RbF105PhFvz7tPEEjNTl/images/backgroundImage",
            summaryImage: "projects/RbF105PhFvz7tPEEjNTl/images/summaryImage",
        },
        recommendedAges: [8, 12],
        programmingTypes: ["Code.org"],
        id: "RbF105PhFvz7tPEEjNTl",
        projectName: {
            catalan: "Construïm una motocicleta",
        },
    },
    {
        minVersion: 1,
        languages: {
            possibleLanguages: {
                spanish: "pending",
                basque: "pending",
                catalan: "pending",
                english: "pending",
            },
            defaultLanguage: "catalan",
        },
        description: {
            longDescription: {
                catalan:
                    "Estem acostumats a jugar amb el Roblox però... sabem crear jocs des de zero utilitzant-lo? Amb el Roblox Studio, recrearem la nostra pel·lícula preferida: Jurassic World!",
            },
            shortDescription: {
                catalan:
                    "Utilitzarem el Roblox Studio per programar el nostre propi Jurassic World!",
            },
        },
        roboticsTypes: ["ARTEC Blocks"],
        type: "programming",
        difficulty: 3,
        _storageImages: {
            backgroundImage:
                "projects/qpm3OTFZ44wCwhgDV0uv/images/backgroundImage",
            summaryImage: "projects/qpm3OTFZ44wCwhgDV0uv/images/summaryImage",
        },
        recommendedAges: [10, 13],
        programmingTypes: ["Construct 3"],
        id: "qpm3OTFZ44wCwhgDV0uv",
        projectName: {
            catalan: "Jurassic World a Roblox!",
        },
    },
];
 */
