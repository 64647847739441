import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AdminProjectLevelDetail } from "./projectLevelDetail/AdminProjectLevelDetail";
import { AdminProjectLevelsDashboard } from "./projectLevelsDashboard/AdminProjectLevelsDashboard";
export const AdminProjectLevels = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:levelId`}>
                <AdminProjectLevelDetail />
            </Route>

            <Route path={`${match.path}`} exact>
                <AdminProjectLevelsDashboard />
            </Route>
        </Switch>
    );
};
