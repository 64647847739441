import { Modal, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/ui/button/Button";
import { Card } from "../../../components/ui/card/Card";
import { Loading } from "../../../components/ui/loading/Loading";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../../components/ui/snackbar/Snackbar";
import firebaseApp from "../../../firebase";

export const ForgottenPasswordModal = (props: {
    open: boolean;
    onClose: (passwordReset?: boolean) => void;
}) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState<ValidityState>();
    const [snackbar, setSnackbar] = useState<SnackbarProps>({ message: "" });
    const [uploading, setUploading] = useState(false);

    function recoverPassword() {
        if (!validEmail?.valid) {
            setSnackbar({
                message: t("Introduce a valid email"),
                type: "error",
            });
            return;
        }
        setUploading(true);
        firebaseApp
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                props.onClose(true);
            })
            .catch(() =>
                setSnackbar({
                    message: t("There has been a problem reaching the server"),
                    type: "error",
                })
            )
            .finally(() => setUploading(false));
    }

    return (
        <Modal
            open={props.open}
            onClose={() => props.onClose()}
            style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Card
                closeHandle={() => props.onClose()}
                style={{ padding: "4rem" }}
            >
                <h2 style={{ textAlign: "center" }}>
                    {t("Have you forgotten your password?")}
                </h2>
                <label style={{ marginTop: "1rem" }}>
                    {t("Enter your email")}
                </label>
                <TextField
                    style={{ marginBottom: "2rem" }}
                    value={email}
                    onChange={(ev) => {
                        setEmail(ev.target.value);
                        setValidEmail(ev.target.validity);
                    }}
                    type="email"
                />
                <Button
                    text={t("Recover password")}
                    onPress={recoverPassword}
                    fillContainer={false}
                />
                <CustomSnackbar
                    handleSnackbarClose={(snack) => setSnackbar(snack)}
                    snackbar={snackbar}
                />
                {uploading ? <Loading fullScreenLoading /> : null}
            </Card>
        </Modal>
    );
};
