import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationCAT from "./assets/strings/translations/cat.json";
import translationENG from "./assets/strings/translations/eng.json";
import translationESP from "./assets/strings/translations/esp.json";
import LanguageDetector from "i18next-browser-languagedetector";



const fallbackLng = ['esp'];
const availableLanguages = ['cat', 'eng', 'esp'];

const resources = {
    en: {
        translation: translationENG
    },
    es: {
        translation: translationESP
    },
    cat: {
        translation: translationCAT
    }
}

i18n
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .use(LanguageDetector)
    .init({
        resources, // the translation jsons
        fallbackLng, // fallback language is catalan.
        /* 
                detection: {
                    checkWhitelist: true, // options for language detection
                },
         */
        detection: {
            order: ['navigator']
        },
        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });

export default i18n;
