import { functionsApp } from "../firebase";
import firebase from "firebase";
import { ApiService } from "./ApiService";

export interface DistributionInformation {
    distributionId: string;
    projectId: string;
    ages: [number, number];
    active: boolean;
    public: boolean;
    price: number;
    offers: Offer[];
    creationDate: number;
    initDate: number;
    finalDate: number;
}

export interface Offer {
    initDate: number;
    finalDate: number;
    originalPrice: number;
    discountedPrice: number;
}

export class Distribution implements DistributionInformation {
    distributionId: string;
    ages: [number, number];
    active: boolean;
    public: boolean;
    price: number;
    offers: Offer[];
    creationDate: number;
    initDate: number;
    finalDate: number;
    projectId: string;

    constructor(data: DistributionInformation) {
        this.distributionId = data.distributionId;
        this.ages = data.ages;
        this.active = data.active;
        this.public = data.public;
        this.price = data.price;
        this.offers = data.offers;
        this.creationDate = data.creationDate;
        this.initDate = data.initDate;
        this.finalDate = data.finalDate;
        this.projectId = data.projectId;
    }

    static async getFromId(id: string) {
        const response = await functionsApp.httpsCallable(
            "distributions-getFromId"
        )({ distributionId: id });
        const data = response.data as DistributionInformation;

        return new Distribution(data);
    }

    static async postDistribution(data: DistributionInformation) {
        const response = await functionsApp.httpsCallable(
            "distributions-postDistribution"
        )({ distribution: data });
        return response.data as DistributionInformation;
    }

    getDistributionInformation(): DistributionInformation {
        return {
            distributionId: this.distributionId,
            ages: this.ages,
            active: this.active,
            public: this.public,
            price: this.price,
            offers: this.offers,
            creationDate: this.creationDate,
            initDate: this.initDate,
            finalDate: this.finalDate,
            projectId: this.projectId,
        };
    }

    static async getListAllDistributions(
        cursor?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        limit?: number,
        orderBy?: string
    ) {
        var data: { [key: string]: any } = {};
        if (cursor) data.cursor = cursor;
        if (limit) data.limit = limit;
        if (orderBy) data.orderBy = orderBy;

        const response = await functionsApp.httpsCallable(
            "distributions-getDistributions"
        )(data);
        return response.data as {
            data: DistributionInformation[];
            cursor: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
        };
    }

    async getDataTableFormat(language: string): Promise<DistributionTable> {
        const project = await ApiService.getProjectInformationFromId(
            this.projectId
        );
        const projectName =
            project!.projectName[language] ??
            project!.projectName[project!.languages.defaultLanguage];

        return {
            distributionId: this.distributionId,
            projectName: projectName,
            ages: this.ages.join(" - "),
            active: this.active ? "active" : "inactive",
            public: this.public ? "public" : "private",
            numOffers: this.offers.length.toString(),
            price: this.price.toString(),
        };
    }
}

export interface DistributionTable {
    distributionId: string;
    projectName: string;
    ages: string;
    active: string;
    public: string;
    numOffers: string;
    price: string;
}
