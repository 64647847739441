import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
    FaBook,
    FaBookOpen,
    FaCalendarAlt,
    FaGraduationCap,
    FaPaperPlane,
    FaVideo,
} from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { ClassDay, Group, GroupInformation } from "../../../apis/Group";
import { ParticipantsTeamSummary } from "../../../apis/ParticipantsTeam";
import { KidBasicInfo, User } from "../../../apis/User";
import { IconButton } from "../../../components/ui/button/Button";
import { Card } from "../../../components/ui/card/Card";
import { Loading } from "../../../components/ui/loading/Loading";
import { Path } from "../../../components/ui/path/Path";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../../components/ui/snackbar/Snackbar";
import styles from "./ClassDetails.module.css";
import maleKid from "../../../assets/icons/male_kid.png";
import femaleKid from "../../../assets/icons/female_kid.png";

export const ClassDetails = () => {
    const { t } = useTranslation();
    const { classId } = useParams<{ classId: string }>();

    const [loading, setLoading] = useState(true);
    const [fatalError, setFatalError] = useState("");
    const [group, setGroup] = useState<GroupInformation>();

    useEffect(() => {
        document.title = t("Class Details");

        getGroup()
            .catch(() =>
                setFatalError(
                    t(
                        "This group does not exist or there was a problem reaching it"
                    )
                )
            )
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getGroup() {
        const group = await Group.getFromId(classId);
        setGroup(group);
    }

    if (loading) return <Loading />;
    if (fatalError) return <h1>{fatalError}</h1>;
    return (
        <div className={`app-body-container-box ${styles.container}`}>
            <div className={styles.headerContainer}>
                <Header
                    t={t}
                    groupName={group!.name}
                    schedule={group!.schedule}
                />
                {group?.teamsSummaries ? (
                    <GroupParticipantsElements
                        teamsSummaries={group.teamsSummaries}
                    />
                ) : (
                    <p>{t("There aren't any participants yet")}</p>
                )}
                <ButtonsList />
            </div>
        </div>
    );
};

const Header = (props: {
    t: TFunction<string>;
    groupName: string;
    schedule: ClassDay[];
}) => {
    const { t, groupName, schedule } = props;

    return (
        <div className={styles.header}>
            <Path
                elements={[
                    { text: t("Classes"), linkTo: "/classes/" },
                    { text: groupName },
                ]}
            />
            <p className={styles.smallText}>
                {Group.scheduleToText(schedule, t)}
            </p>
        </div>
    );
};

const GroupParticipantsElements = (props: {
    teamsSummaries: ParticipantsTeamSummary[];
}) => {
    const { teamsSummaries } = props;
    const [participants, setParticipants] = useState<KidBasicInfo[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        getParticipants()
            .catch(() => setError(t("Participants cannot be reached")))
            .finally(() => setLoading(false));
    }, [teamsSummaries]);

    async function getParticipants() {
        const participantsIds = teamsSummaries
            .map((team) => team.participantsIds)
            .flat();
        const kids = await User.getKidInfoFromId(participantsIds);
        setParticipants(kids);
    }

    return (
        <Card className={styles.participantsCard}>
            {loading ? (
                <Loading />
            ) : error ? (
                <p>{error}</p>
            ) : participants.length === 0 ? (
                <p>{t("There are no subscribed participants yet")}</p>
            ) : (
                participants.map((kid) => <ParticipantElement kid={kid} />)
            )}
        </Card>
    );
};

const ParticipantElement = (props: { kid: KidBasicInfo }) => {
    return (
        <div className={styles.kidColumn}>
            <img
                src={props.kid.gender === "male" ? maleKid : femaleKid}
                alt="kid"
                className={styles.kidImage}
            />
            <p className={styles.kidName}>
                {props.kid.name + " " + props.kid.surname}
            </p>
        </div>
    );
};
const ButtonsList = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const route = useRouteMatch();
    const [snackbar, setSnackbar] = useState<SnackbarProps>({
        message: "",
        type: "warning",
    });

    function handleSnackbarClose(snackbar: SnackbarProps) {
        setSnackbar(snackbar);
    }
    return (
        <div className={styles.buttonsContainer}>
            <IconContext.Provider
                value={{ style: { height: "1.5rem", width: "1.5rem" } }}
            >
                <div className={styles.halfButtonContainer}>
                    <IconButton
                        text={t("Get in the videocall")}
                        icon={<FaVideo />}
                        className={styles.button}
                        onPress={() =>
                            setSnackbar({
                                type: "warning",
                                message: t("The videocall is not linked yet"),
                            })
                        }
                    />
                    <IconButton
                        text={t("See the project")}
                        icon={<FaBookOpen />}
                        className={styles.button}
                        onPress={() =>
                            setSnackbar({
                                type: "warning",
                                message: t("We can't see the project yet"),
                            })
                        }
                    />
                    <IconButton
                        text={t("See the reports")}
                        icon={<FaPaperPlane />}
                        className={styles.button}
                        onPress={() =>
                            setSnackbar({
                                type: "warning",
                                message: t("Reports cannot be created yet"),
                            })
                        }
                    />
                </div>
                <div className={styles.halfButtonContainer}>
                    <IconButton
                        text={t("See the attendance list")}
                        icon={<FaCalendarAlt />}
                        className={styles.button}
                        onPress={() =>
                            setSnackbar({
                                type: "warning",
                                message: t(
                                    "The assistance sheet has not been linked yet"
                                ),
                            })
                        }
                    />
                    <IconButton
                        text={t("See the classroom's journal")}
                        icon={<FaBook />}
                        className={styles.button}
                        onPress={() =>
                            setSnackbar({
                                type: "warning",
                                message: t(
                                    "The class journal has not been linked yet"
                                ),
                            })
                        }
                    />
                    <IconButton
                        text={t("See the levels and unlock them")}
                        icon={<FaGraduationCap />}
                        className={styles.button}
                        onPress={() =>
                            history.push(route.url + "/class-progress")
                        }
                    />
                </div>
                <CustomSnackbar
                    snackbar={snackbar}
                    handleSnackbarClose={handleSnackbarClose}
                />
            </IconContext.Provider>
        </div>
    );
};
