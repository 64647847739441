import React from "react";
import Cheer from "../../../assets/animations/cheer.svg";
import { Button, ButtonProps } from "../button/Button";
import styles from "./SuccessPage.module.css";

/** Returns a Success Page, with a cheering robot in the middle and the desired message below
 * on a fullscreen. Will also show the desired buttons
 * @param message @param buttons An array of buttons to continue the navigation */
export const SuccessPage = (props: {
    message: string;
    buttons: ButtonProps[];
}) => {
    return (
        <div className={styles.container}>
            <object type="image/svg+xml" data={Cheer} width={500}>
                Loading...
            </object>
            <h2>{props.message}</h2>
            <div className={styles.rowButtonsContainer}>
                {props.buttons.map((val, ind) => {
                    return (
                        <div className={styles.buttonContainer} key={ind}>
                            <Button
                                key={ind}
                                text={val.text}
                                onPress={val.onPress}
                                type={val.type}
                            ></Button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
