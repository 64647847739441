import { ReactElement } from "react";
import * as FaIcons from "react-icons/fa";
import { MdSettings } from "react-icons/md";

export const sidebarData: Array<{
    title: string;
    path: string;
    icon: ReactElement;
}> = [
    {
        title: "Home",
        path: "/home",
        icon: <FaIcons.FaHome />,
    },
    {
        title: "Classes",
        path: "/classes",
        icon: <FaIcons.FaChild />,
    },
    {
        title: "Calendar",
        path: "/calendar",
        icon: <FaIcons.FaCalendar />,
    },
    {
        title: "Administration",
        path: "/administration",
        icon: <FaIcons.FaUserLock />,
    },
    {
        title: "Projects",
        path: "/projects",
        icon: <FaIcons.FaBookOpen />,
    },
    {
        title: "Messages",
        path: "/messages",
        icon: <FaIcons.FaEnvelope />,
    },
    {
        title: "Payrolls",
        path: "/payrolls",
        icon: <FaIcons.FaBriefcase />,
    },

    {
        title: "Configuration",
        path: "/configuration",
        icon: <MdSettings />,
    },
];
