import { FormControlLabel, Switch } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { ApiService } from "../../../../apis/ApiService";
import {
    Distribution,
    DistributionInformation,
    Offer,
} from "../../../../apis/Distribution";
import { ProjectInformation } from "../../../../apis/Project";
import { SaveDiscardButtons } from "../../../../components/ui/button/Button";
import { Card } from "../../../../components/ui/card/Card";
import {
    SelectInput,
    SelectOptionProps,
} from "../../../../components/ui/input/selectInput/SelectInput";
import { SliderInput } from "../../../../components/ui/input/sliderInput/SliderInput";
import { TextInput } from "../../../../components/ui/input/textInput/TextInput";
import { Loading } from "../../../../components/ui/loading/Loading";
import { Path } from "../../../../components/ui/path/Path";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../../../components/ui/snackbar/Snackbar";
import {
    deleteAndAttachUrl,
    getUuidv4,
    toTextFieldDateString,
} from "../../../../utils/functions";
import { numberDotCommaRegex } from "../../../../utils/regex";
import styles from "./AdminNewDistribution.module.css";

interface NewOffer {
    initDate?: number;
    finalDate?: number;
    originalPrice?: number;
    discountedPrice?: number;
    new: true;
}

interface DistributionFormResults {
    projectId: string;
    price: string;
    initDate: string;
    finalDate: string;
    active: boolean;
    public: boolean;
    offerInitDate0: string;
    offerFinalDate0: string;
    offerOriginalPrice0: string;
    offerDiscountedPrice0: string;
    [key: string]: any;
}
export const AdminNewDistribution = () => {
    /** If present, it will try to load the data associated */
    const { distributionId } = useParams<{ distributionId: string }>();

    /** The previous distribution information, if existed */
    const [savedDisInfo, setSavedDisInfo] = useState<DistributionInformation>();

    /** Will be used to show the recommended ages of a particular selected project */
    const [recommendedAges, setRecommendedAges] = useState<[number, number]>();

    /** Will be used to populate the options from the select input */
    const projectsList = useRef<ProjectInformation[]>();

    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);

    const [snackbar, setSnackbar] = useState<SnackbarProps>({ message: "" });
    const [fatalError, setFatalError] = useState("");

    const history = useHistory();
    const distributionForm = useForm({ mode: "onBlur" });

    /** Used to rerender if new offers should be created */
    const [offers, setOffers] = useState<(Offer | NewOffer)[]>([]);

    const { t } = useTranslation();
    let match = useRouteMatch();

    useEffect(() => {
        document.title = t("New distribution");

        const getDistributionPromise = getDistribution();
        const getProjectsListPromise = loadProjectSelection();

        // We wait for both promises to fulfill or throw disable the Loading or show
        // a fatal error
        Promise.all([getDistributionPromise, getProjectsListPromise])
            .catch(() =>
                setFatalError(
                    t(
                        "There was a problem reaching the distribution. It may not exist or have a problem with the connection."
                    )
                )
            )
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** Will check if the form is filled correctly. It will then format the form results
     * and try to post it. */
    const onSave = async () => {
        if (await distributionForm.trigger()) {
            setUploading(true);

            const newDistribution = formatFormResults(
                distributionForm.getValues() as DistributionFormResults
            );

            try {
                const responseDistribution =
                    await Distribution.postDistribution(newDistribution);
                const path = deleteAndAttachUrl(
                    match.path,
                    "distributions",
                    `/edit-distribution/${responseDistribution.distributionId}`
                );
                setSnackbar({
                    message: t("Data updated"),
                    type: "success",
                });
                setTimeout(() => {
                    history.push(path);
                    history.go(0);
                }, 3000);
            } catch {
                setSnackbar({
                    message: t(
                        "Error uploading the data. Try again later or contact the admin"
                    ),
                    type: "error",
                });
            } finally {
                setUploading(false);
            }
        }
    };

    /** Will reload the page */
    const onDiscard = () => history.go(0);

    const formatFormResults = (
        distributionFormResults: DistributionFormResults
    ) => {
        let newOffers: any[] = [];
        // With this loop we will format the offers
        for (let key in distributionFormResults) {
            // If the key does not include a offer, we don't need it right now
            if (!key.includes("offer")) continue;
            // We proceed to get the number of the offer and the rest of the string
            let num = +key.slice(-1);
            let string = key.slice(0, -1);
            // If the array still does not have the object created, it creates it. Will throw
            // if not because newOffers[num] will be undefined
            if (!newOffers[num]) newOffers[num] = {};
            switch (string) {
                case "offerInitDate":
                    newOffers[num]["initDate"] = new Date(
                        distributionFormResults[key]
                    ).getTime();
                    break;
                case "offerFinalDate":
                    newOffers[num]["finalDate"] = new Date(
                        distributionFormResults[key]
                    ).getTime();
                    break;
                case "offerOriginalPrice":
                    newOffers[num]["originalPrice"] =
                        +distributionFormResults[key];
                    break;
                case "offerDiscountedPrice":
                    newOffers[num]["discountedPrice"] =
                        +distributionFormResults[key];
                    break;
                default:
                    break;
            }
        }
        let newDistribution: DistributionInformation = {
            distributionId: savedDisInfo?.distributionId ?? getUuidv4(),
            projectId: distributionFormResults.projectId,
            active: distributionFormResults.active,
            ages: distributionFormResults.ages,
            public: distributionFormResults.public,
            price: +distributionFormResults.price,
            offers: newOffers,
            creationDate:
                savedDisInfo?.creationDate ?? new Date(Date.now()).getTime(),
            initDate: new Date(distributionFormResults.initDate).getTime(),
            finalDate: new Date(distributionFormResults.finalDate).getTime(),
        };
        return newDistribution;
    };

    const handleSnackbarClose = (snackbar: SnackbarProps) => {
        setSnackbar(snackbar);
    };

    const addOffer = () => {
        setOffers([...offers, { new: true }]);
    };

    /** If there is a distribution Id, it will try to load the data from the API
     * and update the SavedDisInfo and Offers states */
    async function getDistribution() {
        if (distributionId) {
            const distribution = await Distribution.getFromId(distributionId);
            let disInfo = distribution.getDistributionInformation();
            setSavedDisInfo(disInfo);
            setOffers(disInfo.offers);
        }
    }

    /** It will try to get the list of all projects and update the projectsList ref with them */
    async function loadProjectSelection() {
        let projects = await ApiService.getListAllProjects();
        projectsList.current = projects;
    }

    /** From an array of ProjectInformation objects, it will return the display and value
     * to be able to select them in the selectInput     */
    function getProjectOptions(
        projects: ProjectInformation[]
    ): SelectOptionProps[] {
        return projects.map((val) => {
            return {
                display: val.projectName["catalan"],
                value: val.id!,
            };
        });
    }

    /** Will copy the current offers, splice the indexed element and update the offers state */
    function onOfferDelete(index: number) {
        let newOffers = [...offers];
        newOffers.splice(index, 1);
        setOffers(newOffers);
    }

    // If we don't format the dates, the textInputs do not understand the default value
    let initDate: string | undefined = undefined;
    let finalDate: string | undefined = undefined;

    if (savedDisInfo?.initDate)
        initDate = toTextFieldDateString(savedDisInfo.initDate);

    if (savedDisInfo?.finalDate)
        finalDate = toTextFieldDateString(savedDisInfo.finalDate);

    if (loading) return <Loading />;

    if (fatalError) return <h1 id="fatalError">{fatalError}</h1>;

    return (
        <div className={`app-body-container-box overflow-y`}>
            <div className={styles.headerContainer}>
                <Header />
                <SaveDiscardButtons
                    onDiscard={onDiscard}
                    onSave={onSave}
                    className={styles.saveDiscardButtons}
                />
            </div>
            <Card className={styles.card}>
                <FormProvider {...distributionForm}>
                    <form>
                        <div className={styles.editDistributionContainer}>
                            <div className={styles.leftContainer}>
                                <h2>{t("Description")} </h2>
                                <label htmlFor="price">{t("Price")}</label>
                                <TextInput
                                    name="price"
                                    className={styles.inputsMargin}
                                    inputAdornments={{ start: <p>€</p> }}
                                    defaultValue={savedDisInfo?.price.toString()}
                                    mandatory
                                    pattern={numberDotCommaRegex}
                                    errorMessage={t(
                                        "Only numbers are valid. Decimals must be indicated with a dot"
                                    )}
                                />
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <label htmlFor="initDate">
                                            {t("Initial Date")}
                                        </label>
                                        <TextInput
                                            name={`initDate`}
                                            type="date"
                                            className={styles.textInput}
                                            defaultValue={initDate}
                                            mandatory
                                        />
                                    </div>
                                    <div className={styles.column}>
                                        <label htmlFor="finalDate">
                                            {t("Final Date")}
                                        </label>
                                        <TextInput
                                            name={`finalDate`}
                                            type="date"
                                            className={styles.textInput}
                                            defaultValue={finalDate}
                                            mandatory
                                        />
                                    </div>
                                </div>

                                <label htmlFor="ages">{t("Ages")}</label>
                                <SliderInput
                                    name="ages"
                                    sliderProps={{
                                        min: 4,
                                        max: 18,
                                        valueLabelDisplay: "on",
                                    }}
                                    labelDif={2}
                                    margins={{
                                        optimalMargin: [2, 4],
                                        recommendedMargin: 1,
                                    }}
                                    defaultValue={savedDisInfo?.ages}
                                    className={styles.inputsMargin}
                                    mandatory
                                />
                                <label htmlFor="projectId">
                                    {t("Project")}
                                </label>
                                <SelectInput
                                    name="projectId"
                                    options={getProjectOptions(
                                        projectsList.current!
                                    )}
                                    className={styles.row}
                                    defaultValue={savedDisInfo?.projectId}
                                    mandatory
                                />

                                <div className={styles.row}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="active"
                                                inputRef={distributionForm.register()}
                                                color="primary"
                                                defaultChecked={
                                                    savedDisInfo?.active
                                                }
                                            />
                                        }
                                        label={t("Active")}
                                        className={styles.inputsMargin}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="public"
                                                inputRef={distributionForm.register()}
                                                color="primary"
                                                defaultChecked={
                                                    savedDisInfo?.public
                                                }
                                            />
                                        }
                                        label={t("Public")}
                                        className={styles.inputsMargin}
                                    />
                                </div>
                            </div>
                            <div className={styles.rightContainer}>
                                <h2>{t("Offers")}</h2>
                                {offers.length > 0 ? (
                                    offers.map((val, ind) => (
                                        <OfferEl
                                            defaultOffer={val}
                                            key={ind}
                                            ind={ind}
                                            onOfferDelete={onOfferDelete}
                                        />
                                    ))
                                ) : (
                                    <p className="italics">
                                        {t("There are no offers")}
                                    </p>
                                )}
                                <FaPlusCircle
                                    className={`icon ${styles.icon}`}
                                    onClick={() => addOffer()}
                                    role="button"
                                    name="addOffer"
                                    aria-label="addOffer"
                                />
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </Card>
            <CustomSnackbar
                handleSnackbarClose={handleSnackbarClose}
                snackbar={snackbar}
            />
            {uploading ? <Loading fullScreenLoading /> : null}
        </div>
    );
};

const Header = () => {
    const { t } = useTranslation();
    return (
        <Path
            elements={[
                { text: t("Administration"), linkTo: "/administration" },
                {
                    text: t("Distributions"),
                    linkTo: "/administration/distributions/distributions-list",
                },
                { text: t("Add or edit a distribution") },
            ]}
        />
    );
};

const OfferEl = (props: {
    defaultOffer: Offer | NewOffer;
    ind: number;
    onOfferDelete: (index: number) => void;
}) => {
    const { defaultOffer, ind, onOfferDelete } = props;

    const { t } = useTranslation();

    // If we don't format the dates, the textInputs do not understand the default value

    let initDate: string | undefined = undefined;
    let finalDate: string | undefined = undefined;

    if (defaultOffer.initDate)
        initDate = toTextFieldDateString(defaultOffer.initDate);
    if (defaultOffer.finalDate)
        finalDate = toTextFieldDateString(defaultOffer.finalDate);

    return (
        <>
            <div className={styles.offerLabel}>
                <label>{`${t("Offer")} ${ind}`}</label>
                <FaMinusCircle
                    className={`icon ${styles.floatRight}`}
                    onClick={() => onOfferDelete(ind)}
                    role="button"
                    aria-label={`deleteOffer${ind}`}
                />
            </div>
            <div className={styles.offerElement}>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <label htmlFor={`offerInitDate${ind}`}>
                            {t("Initial Date")}
                        </label>
                        <TextInput
                            name={`offerInitDate${ind}`}
                            type="date"
                            className={styles.textInput}
                            defaultValue={initDate}
                            mandatory
                        />
                    </div>
                    <div className={styles.column}>
                        <label htmlFor={`offerFinalDate${ind}`}>
                            {t("Final Date")}
                        </label>
                        <TextInput
                            name={`offerFinalDate${ind}`}
                            type="date"
                            className={styles.textInput}
                            defaultValue={finalDate}
                            mandatory
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <label htmlFor={`offerOriginalPrice${ind}`}>
                            {t("Original Price")}
                        </label>
                        <TextInput
                            name={`offerOriginalPrice${ind}`}
                            defaultValue={defaultOffer.originalPrice?.toString()}
                            inputAdornments={{ start: <p>€</p> }}
                            mandatory
                            pattern={numberDotCommaRegex}
                            errorMessage={t(
                                "Only numbers are valid. Decimals must be indicated with a dot"
                            )}
                        />
                    </div>
                    <div className={styles.column}>
                        <label htmlFor={`offerDiscountedPrice${ind}`}>
                            {t("Discounted Price")}
                        </label>
                        <TextInput
                            name={`offerDiscountedPrice${ind}`}
                            defaultValue={defaultOffer.discountedPrice?.toString()}
                            inputAdornments={{ start: <p>€</p> }}
                            mandatory
                            pattern={numberDotCommaRegex}
                            errorMessage={t(
                                "Only numbers are valid. Decimals must be indicated with a dot"
                            )}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
