import React, { ReactElement } from "react";
import { IconContext } from "react-icons/lib";
import styles from "./Card.module.css";
import { IoClose } from "react-icons/io5";

interface CardProps {
    children?: any;
    header?: boolean;
    closeHandle?: Function;
    className?: string;
    style?: React.CSSProperties;
}
export const Card = (props: CardProps) => {
    return (
        <div
            className={`${styles.container} shadow ${
                props.header ? styles.cardWithHeader : ""
            } ${props.className}`}
            style={props.style}
        >
            {props.closeHandle ? (
                <div
                    className={styles.closeContainer}
                    onClick={() =>
                        props.closeHandle ? props.closeHandle() : undefined
                    }
                >
                    <IconContext.Provider value={{ className: styles.close }}>
                        <IoClose />
                    </IconContext.Provider>
                </div>
            ) : null}
            {props.children}
        </div>
    );
};

export const IconCard = (props: {
    children: any;
    icon: ReactElement;
    cardProps?: CardProps;
}) => {
    return (
        <IconContext.Provider value={{ className: "iconCard-icon" }}>
            <Card {...props.cardProps}>
                <div className={styles.iconContainer}>{props.icon}</div>
                {props.children}
            </Card>
        </IconContext.Provider>
    );
};

export const HeaderCard = (props: {
    children: any;
    header: string;
    textPadding?: boolean;
    cardProps?: CardProps;
}) => {
    return (
        <>
            <div className={styles.headerContainer}>
                <h3>{props.header}</h3>
            </div>
            <Card header {...props.cardProps}>
                <div>{props.children}</div>
            </Card>
        </>
    );
};
