import React, { useEffect, useState } from "react";
import firebaseApp, { functionsApp } from "../firebase";
import firebase from "firebase";

import { Loading } from "../components/ui/loading/Loading";
import { Instructor } from "../apis/Instructor";

/** Returns {@link AuthContextObject} */
export const AuthContext = React.createContext<AuthContextObject | null>(null);

/** Contains the user and role currently authenticated. Also shows if the auth is
 *  still loading */
interface AuthContextObject {
    /** Contains the firebase user model. If the user is not logged in, it will be null */
    currentUser?: firebase.User | null;
    /** The role of the user, if it exists. Used to check permissions to access sites */
    role?: "kid" | "instructor" | "admin";
    /** If it is true, it means that the auth is loading and is not yet finished.
     * Should wait for it to end. It could be changed for an observer in the future */
    loading?: boolean;
    userId?: string;
}

/* The Auth Provider is responsible for telling all the application whether
 there is a user logged in or not and which role it has */
export const AuthProvider = (props: { children: any }) => {
    const [currentContext, setCurrentContext] =
        useState<AuthContextObject | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        firebaseApp.auth().onAuthStateChanged((user) => {
            /** Since we need to load the role of the user, we set the context to
             *  loading to avoid races and set the loading */
            setLoading(true);
            setCurrentContext({ loading: true });

            /** If there is no user, we turn off the loading */
            if (!user) {
                setLoading(false);
                setCurrentContext({ currentUser: null });
                return;
            }

            /** We ask for the role of the user and set the context accordingly.
             *  We finally turn off the loading
             */
            Instructor.getInstructorFromAuthUid(user.uid)
                .then((res) => {
                    setCurrentContext({
                        currentUser: user,
                        role: res.role,
                        userId: res.id,
                    });
                })
                .catch(() => {
                    setCurrentContext({ currentUser: user, role: undefined });
                })
                .finally(() => setLoading(false));
        });
    }, []);

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <AuthContext.Provider value={currentContext}>
            {props.children}
        </AuthContext.Provider>
    );
};

const LoadingPage = () => {
    useEffect(() => {
        document.title = "Roboclick Instructors";
    });
    return <Loading />;
};
