import { TextField } from "@material-ui/core";
import {
    CalendarComponent,
    RenderDayCellEventArgs,
} from "@syncfusion/ej2-react-calendars";
import { addClass } from "@syncfusion/ej2-base";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChild } from "react-icons/fa";
import { ClassDay, Group, GroupSummary } from "../../../apis/Group";
import { Card } from "../../../components/ui/card/Card";
import { Loading } from "../../../components/ui/loading/Loading";
import { Path } from "../../../components/ui/path/Path";
import { AuthContext } from "../../../provider/Auth";
import { checkObjectEquality } from "../../../utils/functions";
import styles from "./ClassList.module.css";
import "./calendarStyles.css";
import { useHistory } from "react-router";

export const ClassList = () => {
    const { t } = useTranslation();
    const [classes, setClasses] = useState<GroupSummary[]>([]);
    const [filteredClasses, setFilteredClasses] = useState<GroupSummary[]>([]);
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(true);
    const [fatalError, setFatalError] = useState("");
    const [format, setFormat] = useState<"projectName" | "days">("projectName");
    const authContext = useContext(AuthContext);

    useEffect(() => {
        document.title = t("Classes List");
        getGroupsSummaries().finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilteredClasses(classes);
        setFilter("");
    }, [classes]);

    async function getGroupsSummaries() {
        try {
            const groups = await Group.getGroupsFromInstructor(
                authContext!.userId!
            );
            const summaryGroups = await Promise.all(
                groups.map((group) => group.getGroupSummary())
            );
            setClasses(summaryGroups);
        } catch {
            setFatalError(
                t(
                    "You have no groups attached or there has been a problem reaching them"
                )
            );
        }
    }

    function formatToProjectCards(groups: GroupSummary[]): {
        [key: string]: GroupSummary[];
    } {
        let formatted: { [key: string]: GroupSummary[] } = {};
        // Gets all the unique project names. Set is used to only get the unique values
        const uniqueProjects = Array.from(
            new Set(groups.map((group) => group.projectName))
        );

        uniqueProjects.forEach((projectName) => {
            formatted[projectName["catalan"]] = groups.filter((group) => {
                return checkObjectEquality(group.projectName, projectName);
            });
        });

        return formatted;
    }

    function renderProjectCards(groups: GroupSummary[]) {
        const formattedGroups = formatToProjectCards(groups);
        return Object.getOwnPropertyNames(formattedGroups).map(
            (projectName) => (
                <Card className={styles.card}>
                    <h1>{projectName}</h1>
                    {formattedGroups[projectName].map((group) => {
                        return (
                            <GroupLine
                                groupName={group.groupName}
                                additionalText={Group.scheduleToText(
                                    group.schedule,
                                    t
                                )}
                                groupId={group.groupId}
                                num={group.numParticipants}
                            />
                        );
                    })}
                </Card>
            )
        );
    }

    function renderDayCards(groups: GroupSummary[]) {
        const formattedGroups = formatToDayCards(groups);
        return Object.getOwnPropertyNames(formattedGroups).map((day) => (
            <Card className={styles.card}>
                <h1>{t(day)}</h1>
                {formattedGroups[day].map((group) => {
                    return (
                        <GroupLine
                            groupName={group.groupName}
                            additionalText={group.projectName["catalan"]}
                            groupId={group.groupId}
                            num={group.numParticipants}
                        />
                    );
                })}
            </Card>
        ));
    }

    function formatToDayCards(groups: GroupSummary[]) {
        let formatted: { [key: string]: GroupSummary[] } = {};
        // Gets all the unique days. Set is used to only get the unique values
        const days = Array.from(
            new Set(
                groups.flatMap((group) =>
                    group.schedule.map((day) => day.weekDay!)
                )
            )
        );

        days.forEach((day) => {
            formatted[day] = groups.filter((group) =>
                group.schedule.find((classDay) => classDay.weekDay === day)
            );
        });

        return formatted;
    }

    function onFilterChange(filter: string) {
        setFilter(filter);
        if (filter === "") setFilteredClasses(classes);
        else {
            let filtered = classes.filter((group) => {
                return JSON.stringify(group)
                    .toLowerCase()
                    .includes(filter.toLowerCase());
            });
            setFilteredClasses([...filtered]);
        }
    }

    function onFormatChange(format: "projectName" | "days") {
        setFormat(format);
    }

    if (loading) return <Loading />;

    if (fatalError) return <h1>{fatalError}</h1>;

    return (
        <div className={`app-body-container-box ${styles.container}`}>
            <div className={styles.headerContainer}>
                <Path elements={[{ text: t("Classes") }]} />
                <FilterElement
                    onFilterChange={onFilterChange}
                    filter={filter}
                />
                <FormatInput onFormatChange={onFormatChange} format={format} />
            </div>
            <div className={styles.body}>
                <div className={styles.cardsContainer}>
                    {format === "projectName"
                        ? renderProjectCards(filteredClasses)
                        : format === "days"
                        ? renderDayCards(filteredClasses)
                        : null}
                </div>
                <CustomCalendar
                    schedule={classes.flatMap((group) => group.schedule)}
                />
            </div>
        </div>
    );
};

const GroupLine = (props: {
    groupName: string;
    additionalText: string;
    num: number;
    groupId: string;
}) => {
    const { groupName, additionalText, num, groupId } = props;

    const history = useHistory();

    function goToGroupDetails() {
        history.push(`/classes/class-details/${groupId}`);
    }
    return (
        <div className={styles.groupLine} onClick={() => goToGroupDetails()}>
            <p className={styles.groupName}>
                {groupName + " - " + additionalText}
            </p>
            <div className={styles.groupLineIcon}>
                <FaChild className={styles.blue} />
                <p className={styles.blue}>{num}</p>
            </div>
        </div>
    );
};

const FilterElement = (props: {
    onFilterChange: (filter: string) => void;
    filter: string;
}) => {
    const { t } = useTranslation();
    const { onFilterChange, filter } = props;

    return (
        <TextField
            className={styles.filter}
            placeholder={t("Search through classes")}
            onChange={(ev) => onFilterChange(ev.target.value)}
            value={filter}
        />
    );
};

const FormatInput = (props: {
    onFormatChange: (format: "projectName" | "days") => void;
    format: "projectName" | "days";
}) => {
    const { onFormatChange, format } = props;
    const { t } = useTranslation();
    return (
        <p className={styles.formatInput}>
            {t("Group it with")}{" "}
            <button
                onClick={() => onFormatChange("projectName")}
                disabled={format === "projectName"}
            >
                {t("Projects")}
            </button>{" "}
            {t("or")}{" "}
            <button
                onClick={() => onFormatChange("days")}
                disabled={format === "days"}
            >
                {t("Days")}
            </button>
        </p>
    );
};

const CustomCalendar = (props: { schedule: ClassDay[] }) => {
    const [workDays, setWorkDays] = useState<number[]>();

    useEffect(() => {
        const days = Array.from(
            new Set(
                props.schedule.map((classDay) => {
                    switch (classDay.weekDay) {
                        case "sunday":
                            return 0;
                        case "monday":
                            return 1;
                        case "tuesday":
                            return 2;
                        case "wednesday":
                            return 3;
                        case "thursday":
                            return 4;
                        case "friday":
                            return 5;
                        case "saturday":
                            return 6;
                        default:
                            return 0;
                    }
                })
            )
        );
        setWorkDays(days);
    }, [props]);

    function specialDate(args: RenderDayCellEventArgs, name: String) {
        let span = document.createElement("span");
        span.setAttribute("class", "e-icons highlight");
        args?.element?.firstElementChild?.setAttribute("title", name + "!");
        addClass([args?.element!], ["e-day", "special", name.toLowerCase()]);
        args?.element?.setAttribute("data-val", name + "!");
        args?.element?.setAttribute("title", name + "!");
        args?.element?.appendChild(span);
    }

    function customDates(args?: RenderDayCellEventArgs): void {
        let day = args?.date?.getDay();
        if (day && workDays!.includes(day)) {
            specialDate(args!, "Class");
        }
    }

    if (!workDays) return <Loading />;

    return (
        <div className={`${styles.calendar} shadow`}>
            <CalendarComponent
                id="calendar"
                renderDayCell={customDates.bind(this)}
                className={`e-customStyle`}
                firstDayOfWeek={1}
            />
        </div>
    );
};
