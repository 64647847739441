import { useEffect, useState } from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvidedDragHandleProps,
    Droppable,
} from "react-beautiful-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaPencilAlt, FaPlusCircle } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { MdDragHandle } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { ApiService } from "../../../../../apis/ApiService";
import { ProjectInformation } from "../../../../../apis/Project";
import {
    AdminLevel,
    PostLevelStep,
} from "../../../../../apis/ProjectInterfaces";
import { Button } from "../../../../../components/ui/button/Button";
import { Card } from "../../../../../components/ui/card/Card";
import {
    SelectInput,
    SelectOptionProps,
} from "../../../../../components/ui/input/selectInput/SelectInput";
import { TextInput } from "../../../../../components/ui/input/textInput/TextInput";
import { Loading } from "../../../../../components/ui/loading/Loading";
import { Path } from "../../../../../components/ui/path/Path";
import styles from "./AdminProjectLevelDetail.module.css";
import { StepModal } from "./StepModal";
import { competences } from "../../../../../assets/strings/competences.json";
import { checkObjectEquality } from "../../../../../utils/functions";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../../../../components/ui/snackbar/Snackbar";

let projectInformation: ProjectInformation;
export const AdminProjectLevelDetail = () => {
    const [loading, setLoading] = useState(true);
    const [levelDetails, setLevelDetails] = useState<AdminLevel>();
    const [error, setError] = useState<string>();
    const { projectId, levelId } =
        useParams<{
            projectId: string;
            levelId: string;
        }>();
    const { t } = useTranslation();

    const [snackbar, setSnackbar] = useState<SnackbarProps>({ message: "" });

    useEffect(() => {
        document.title = t("Level Details");

        const levelPromise = ApiService.getLevelDetails(projectId, levelId);
        const projectPromise =
            ApiService.getProjectInformationFromId(projectId);
        Promise.all([levelPromise, projectPromise])
            .then(([level, project]) => {
                level ? setLevelDetails(level) : setError(t("No level found"));
                project
                    ? (projectInformation = project)
                    : setError(t("No project found"));
            })
            .catch(() => setError(t("There is a problem reaching our end")))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSnackbarClose = (snackbar: SnackbarProps) => {
        setSnackbar(snackbar);
    };

    if (loading) return <Loading />;

    if (error) return <h2>{error}</h2>;

    return (
        <div className={`app-body-container-box overflow-y`}>
            <Header levelNumber={levelDetails!.number} />
            <div className={styles.bodyContainer}>
                <FirstForm
                    levelDetails={levelDetails!}
                    handleSnackbar={handleSnackbarClose}
                />
                <SecondForm
                    levelDetails={levelDetails!}
                    handleSnackbar={handleSnackbarClose}
                />
                <CustomSnackbar
                    snackbar={snackbar}
                    handleSnackbarClose={handleSnackbarClose}
                    autoHideDuration={2000}
                />
            </div>
        </div>
    );
};

const Header = (props: { levelNumber: number }) => {
    const { t } = useTranslation();
    const { levelNumber } = props;
    const { projectId } =
        useParams<{
            projectId: string;
        }>();

    return (
        <Path
            elements={[
                {
                    text: t("Administration"),
                    linkTo: "/administration",
                },
                {
                    text: t("Projects"),
                    linkTo: "/administration/projects/projects-list",
                },
                {
                    text: "Anem a l'espai exterior",
                    linkTo: `/administration/projects/${projectId}/levels`,
                },
                {
                    text: t("Levels"),
                    linkTo: `/administration/projects/${projectId}/levels`,
                },
                {
                    text: `${t("Level")} ${levelNumber}`,
                },
            ]}
            style={{ marginBottom: 40 }}
        />
    );
};

const FirstForm = (props: {
    levelDetails: AdminLevel;
    handleSnackbar: (snackbar: SnackbarProps) => void;
}) => {
    const { levelDetails, handleSnackbar } = props;
    const { t } = useTranslation();
    const { projectId, levelId } =
        useParams<{
            projectId: string;
            levelId: string;
        }>();
    const methods = useForm({
        mode: "onBlur",
    });
    const history = useHistory();

    const [changes, setChanges] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    useEffect(() => {
        let result = formatFormResult(methods.watch());
        if (!checkObjectEquality(result, levelDetails) && !changes)
            setChanges(true);
        else if (checkObjectEquality(result, levelDetails) && changes)
            setChanges(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.watch()]);

    function formatFormResult(results: { [key: string]: any }) {
        let title: { [key: string]: string } = {};
        for (let key in results) {
            if (key.slice(key.length - 5) === "Title") {
                if (results[key] !== "") {
                    let lan = key.slice(0, key.length - 5);
                    title[lan] = results[key];
                }
            }
        }
        let formatted: AdminLevel = {
            id: levelDetails.id,
            number: levelDetails.number,
            title: title,
            competences: results.competences,
            instructorInfo: results.instructorLink,
            steps: levelDetails.steps,
        };
        return formatted;
    }

    const onDiscard = () => methods.reset();
    const onSave = () => {
        if (uploaded || uploading) return;
        let result = formatFormResult(methods.watch());
        setUploading(true);
        setChanges(false);
        ApiService.postLevelDescriptionUpdate(projectId, levelId, result)
            .then(() => {
                handleSnackbar({
                    message: t("Level updated successfully"),
                    type: "success",
                });
                setTimeout(() => history.go(0), 3000);
                setUploaded(true);
            })
            .catch(() => {
                handleSnackbar({
                    message: t(
                        "Error reaching our end. Please try again later"
                    ),
                    type: "error",
                });
            })
            .finally(() => {
                setUploading(false);
            });
    };

    function getSelectOptions(options: string[]): SelectOptionProps[] {
        return options.map((val) => {
            return {
                display: t(val),
                value: val,
            };
        });
    }

    return (
        <Card className={styles.cardContainer}>
            <FormProvider {...methods}>
                <form>
                    <h1>{t("Level description")}</h1>
                    <div className={styles.formContainer}>
                        <div className={styles.inputContainer}>
                            <LevelNames levelDetails={levelDetails} />
                        </div>
                        <div className={styles.horizontal}>
                            <div className={styles.halfContainer}>
                                <label className={styles.mediumSizeFont}>
                                    {t("Type of competence worked")}
                                </label>
                                <div className={styles.inputContainer}>
                                    <SelectInput
                                        name="competences"
                                        options={getSelectOptions(
                                            competences.sort()
                                        )}
                                        defaultValue={levelDetails.competences}
                                        multiple
                                        chips
                                    />
                                </div>
                            </div>
                            <div className={styles.halfContainer}>
                                <label className={styles.mediumSizeFont}>
                                    {t("Instructor information (link)")}
                                </label>
                                <div className={styles.inputContainer}>
                                    <TextInput
                                        name="instructorLink"
                                        mandatory
                                        multiline
                                        defaultValue={
                                            levelDetails.instructorInfo
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {uploading ? <Loading /> : undefined}
                    {changes ? (
                        <SaveDiscardButtons
                            onDiscard={onDiscard}
                            onSave={onSave}
                        />
                    ) : null}
                </form>
            </FormProvider>
        </Card>
    );
};

const LevelNames = (props: { levelDetails: AdminLevel }) => {
    const { levelDetails } = props;
    const [possibleLanguages, setPossibleLanguages] = useState<string[]>();
    const { t } = useTranslation();

    let languages = Object.getOwnPropertyNames(
        projectInformation.languages.possibleLanguages
    );
    let defaultLanguage = projectInformation.languages.defaultLanguage;

    useEffect(() => {
        languages.sort((a, b) => {
            if (a === defaultLanguage) return -1;
            if (b === defaultLanguage) return 1;
            if (a > b) return 1;
            if (b > a) return -1;
            return 0;
        });
        setPossibleLanguages(languages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Accordion className={styles.accordion}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <p className={styles.mediumSizeFont}>{t("Level Name")} </p>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
                {possibleLanguages?.map((val, ind) => (
                    <div className={styles.inputContainer} key={ind}>
                        <label className={styles.mediumSizeFont}>
                            {t(val)}
                        </label>
                        <TextInput
                            name={`${val}Title`}
                            defaultValue={levelDetails.title[val]}
                        />
                    </div>
                ))}
            </AccordionDetails>
        </Accordion>
    );
};

const SecondForm = (props: {
    levelDetails: AdminLevel;
    handleSnackbar: (snackbar: SnackbarProps) => void;
}) => {
    const { t } = useTranslation();

    const [changes, setChanges] = useState(false);
    const [steps, setSteps] = useState<PostLevelStep[]>([]);
    const [originalOrder, setOriginalOrder] = useState<PostLevelStep[]>([]);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [creationModal, setCreationModal] = useState(false);

    const history = useHistory();
    const { projectId, levelId } =
        useParams<{
            projectId: string;
            levelId: string;
        }>();

    const { levelDetails, handleSnackbar } = props;

    useEffect(() => {
        if (!levelDetails.steps) return;
        setOriginalOrder(Array.from(levelDetails.steps));
        setSteps(levelDetails.steps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (changes) window.onbeforeunload = () => true;
        else window.onbeforeunload = () => null;
    }, [changes]);

    if (!arraysEqual(originalOrder, steps) && !changes) {
        setChanges(true);
    } else if (arraysEqual(originalOrder, steps) && changes) {
        setChanges(false);
    }

    function onDragChange(newSteps: PostLevelStep[]) {
        setSteps(newSteps);
    }

    async function saveOrder() {
        if (uploaded || uploading) return;
        setUploading(true);
        ApiService.postAllLevelStepUpdates(projectId, levelId, steps)
            .then(() => {
                handleSnackbar({
                    message: t("Level updated successfully"),
                    type: "success",
                });
                setChanges(false);
                setUploaded(true);
                setTimeout(() => {
                    window.onbeforeunload = () => null;
                    history.go(0);
                }, 2000);
            })
            .catch(() =>
                handleSnackbar({
                    message: t(
                        "Error reaching our end. Please try again later"
                    ),
                    type: "error",
                })
            )
            .finally(() => setUploading(false));
    }

    function discardOrder() {
        setSteps(originalOrder);
        setChanges(false);
    }

    function createNewStep() {
        setCreationModal(true);
    }

    function handleModalClose() {
        setCreationModal(false);
    }

    return (
        <Card className={styles.cardContainer}>
            {creationModal ? (
                <StepModal
                    open={creationModal}
                    projectInformation={projectInformation}
                    onClose={handleModalClose}
                />
            ) : undefined}
            <div className={styles.stepsTitleContainer}>
                <h1>{t("Steps")}</h1>
                <IconContext.Provider
                    value={{
                        className: styles.addIcon,
                    }}
                >
                    <FaPlusCircle onClick={() => createNewStep()} />
                </IconContext.Provider>
            </div>
            {steps.length > 0 ? (
                <DraggableList steps={steps} onChange={onDragChange} />
            ) : (
                <h2>{t("There are no steps yet")}</h2>
            )}
            {changes ? (
                <SaveDiscardButtons
                    onSave={saveOrder}
                    onDiscard={discardOrder}
                />
            ) : null}
        </Card>
    );
};

const DraggableList = (props: {
    onChange: Function;
    steps: PostLevelStep[];
}) => {
    const { onChange, steps } = props;

    function reorder(list: any[], startIndex: number, endIndex: number) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }
        let changedSteps = reorder(
            steps,
            result.source.index,
            result.destination.index
        );
        onChange(changedSteps);
    }

    return (
        <div className={styles.draggableContainer}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="stepsDroppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="overflow-y"
                            style={{ height: "100%" }}
                        >
                            {steps.map((step, ind) => (
                                <Draggable
                                    key={ind}
                                    draggableId={ind.toString()}
                                    index={ind}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <Step
                                                dragHandleProps={
                                                    provided.dragHandleProps
                                                }
                                                step={step}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

const Step = (props: {
    step: PostLevelStep;
    dragHandleProps: DraggableProvidedDragHandleProps | undefined;
}) => {
    const { step, dragHandleProps } = props;
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    return (
        <div className={`${styles.stepItemContainer} shadow`}>
            <div className={`${styles.stepHeaderContainer} shadow`}>
                {open ? (
                    <StepModal
                        open={open}
                        onClose={handleClose}
                        step={step}
                        projectInformation={projectInformation}
                    />
                ) : null}
                <div {...dragHandleProps} className={styles.handlerContainer}>
                    <IconContext.Provider
                        value={{ className: styles.handlerIcon }}
                    >
                        <MdDragHandle />
                    </IconContext.Provider>
                </div>
                <p>{`${t(step.type.replace(/([A-Z])/g, " $1").trim())}`}</p>
                <div className={styles.buttonsContainer}>
                    <IconContext.Provider value={{ className: styles.icon }}>
                        <FaPencilAlt onClick={() => handleOpen()} />
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    );
};

const SaveDiscardButtons = (props: {
    onDiscard: Function;
    onSave: Function;
}) => {
    const { onDiscard, onSave } = props;
    const { t } = useTranslation();
    return (
        <div className={styles.saveDiscardButtonsContainer}>
            <div className={styles.buttonContainer}>
                <Button
                    text={t("Discard")}
                    type="greyOutline"
                    onPress={onDiscard}
                />
            </div>
            <div className={styles.buttonContainer}>
                <Button text={t("Save")} onPress={onSave} />
            </div>
        </div>
    );
};

function arraysEqual(a: any[], b: any[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}
