import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface IInputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {}

export const Input: React.FC<{
    inputProps: IInputProps;
    mandatory?: boolean;
}> = (props) => {
    const { name } = props.inputProps;
    const { errors, register } = useFormContext();
    const errorMessage = errors[name!];
    const { t } = useTranslation();
    return (
        <>
            <input
                {...props.inputProps}
                id={name}
                ref={register({ required: props.mandatory })}
            />
            {errorMessage ? (
                <p className="error">{t("This is mandatory")}</p>
            ) : null}
        </>
    );
};
