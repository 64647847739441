import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { TempUser } from "./newUser/TempUser";
import { UsersList } from "./usersList/UsersList";

export const Users = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/new-user`}>
                <TempUser />
            </Route>
            <Route path={`${match.path}/users-list`}>
                <UsersList />
            </Route>
            <Route path={`${match.path}/:userId`}>
                <TempUser />
            </Route>
        </Switch>
    );
};
