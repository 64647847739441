import { functionsApp } from "../firebase";
import { ClassDay, GroupInformation } from "./Group";
import { ParticipantsTeamInformation } from "./ParticipantsTeam";

export interface InstructorInformation {
    authUid: string[];
    id: string;
    name: string;
    surname: string;
    role: "instructor" | "admin";
}

export interface InstructorClassDay extends ClassDay {
    groupName: string;
    groupId: string;
}

export class Instructor {
    static async getAllInstructors(): Promise<InstructorInformation[]> {
        const response = await functionsApp.httpsCallable(
            "instructors-getAllInstructors"
        )();
        return response.data as InstructorInformation[];
    }

    static async getInstructorFromAuthUid(
        authUid: string
    ): Promise<InstructorInformation> {
        const response = await functionsApp.httpsCallable(
            "instructors-getInstructorFromAuthUid"
        )({ authUid: authUid });
        return response.data as InstructorInformation;
    }

    static async getInstructorWeekSchedule(instructorId: string) {
        const response = await functionsApp.httpsCallable(
            "instructors-getInstructorWeekSchedule"
        )({ instructorId: instructorId });
        const data = response.data as InstructorClassDay[][];
        return data.flat();
    }

    static async getGroupsWithPendingActivities(instructorId: string) {
        const response = await functionsApp.httpsCallable(
            "instructors-getGroupsWithPendingActivities"
        )({ instructorId: instructorId });

        return response.data as ParticipantsTeamInformation[];
    }
}
