// components/FormComponents/FileInput.tsx
import React, { FC, useCallback, useEffect } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import styles from "./FileInput.module.css";

interface IFileInputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {}

export const FileInput: FC<{
    inputProps: IFileInputProps;
    height: number;
    mandatory?: boolean;
    button?: boolean;
    defaultValue?: any;
    maxFiles?: number;
    style?: React.CSSProperties;
    className?: string;
    placeholder?: string;
}> = (props) => {
    const { name } = props.inputProps;
    const { register, unregister, setValue, watch, errors } = useFormContext();
    const files: File[] = watch(name!);

    const defaultValue = props.defaultValue;
    const placeholder = props.placeholder ?? "Seleccioni un fitxer";

    const errorMessage = errors[name!];

    const { t } = useTranslation();

    const onDrop = useCallback<DropzoneOptions["onDrop"] | any>(
        (droppedFiles: any) => {
            setValue(name!!, droppedFiles, { shouldValidate: true });
        },
        [setValue, name]
    );

    const { getRootProps, getInputProps, isDragAccept, isDragReject } =
        useDropzone({
            onDrop,
            accept: props.inputProps.accept,
            maxFiles: props.maxFiles ?? 1,
        });

    useEffect(() => {
        register(name!, { required: props.mandatory });
        return () => {
            unregister(name!);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [register, unregister, name]);

    return (
        <div
            className={`${styles.container} ${props.className}`}
            style={props.style}
        >
            {!props.button ? (
                <>
                    <div
                        {...getRootProps()}
                        className={`${styles.inputContainer} ${
                            isDragAccept ? styles.dragAccept : ``
                        } shadow ${styles.noOutline} ${
                            isDragReject ? styles.dragReject : ``
                        }`}
                        style={{ height: props.height }}
                    >
                        <input
                            {...props.inputProps}
                            id={name}
                            {...getInputProps()}
                            className={styles.noOutline}
                        />
                        {files?.length >= 1 ? (
                            files?.length > 1 ? (
                                <p>{filesToString(files)}</p>
                            ) : (
                                <div key={files[0].name} className="fill">
                                    <img
                                        src={URL.createObjectURL(files[0])}
                                        alt={files[0].name}
                                        className={`${styles.borderRadius} fit-cover-image`}
                                    />
                                </div>
                            )
                        ) : defaultValue ? (
                            <div className="fill">
                                <img
                                    src={defaultValue}
                                    alt={"Preselected file"}
                                    className={`${styles.borderRadius} fit-cover-image`}
                                />
                            </div>
                        ) : (
                            <div className={styles.message}>
                                <FaCloudDownloadAlt className={styles.icon} />
                                <p>
                                    <span className="bold">
                                        {t("Select a file")}
                                    </span>
                                    <br /> {t("or drop it here")}
                                </p>
                            </div>
                        )}
                    </div>
                    {errorMessage ? (
                        <p className="error">{t("This is mandatory")}</p>
                    ) : null}
                </>
            ) : (
                <>
                    <div
                        {...getRootProps()}
                        className={`${styles.inputContainer} ${
                            isDragAccept ? styles.dragAccept : ``
                        } ${isDragReject ? styles.dragReject : ``} shadow ${
                            styles.noOutline
                        } ${files?.length >= 1 ? styles.blueBackground : ""}`}
                        style={{ height: props.height }}
                    >
                        <input
                            {...props.inputProps}
                            id={name}
                            {...getInputProps()}
                            multiple={false}
                            className={styles.noOutline}
                        />
                        {files?.length >= 1 || defaultValue ? (
                            <div className={styles.fileName}>
                                <p>{t("File selected")}</p>
                            </div>
                        ) : (
                            <div className={styles.message}>
                                <p className="bold">{placeholder}</p>
                            </div>
                        )}
                    </div>
                    {errorMessage ? (
                        <p className="error">{t("This is mandatory")}</p>
                    ) : null}
                </>
            )}
        </div>
    );
};

function filesToString(files: File[]) {
    return files.map((val) => val.name).toString();
}
