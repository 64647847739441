import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Loading } from "./components/ui/loading/Loading";
import firebaseApp from "./firebase";
import { AuthContext } from "./provider/Auth";

/** Protects all its children from accessing without being logged and having enough
 * permissions. If the user is logged in, it signs it out and redirects it to login */

export const PrivateRoute = (props: {
    children: any;
    /** It must at least have the path for the children */
    routeProps: RouteProps;
}) => {
    const authContext = useContext(AuthContext);
    const { children, routeProps } = props;

    /** In this state we will save the status of the access and will be used to
     * determine the route */
    const [access, setAccess] =
        useState<"noAuth" | "ok" | "invalid" | null>(null);

    /** Computes what type of access the user has */
    const getAccess = async () => {
        /** If there is no authContext, it sets access to noAuth */
        if (!authContext) {
            setAccess("noAuth");
            return;
        }

        /** If the context shows that it is loading, we return and do nothing */
        if (authContext.loading) {
            return;
        }

        /** If there is a user *and* its role is admin or instructor, it sets access to ok
         *  If there is a user *and* its role isn't admin or instructor, it sets access to invalid.
         *  If there is not a user, it sets access to invalid */
        if (authContext.currentUser) {
            if (
                authContext.role === "admin" ||
                authContext.role === "instructor"
            ) {
                setAccess("ok");
                return;
            } else {
                firebaseApp.auth().signOut();
                setAccess("invalid");
                return;
            }
        } else setAccess("invalid");
    };

    /** IMPORTANT: When the authContext changes, the function getAccess runs again */
    useEffect(() => {
        getAccess();
    }, [authContext]);

    /** If we have no access, it means that the auth is loading. Thus, we return Loading */
    if (!access) {
        return <Loading />;
    }

    return (
        <Route
            {...routeProps}
            render={() => {
                switch (access) {
                    case "ok":
                        return children;
                    /** If it is invalid, we redirect to login and indicate in the param that
                     *  the role is invalid */
                    case "invalid":
                        return <Redirect to="/login/invalid-role" />;
                    default:
                        return <Redirect to="/login" />;
                }
            }}
        />
    );
};
