import { functionsApp } from "../firebase";

export interface ParticipantsTeamSummary {
    teamId: string;
    participantsIds: string[];
    participantsNames?: string[];
    groupId: string;
    projectId: string;
}

export interface ParticipantsTeamInformation extends ParticipantsTeamSummary {
    progress?: LevelProgress[];
}

export class ParticipantsTeam implements ParticipantsTeamInformation {
    progress?: LevelProgress[];
    teamId: string;
    participantsIds: string[];
    participantsNames?: string[];
    groupId: string;
    projectId: string;

    constructor(data: ParticipantsTeamInformation) {
        this.progress = data.progress;
        this.teamId = data.teamId;
        this.participantsIds = data.participantsIds;
        this.groupId = data.groupId;
        this.projectId = data.projectId;
    }

    static async getFromId(teamId: string) {
        const response = await functionsApp.httpsCallable(
            "participants-getFromId"
        )({ teamId: teamId });
        return new ParticipantsTeam(
            response.data as ParticipantsTeamInformation
        );
    }

    static async getMultipleTeamsInformationFromId(
        teamsIds: string[],
        progressUrls?: boolean,
        participantsNames?: boolean
    ) {
        const response = await functionsApp.httpsCallable(
            "participants-getMultipleTeamsFromIds"
        )({
            teamsIds: teamsIds,
            progressUrls: progressUrls,
            participantsNames: participantsNames,
        });
        return response.data as ParticipantsTeamInformation[];
    }

    static async postFeedback(
        teamId: string,
        levelId: string,
        feedback: Feedback
    ) {
        const response = await functionsApp.httpsCallable(
            "participants-postFeedback"
        )({ teamId: teamId, levelId: levelId, feedback: feedback });
        return response.data;
    }

    static async getParticipantsNames(teams: ParticipantsTeamInformation[]) {
        const response = await functionsApp.httpsCallable(
            "participants-getMultipleParticipantsNames"
        )({ teams: teams });
        return response.data as ParticipantsTeamInformation[];
    }
}

export interface LevelProgress {
    levelId: string;
    resultSent?: LevelSubmission;
    feedback?: Feedback;
    stars: number;
}

interface LevelSubmission {
    message: string;
    attachment?: boolean;
    attachmentBase64?: string;
    attachmentUrl?: string;
    timestamp: number;
}

export type Feedback = {
    stars: number;
    content: FeedbackText | FeedbackVideo;
    instructorId: string;
};

export interface FeedbackText {
    type: "text";
    text: string;
}

export interface FeedbackVideo {
    type: "video";
    video: string;
}
