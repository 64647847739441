import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
    FieldValues,
    FormProvider,
    useForm,
    UseFormMethods,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { ApiService } from "../../../../apis/ApiService";
import {
    ClassDay,
    Group,
    GroupInformation,
    WeekDay,
} from "../../../../apis/Group";
import { Instructor, InstructorInformation } from "../../../../apis/Instructor";
import { ParticipantsTeamSummary } from "../../../../apis/ParticipantsTeam";
import { AdminLevelSummary } from "../../../../apis/ProjectInterfaces";
import { User } from "../../../../apis/User";
import { SaveDiscardButtons } from "../../../../components/ui/button/Button";
import { Card } from "../../../../components/ui/card/Card";
import {
    SelectInput,
    SelectOptionProps,
} from "../../../../components/ui/input/selectInput/SelectInput";
import { TextInput } from "../../../../components/ui/input/textInput/TextInput";
import { Loading } from "../../../../components/ui/loading/Loading";
import { Path } from "../../../../components/ui/path/Path";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../../../components/ui/snackbar/Snackbar";
import {
    checkObjectEquality,
    codeToLan,
    deleteAndAttachUrl,
    getLanguageOptions,
    getUuidv4,
    toTextFieldDateString,
} from "../../../../utils/functions";

import styles from "./AdminNewGroup.module.css";

export const AdminNewGroup = () => {
    const { groupId } = useParams<{ groupId?: string }>();

    const [savedGroupInfo, setSavedGroupInfo] = useState<GroupInformation>();
    const [projectName, setProjectName] = useState<string>();
    const [instructors, setInstructors] = useState<InstructorInformation[]>();

    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);

    const [snackbar, setSnackbar] = useState<SnackbarProps>({ message: "" });
    const [fatalError, setFatalError] = useState("");

    const [classDays, setClassDays] = useState<ClassDay[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("Group Creation");

        Promise.all([getGroup(), getInstructors()])
            .catch(() =>
                setFatalError(
                    t(
                        "There was a problem reaching the group. It may not exist or have a problem with the connection"
                    )
                )
            )
            .finally(() => setLoading(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSnackbar = (snackbar: SnackbarProps) => setSnackbar(snackbar);

    const onClassDaysChange = (classDays: ClassDay[]) =>
        setClassDays(classDays);

    const handleUploading = (uploading: boolean) => setUploading(uploading);

    async function getGroup() {
        if (groupId) {
            const group = await Group.getFromId(groupId);
            let groupInfo = group.getGroupInformation();
            setClassDays(groupInfo.schedule);
            setSavedGroupInfo(groupInfo);
        }
    }

    async function getInstructors() {
        const instructors = await Instructor.getAllInstructors();
        setInstructors(instructors);
    }

    if (loading) return <Loading />;
    if (fatalError) return <h1>{fatalError}</h1>;

    return (
        <div className={`app-body-container-box overflow-y`}>
            <div className={styles.headerContainer}>
                <Header />
            </div>
            <GroupInformationElement
                projectName={projectName}
                savedGroupInfo={savedGroupInfo}
                classDays={classDays}
                instructors={instructors!}
                onClassDaysChange={onClassDaysChange}
                handleSnackbar={handleSnackbar}
                handleUploading={handleUploading}
            />
            {groupId ? (
                <div className={`${styles.participantsLevelsContainer}`}>
                    {/*                     <ParticipantsInformationElement
                        teams={savedGroupInfo?.teamsSummaries ?? []}
                    />
 */}
                    <GroupLevelsElement
                        projectId={savedGroupInfo!.projectId}
                        levelsUnlocked={savedGroupInfo?.unlockedLevels}
                        groupId={groupId}
                        handleSnackbar={handleSnackbar}
                        handleUploading={handleUploading}
                    />
                </div>
            ) : undefined}
            <CustomSnackbar
                handleSnackbarClose={handleSnackbar}
                snackbar={snackbar}
            />
            {uploading ? <Loading fullScreenLoading /> : null}
        </div>
    );
};

const Header = () => {
    const { t } = useTranslation();
    return (
        <Path
            elements={[
                { text: t("Administration"), linkTo: "/administration" },
                {
                    text: t("Groups"),
                    linkTo: "/administration/groups/",
                },
                { text: t("Create or edit a group") },
            ]}
        />
    );
};

interface GroupFormResults extends ClassDayForm {
    groupName: string;
    videoCallRoom: string;
    projectId: string;
    startDate: string;
    finishDate: string;
    maxNumChildren: string;
    open: boolean;
    visible: boolean;
    defaultLanguage: string;
    instructorIds: string[];
}

const GroupInformationElement = (props: {
    savedGroupInfo: GroupInformation | undefined;
    projectName: string | undefined;
    classDays: ClassDay[];
    instructors: InstructorInformation[];
    onClassDaysChange: (classDays: ClassDay[]) => void;
    handleSnackbar: (snackbar: SnackbarProps) => void;
    handleUploading: (uploading: boolean) => void;
}) => {
    const {
        savedGroupInfo,
        projectName,
        classDays,
        instructors,
        onClassDaysChange,
        handleSnackbar,
        handleUploading,
    } = props;

    const history = useHistory();
    const match = useRouteMatch();
    const { t } = useTranslation();
    const groupForm = useForm({ mode: "onBlur" });

    const onSave = async () => {
        if (await groupForm.trigger()) {
            handleUploading(true);

            const newGroup = formatFormResults(
                groupForm.getValues() as GroupFormResults
            );

            try {
                const responseGroup = await Group.postGroup(newGroup);
                handleSnackbar({
                    message: t("Data updated"),
                    type: "success",
                });
                if (!savedGroupInfo) {
                    const path = deleteAndAttachUrl(
                        match.path,
                        "groups",
                        `/edit-group/${responseGroup.groupId}`
                    );
                    setTimeout(() => {
                        history.push(path);
                        history.go(0);
                    }, 3000);
                }
            } catch {
                handleSnackbar({
                    message: t(
                        "Error uploading the data. Try again later or contact the admin"
                    ),
                    type: "error",
                });
            } finally {
                handleUploading(false);
            }
        }
    };

    const onDiscard = async () => history.go(0);

    const formatFormResults = (formResults: GroupFormResults) => {
        let formatted: GroupInformation = {
            groupId: savedGroupInfo?.groupId ?? getUuidv4(),
            schedule: classDays,
            startDate: new Date(formResults.startDate).getTime(),
            finishDate: new Date(formResults.finishDate).getTime(),
            name: formResults.groupName,
            projectId: formResults.projectId,
            videoCallRoom: formResults.videoCallRoom,
            instructorIds: formResults.instructorIds,
            language: formResults.defaultLanguage,
            maxNumChildren: +formResults.maxNumChildren,
            open: formResults.open,
            visible: formResults.visible,
        };
        return formatted;
    };

    const addClassDay = () => onClassDaysChange([...classDays, {}]);
    const deleteClassDay = (ind: number) => {
        const copiedArray = [...classDays];
        copiedArray.splice(ind, 1);
        onClassDaysChange(copiedArray);
    };

    const changeClassDay = (ind: number, classDay: ClassDayForm) => {
        let formatClassDay: ClassDay = {};

        for (const key in classDay) {
            if (key.includes("day"))
                formatClassDay.weekDay = (classDay as any)[key] as WeekDay;
            if (key.includes("start"))
                formatClassDay.startHour = (classDay as any)[key];
            if (key.includes("end"))
                formatClassDay.finishHour = (classDay as any)[key];
        }
        const copiedArray = [...classDays];
        copiedArray[ind] = formatClassDay;
        onClassDaysChange(copiedArray);
    };

    const formatInstructors = (
        instructors: InstructorInformation[]
    ): SelectOptionProps[] => {
        return instructors.map((instr) => {
            return {
                display: `${instr.name} ${instr.surname}`,
                value: instr.id,
            };
        });
    };

    let startDate: string | undefined = undefined;
    let finishDate: string | undefined = undefined;

    if (savedGroupInfo?.startDate)
        startDate = toTextFieldDateString(savedGroupInfo.startDate);

    if (savedGroupInfo?.finishDate)
        finishDate = toTextFieldDateString(savedGroupInfo.finishDate);

    return (
        <Card className={styles.card}>
            <FormProvider {...groupForm}>
                <form>
                    <div className={styles.cardHeader}>
                        <h2>{t("Group Information")}</h2>
                        <SaveDiscardButtons
                            onDiscard={onDiscard}
                            onSave={onSave}
                            className={styles.saveDiscardButtons}
                        />
                    </div>
                    <div
                        className={`${styles.groupInformationFieldsContainer} ${styles.cardInterior}`}
                    >
                        <div className={styles.leftContainer}>
                            <label>{t("Group Default Language")}</label>
                            <SelectInput
                                name="defaultLanguage"
                                options={getLanguageOptions(t)}
                                mandatory
                                className={styles.inputsMargin}
                                defaultValue={savedGroupInfo?.language}
                            />
                            <label>{t("Group name")}</label>
                            <TextInput
                                name="groupName"
                                mandatory
                                defaultValue={savedGroupInfo?.name}
                                className={styles.inputsMargin}
                            />
                            <label>{t("Link to videocall room")}</label>
                            <TextInput
                                name="videoCallRoom"
                                mandatory
                                defaultValue={savedGroupInfo?.videoCallRoom}
                                className={styles.inputsMargin}
                            />
                            <label>{t("Project Id")}</label>
                            <TextInput
                                name="projectId"
                                mandatory
                                defaultValue={savedGroupInfo?.projectId}
                                className={styles.inputsMargin}
                            />
                            <label>{t("Instructors")}</label>
                            <SelectInput
                                name="instructorIds"
                                options={formatInstructors(instructors)}
                                multiple
                                defaultValue={savedGroupInfo?.instructorIds}
                            />
                            {projectName ? <p>{projectName}</p> : null}
                        </div>
                        <div className={styles.rightContainer}>
                            <label>{t("Start Date")}</label>
                            <TextInput
                                name="startDate"
                                type="date"
                                mandatory
                                defaultValue={startDate}
                                className={styles.inputsMargin}
                            />
                            <label>{t("Finish Date")}</label>
                            <TextInput
                                name="finishDate"
                                type="date"
                                mandatory
                                defaultValue={finishDate}
                                className={styles.inputsMargin}
                            />
                            <label>{t("Maximum number of children")}</label>
                            <TextInput
                                name="maxNumChildren"
                                mandatory
                                defaultValue={savedGroupInfo?.maxNumChildren.toString()}
                                className={styles.inputsMargin}
                                short
                            />
                            <div
                                className={`${styles.row} ${styles.fullWidth}`}
                            >
                                <label>{t("Class days")}</label>
                                <FaPlusCircle
                                    className={`icon ${styles.rightIcon}`}
                                    onClick={() => addClassDay()}
                                />
                            </div>

                            {classDays.map((val, ind) => (
                                <DayHourPicker
                                    defaultValue={val}
                                    key={ind}
                                    ind={ind}
                                    deleteClass={deleteClassDay}
                                    form={groupForm}
                                    changeClassDays={changeClassDay}
                                />
                            ))}
                            <div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="open"
                                            inputRef={groupForm.register()}
                                            color="primary"
                                            defaultChecked={
                                                savedGroupInfo?.open
                                            }
                                        />
                                    }
                                    label={t("Open")}
                                    className={styles.inputsMargin}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="visible"
                                            inputRef={groupForm.register()}
                                            color="primary"
                                            defaultChecked={
                                                savedGroupInfo?.visible
                                            }
                                        />
                                    }
                                    label={t("Visible")}
                                    className={styles.inputsMargin}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Card>
    );
};

interface ClassDayForm {
    day0?: WeekDay;
    startHour0?: string;
    endHour0?: string;
    day1?: WeekDay;
    startHour1?: string;
    endHour1?: string;
    day2?: WeekDay;
    startHour2?: string;
    endHour2?: string;
    day3?: WeekDay;
    startHour3?: string;
    endHour3?: string;
}

const DayHourPicker = (props: {
    defaultValue: ClassDay;
    ind: number;
    deleteClass: (ind: number) => void;
    form: UseFormMethods<FieldValues>;
    changeClassDays: (ind: number, classDay: ClassDayForm) => void;
}) => {
    const { ind, deleteClass, defaultValue, form, changeClassDays } = props;
    const [lastState, setLastState] = useState<ClassDayForm>({});
    const { t } = useTranslation();

    function getDayOptions(): SelectOptionProps[] {
        return [
            { display: t("Monday"), value: "monday" },
            { display: t("Tuesday"), value: "tuesday" },
            { display: t("Wednseday"), value: "wednesday" },
            { display: t("Thursday"), value: "thursday" },
            { display: t("Friday"), value: "friday" },
            { display: t("Saturday"), value: "saturday" },
            { display: t("Sunday"), value: "sunday" },
        ];
    }

    let currentResult = form.watch([
        `day${ind}`,
        `startHour${ind}`,
        `endHour${ind}`,
    ]);

    if (
        !checkObjectEquality(currentResult, lastState) &&
        !checkObjectEquality(currentResult, {
            day0: undefined,
            endHour0: undefined,
            startHour: undefined,
        })
    ) {
        changeClassDays(
            ind,
            form.getValues([`day${ind}`, `startHour${ind}`, `endHour${ind}`])
        );
        setLastState(
            form.getValues([`day${ind}`, `startHour${ind}`, `endHour${ind}`])
        );
    }

    return (
        <div className={styles.dayHourPickerContainer}>
            <div className={styles.dayHourPicker}>
                <SelectInput
                    name={`day${ind}`}
                    options={getDayOptions()}
                    mandatory
                    className={`${styles.selectDay} ${styles.inputsMargin}`}
                    defaultValue={defaultValue.weekDay}
                    changeValue={defaultValue.weekDay}
                />
                <TextInput
                    name={`startHour${ind}`}
                    type="time"
                    className={`${styles.selectHour} ${styles.inputsMargin}`}
                    defaultValue={defaultValue.startHour}
                    changeValue={defaultValue.startHour}
                />
                <TextInput
                    name={`endHour${ind}`}
                    type="time"
                    className={`${styles.selectHour} ${styles.inputsMargin}`}
                    defaultValue={defaultValue.finishHour}
                    changeValue={defaultValue.finishHour}
                />
            </div>
            <FaMinusCircle
                onClick={() => deleteClass(ind)}
                className={`icon`}
            />
        </div>
    );
};

const GroupLevelsElement = (props: {
    groupId: string;
    handleSnackbar: (snackbar: SnackbarProps) => void;
    handleUploading: (uploading: boolean) => void;
    projectId: string;
    levelsUnlocked?: {
        id: string;
        unlocked: boolean;
    }[];
}) => {
    const {
        groupId,
        handleSnackbar,
        handleUploading,
        projectId,
        levelsUnlocked,
    } = props;

    const [levels, setLevels] = useState<AdminLevelSummary[]>();
    const [defaultLanguage, setDefaultLanguage] = useState("");
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        ApiService.getProjectInformationFromId(projectId)
            .then((res) => {
                setLevels(res.levels ? Object.values(res.levels) : []);
                setDefaultLanguage(res.languages.defaultLanguage);
            })
            .catch(() => setLevels([]))
            .finally(() => setLoading(false));
    }, []);

    const sortLevels = (a: AdminLevelSummary, b: AdminLevelSummary) => {
        if (a.number < b.number) return -1;
        else if (a.number === b.number) return 0;
        else return 1;
    };

    const levelForm = useForm({ mode: "onBlur" });

    const getDefaultChecked = (level: AdminLevelSummary) => {
        return (
            levelsUnlocked?.find((uLevel) => uLevel.id === level.id)
                ?.unlocked ?? false
        );
    };

    const onDiscard = () => {};
    const onSave = async () => {
        handleUploading(true);
        let formResult = levelForm.getValues() as { [index: string]: boolean };
        const formProperties = Object.getOwnPropertyNames(formResult);
        const unlockedLevels = formProperties.sort().map((key) => {
            return { id: key, unlocked: formResult[key] };
        });
        try {
            await Group.postLevels(groupId, unlockedLevels);
            handleSnackbar({
                message: t("Data updated"),
                type: "success",
            });
        } catch {
            handleSnackbar({
                message: t(
                    "Error uploading the data. Try again later or contact the admin"
                ),
                type: "error",
            });
        } finally {
            handleUploading(false);
        }
    };

    if (loading) return <Loading />;

    return (
        <Card className={`${styles.card} ${styles.levelsCard}`}>
            <div className={styles.cardHeader}>
                <h2>{t("Levels")}</h2>
                <SaveDiscardButtons
                    onDiscard={onDiscard}
                    onSave={onSave}
                    className={styles.noMarginTop}
                />
            </div>
            <div className={`${styles.levelsContainer} ${styles.cardInterior}`}>
                {levels!.sort(sortLevels).map((level, ind) => {
                    return (
                        <LevelElement
                            key={ind}
                            level={level}
                            levelForm={levelForm}
                            defaultChecked={getDefaultChecked(level)}
                            defaultLanguage={defaultLanguage}
                        />
                    );
                })}
            </div>
        </Card>
    );
};

const LevelElement = (props: {
    level: AdminLevelSummary;
    levelForm: UseFormMethods<FieldValues>;
    defaultChecked: boolean;
    defaultLanguage: string;
}) => {
    const { level, levelForm, defaultChecked } = props;

    const { t, i18n } = useTranslation();

    const getLabelText = () => {
        if (levelForm.watch(level.id) === true) return t("Unlocked");
        else if (levelForm.watch(level.id) === undefined)
            return defaultChecked ? t("Unlocked") : t("Locked");
        else return t("Locked");
    };

    const language = codeToLan(i18n.language);

    return (
        <div
            className={`${styles.row} ${styles.centerLevels} ${styles.inputsMargin}`}
        >
            <p>{`${level.number}. ${
                level.title[language ?? props.defaultLanguage] ??
                level.title[props.defaultLanguage]
            }`}</p>
            <FormControlLabel
                control={
                    <Switch
                        name={`${level.id}`}
                        inputRef={levelForm.register()}
                        color="primary"
                        defaultChecked={defaultChecked}
                    />
                }
                label={getLabelText()}
            />
        </div>
    );
};

const ParticipantsInformationElement = (props: {
    teams: ParticipantsTeamSummary[];
}) => {
    let { teams } = props;

    const deleteTeam = (ind: number) => {
        let deletedTeam = teams[ind];
        teams = teams.splice(ind, 1);
        //TODO: Actually delete the team
        /*         ParticipantsTeam.deleteTeam(deletedTeam.teamId)
         */
    };
    return (
        <Card className={`${styles.card} ${styles.flexgrow}`}>
            <div className={styles.cardHeader}>
                <h2>Participants Information</h2>
            </div>

            {teams.length > 0 ? (
                teams.map((team, ind) => (
                    <TeamElement
                        team={team}
                        ind={ind}
                        key={ind}
                        onDeleteTeam={deleteTeam}
                    />
                ))
            ) : (
                <p>There are no participants yet</p>
            )}
        </Card>
    );
};

const TeamElement = (props: {
    team: ParticipantsTeamSummary;
    ind: number;
    onDeleteTeam: (ind: number) => void;
}) => {
    const { team, ind, onDeleteTeam } = props;

    const [participants, setParticipants] =
        useState<{ name: string; imageUrl?: string; userId: string }[]>();
    const [loading, setLoading] = useState(true);
    const [idCopied, setIdCopied] = useState(false);

    useEffect(() => {
        setLoading(true);
        getParticipantsInfo().finally(() => setLoading(false));
    }, [props.team]);

    async function getParticipantsInfo() {
        const infoPromise: Promise<{
            name: string;
            imageUrl?: string;
            userId: string;
        }>[] = team.participantsIds.map(async (id) => {
            const user = await User.getFromId(id);
            const url = await user.getProfileImage();
            const name = user.children
                ? Object.values(user.children).join(", ")
                : "";
            return {
                name: name,
                imageUrl: url,
                userId: user.userId,
            };
        });
        const participants = await Promise.all(infoPromise);
        setParticipants(participants);
    }

    function onTeamClicked() {
        navigator.clipboard.writeText(team.teamId);
        setIdCopied(true);
    }

    return (
        <div className={styles.teamContainer}>
            <FaMinusCircle
                className={`icon ${styles.deleteTeam}`}
                onClick={() => onDeleteTeam(ind)}
            />

            <Tooltip title={idCopied ? "Copied!" : "Click to copy ID"}>
                <p
                    className="icon"
                    onClick={() => onTeamClicked()}
                >{`Team ${ind}`}</p>
            </Tooltip>
            <div className={styles.participantsContainer}>
                {!loading ? (
                    participants?.map((val) => (
                        <ParticipantElement user={val} />
                    ))
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    );
};

const ParticipantElement = (props: {
    user: { name: string; imageUrl?: string; userId: string };
}) => {
    const { user } = props;

    const history = useHistory();

    const goToUserDetails = () => {
        history.push(`/administration/users/${user.userId}`);
    };
    return (
        <div className={styles.participant} onClick={() => goToUserDetails()}>
            <img
                src={user.imageUrl}
                alt={`${user.name}`}
                className={styles.participantImage}
            />
            <p>{user.name}</p>
        </div>
    );
};
