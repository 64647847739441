import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { AdminGroupsList } from "./groupsList/AdminGroupsList";
import { AdminNewGroup } from "./newGroup/AdminNewGroup";

export const AdminGroups = () => {
    const match = useRouteMatch();
    return (
        <Switch>
            <Route path={`${match.path}/groups-list`}>
                <AdminGroupsList />
            </Route>
            <Route path={`${match.path}/new-group`}>
                <AdminNewGroup />
            </Route>
            <Route path={`${match.path}/edit-group/:groupId`}>
                <AdminNewGroup />
            </Route>
            <Route path={`${match.path}/`}>
                <Redirect to={`${match.path}/groups-list`} />
            </Route>
        </Switch>
    );
};
