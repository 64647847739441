import React from "react";
import { Link } from "react-router-dom";

interface PathProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLHeadingElement>,
        HTMLHeadingElement
    > {
    elements: { text: string; linkTo?: string }[];
}

/** Returns the classic navigation line with the desired elements. Every element has a text
 * and may have the link to which it should direct */
export const Path = (props: PathProps) => {
    return (
        <h1 style={props.style} className={props.className}>
            {props.elements.map((val, ind) => {
                let lastElement = ind < props.elements.length - 1;
                if (val.linkTo) {
                    return (
                        <span className="a-hover-blue" key={ind}>
                            <Link to={val.linkTo}>{val.text}</Link>
                            {lastElement ? " > " : ""}
                        </span>
                    );
                } else
                    return (
                        <span key={ind}>{`${val.text}${
                            lastElement ? " > " : ""
                        }`}</span>
                    );
            })}
        </h1>
    );
};
