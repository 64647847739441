import { Modal } from "@material-ui/core";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaPlusCircle, FaPencilAlt, FaGraduationCap } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { MdDragHandle } from "react-icons/md";
import { Prompt, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { ApiService } from "../../../../../apis/ApiService";
import { Project } from "../../../../../apis/Project";
import {
    AdminLevelSummary,
    PostAdminLevel,
} from "../../../../../apis/ProjectInterfaces";
import { Button } from "../../../../../components/ui/button/Button";
import { Card } from "../../../../../components/ui/card/Card";
import {
    SelectInput,
    SelectOptionProps,
} from "../../../../../components/ui/input/selectInput/SelectInput";
import { TextInput } from "../../../../../components/ui/input/textInput/TextInput";
import { Loading } from "../../../../../components/ui/loading/Loading";
import { Path } from "../../../../../components/ui/path/Path";
import { competences } from "../../../../../assets/strings/competences.json";

import styles from "./AdminProjectLevelsDashboard.module.css";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../../../../components/ui/snackbar/Snackbar";
import { codeToLan } from "../../../../../utils/functions";

let project: Project | undefined;

export const AdminProjectLevelsDashboard = () => {
    const [levels, setLevels] = useState<AdminLevelSummary[] | null>([]);
    const [changes, setChanges] = useState(false);
    const [loading, setLoading] = useState(true);
    const [savedOrder, setSavedOrder] = useState<AdminLevelSummary[]>([]);
    const [snackbar, setSnackbar] = useState<SnackbarProps>({ message: "" });
    const [saving, setSaving] = useState(false);
    const [modal, setModal] = useState(false);

    const history = useHistory();
    const { t, i18n } = useTranslation();
    let { projectId } = useParams<{ projectId: string }>();

    const language = codeToLan(i18n.language);
    // Gets the project information
    useEffect(() => {
        document.title = t("Levels Dashboard");

        ApiService.getProjectInformationFromId(projectId).then((res) => {
            if (res == null) {
                setLoading(false);
                return;
            }
            project = new Project(res);
            if (!project.levels) setLevels(null);
            else {
                let levelsArray: AdminLevelSummary[] = [];
                for (let level in project.levels) {
                    levelsArray.push(project.levels[level]);
                }
                levelsArray.sort((a, b) => a.number - b.number);
                setSavedOrder(Array.from(levelsArray));
                setLevels(Array.from(levelsArray));
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (changes) window.onbeforeunload = () => true;
        else window.onbeforeunload = () => null;
    }, [changes]);

    function onSaveHandler() {
        let formattedLevels: { [key: string]: AdminLevelSummary } = {};
        levels?.forEach((level, ind) => {
            formattedLevels[level.id] = level;
            formattedLevels[level.id].number = ind + 1;
        });
        setSaving(true);
        ApiService.postUpdateMultipleLevelSummaries(projectId, formattedLevels)
            .then(() => {
                setSnackbar({
                    message: t("Levels updated correctly"),
                    type: "success",
                });
                setTimeout(() => history.go(0), 3000);
                setChanges(false);
            })
            .catch(() =>
                setSnackbar({
                    message: t("Error updating levels"),
                    type: "error",
                })
            )
            .finally(() => setSaving(false));
    }

    function onDiscardHandler() {
        setLevels(Array.from(savedOrder));
        setChanges(false);
    }

    function changeSnackbar(snackbar: SnackbarProps) {
        setSnackbar(snackbar);
    }

    function openNewProjectCreation() {
        setModal(true);
    }

    function handleModalClose() {
        setModal(false);
    }

    if (loading) return <Loading />;
    if (!project) return <h1>{t("This project does not exist")}</h1>;
    if (!levels) return <h1>{t("There are no levels")}</h1>;

    return (
        <div className={`app-body-container-box`}>
            <PromptModalAndSnackbar
                defaultLanguage={project.languages.defaultLanguage}
                promptActivated={changes}
                snackbar={snackbar}
                changeSnackbar={changeSnackbar}
                modal={modal}
                onModalClose={handleModalClose}
            />
            <Header
                projectName={
                    project.projectName[
                        language ?? project.languages.defaultLanguage
                    ] ?? project.projectName[project.languages.defaultLanguage]
                }
                projectId={project.id!}
            />
            <Card className={styles.cardContainer}>
                <div className={styles.title}>
                    <h1>{t("Levels")}</h1>

                    <FaPlusCircle
                        onClick={openNewProjectCreation}
                        className={styles.plusButton}
                    />
                </div>
                {levels == null ? (
                    <h1>{t("Error")}</h1>
                ) : (
                    <DraggableList levels={levels} onChange={onDragChange} />
                )}
                {saving ? <Loading /> : undefined}
                {changes ? (
                    <SaveDiscardButtons
                        onSave={onSaveHandler}
                        onDiscard={onDiscardHandler}
                    />
                ) : null}
            </Card>
        </div>
    );

    function onDragChange(newLevels: AdminLevelSummary[]) {
        setLevels(newLevels);

        if (!arraysEqual(savedOrder, newLevels)) {
            setChanges(true);
        } else setChanges(false);
    }
};

const Header = (props: { projectName: string; projectId: string }) => {
    const { t } = useTranslation();

    return (
        <Path
            elements={[
                {
                    text: t("Administration"),
                    linkTo: "/administration",
                },
                {
                    text: t("Projects"),
                    linkTo: "/administration/projects/projects-list",
                },
                {
                    text: props.projectName,
                    linkTo: `/administration/projects/${props.projectId}`,
                },
                { text: t("Levels") },
            ]}
        />
    );
};

const DraggableList = (props: {
    onChange: Function;
    levels: AdminLevelSummary[];
}) => {
    const { onChange, levels } = props;

    function reorder(
        list: AdminLevelSummary[],
        startIndex: number,
        endIndex: number
    ) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        let newLevels = reorder(
            levels,
            result.source.index,
            result.destination.index
        );

        onChange(newLevels);
    }

    return (
        <div className={styles.draggableContainer}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="overflow-y"
                            style={{ height: "100%" }}
                        >
                            {levels.map((level, ind) => (
                                <Draggable
                                    key={ind}
                                    draggableId={ind.toString()}
                                    index={ind}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <LevelItem
                                                dragHandleProps={
                                                    provided.dragHandleProps
                                                }
                                                level={level}
                                                index={ind}
                                                defaultLanguage={
                                                    project!.languages
                                                        .defaultLanguage
                                                }
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

const LevelItem = (props: {
    level: AdminLevelSummary;
    dragHandleProps: DraggableProvidedDragHandleProps | undefined;
    index: number;
    defaultLanguage: string;
}) => {
    const history = useHistory();
    const match = useRouteMatch();
    const { t, i18n } = useTranslation();

    const language = codeToLan(i18n.language);

    function onEditClicked(levelId: string) {
        history.push(`${match.url}/${levelId}`);
    }

    function getLevelTitle() {
        return (
            props.level.title[language ?? props.defaultLanguage] ??
            props.level.title[props.defaultLanguage]
        );
    }
    return (
        <div className={`${styles.levelItemContainer} shadow`}>
            <div {...props.dragHandleProps} className={styles.handlerContainer}>
                <MdDragHandle className={styles.handlerIcon} />
            </div>

            <p>{`${props.index + 1}. ${getLevelTitle()}`}</p>
            <div className={styles.buttonsContainer}>
                <FaPencilAlt
                    onClick={() => onEditClicked(props.level.id)}
                    className={styles.icon}
                />
            </div>
        </div>
    );
};

const SaveDiscardButtons = (props: {
    onSave: Function;
    onDiscard: Function;
    style?: React.CSSProperties;
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.saveDiscardButtonsContainer} style={props.style}>
            <div className={styles.buttonContainer}>
                <Button
                    text={t("Discard")}
                    type="greyOutline"
                    onPress={props.onDiscard}
                />
            </div>
            <div className={styles.buttonContainer}>
                <Button text={t("Save")} onPress={props.onSave} />
            </div>
        </div>
    );
};

const PromptModalAndSnackbar = (props: {
    snackbar: SnackbarProps;
    promptActivated: boolean;
    changeSnackbar: (snackbar: SnackbarProps) => void;
    modal: boolean;
    onModalClose: Function;
    defaultLanguage: string;
}) => {
    const {
        snackbar,
        promptActivated,
        changeSnackbar,
        modal,
        onModalClose,
        defaultLanguage,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <NewLevelModal
                modal={modal}
                onModalClose={onModalClose}
                defaultLanguage={defaultLanguage}
                changeSnackbar={changeSnackbar}
            />
            <Prompt
                message={t(
                    "You have unsaved changes. Are you sure you want to leave?"
                )}
                when={promptActivated}
            />
            <CustomSnackbar
                snackbar={snackbar}
                autoHideDuration={3000}
                handleSnackbarClose={changeSnackbar}
            />
        </>
    );
};

const NewLevelModal = (props: {
    modal: boolean;
    onModalClose: Function;
    defaultLanguage: string;
    changeSnackbar: Function;
}) => {
    const { modal, onModalClose, defaultLanguage, changeSnackbar } = props;
    const methods = useForm();
    const { t } = useTranslation();
    const { projectId } = useParams<{ projectId: string }>();
    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState("");

    async function onSave() {
        const valid = await methods.trigger();

        if (!valid) return;

        let formResults = methods.watch();
        let level: PostAdminLevel = {
            title: {
                [defaultLanguage]: formResults.levelName,
            },
            competences: formResults.competences,
            instructorInfo: formResults.instructorLink,
        };

        setUploading(true);
        setError("");

        ApiService.postNewLevel(projectId, level)
            .then(() => {
                changeSnackbar({
                    message: t("Level created successfully"),
                    type: "success",
                });
                setTimeout(() => history.go(0), 3000);
            })
            .catch(() => {
                changeSnackbar({
                    message: t(
                        "There is a problem reaching the server. Please try again later"
                    ),
                    type: "error",
                });
                setError(
                    t(
                        "There is a problem reaching the server. Please try again later"
                    )
                );
            })
            .finally(() => setUploading(false));
    }

    function getSelectOptions(options: string[]): SelectOptionProps[] {
        return options.map((val) => {
            return {
                display: t(val),
                value: val,
            };
        });
    }

    return (
        <Modal open={modal} className={styles.newProjectModal}>
            <Card
                className={styles.modalBodyContainer}
                closeHandle={onModalClose}
            >
                <h1>{t("New level creation")}</h1>
                <FormProvider {...methods}>
                    <form>
                        <div className={styles.formContainer}>
                            <div className={styles.halfContainer}>
                                <label className={styles.mediumSizeFont}>
                                    {`${t("Level name (in")} ${t(
                                        defaultLanguage
                                    )})`}
                                </label>
                                <div className={styles.inputContainer}>
                                    <TextInput name="levelName" mandatory />
                                </div>
                                <label className={styles.mediumSizeFont}>
                                    {t("Type of competences worked")}
                                </label>
                                <div className={styles.inputContainer}>
                                    <SelectInput
                                        name="competences"
                                        options={getSelectOptions(
                                            competences.sort()
                                        )}
                                        multiple
                                        chips
                                        mandatory
                                    />
                                </div>
                            </div>
                            <div className={styles.halfContainer}>
                                <label className={styles.mediumSizeFont}>
                                    {t("Instructor information (link)")}
                                </label>
                                <div className={styles.inputContainer}>
                                    <TextInput name="instructorLink" />
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
                {uploading ? (
                    <Loading message={t("Saving the new level...")} />
                ) : undefined}
                {error ? <p className="error">{error}</p> : undefined}
                <SaveDiscardButtons
                    onDiscard={onModalClose}
                    onSave={onSave}
                    style={{ marginLeft: "auto" }}
                />
            </Card>
        </Modal>
    );
};

function arraysEqual(a: any[], b: any[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}
