import React from "react";
import loadingSvg from "../../../assets/logo/loading.svg";
import styles from "./Loading.module.css";

interface LoadingProps {
    /** Will return the loading with all the background with a high opacity grey */
    fullScreenLoading?: boolean;
    /** The message that should be shown below the loading image. None by default */
    message?: string;
}

/** Returns the image of a friendly robot blinking  */
export const Loading = (props: LoadingProps) => {
    const { fullScreenLoading, message } = props;

    return (
        <div
            className={`loading ${fullScreenLoading ? styles.fullScreen : ""}`}
        >
            <object
                type="image/svg+xml"
                data={loadingSvg}
                width={100}
                style={{ marginBottom: 10 }}
            >
                Loading...
            </object>
            {message ? <p>{message}</p> : undefined}
        </div>
    );
};
