import { TFunction } from "react-i18next";
import { functionsApp } from "../firebase";
import { ApiService } from "./ApiService";
import { ParticipantsTeamSummary } from "./ParticipantsTeam";
import { MultiLanguage } from "./ProjectInterfaces";

export interface GroupInformation {
    groupId: string;
    schedule: ClassDay[];
    startDate: number;
    finishDate: number;
    name: string;
    projectId: string;
    videoCallRoom?: string;
    instructorIds: string[];
    teamsSummaries?: ParticipantsTeamSummary[];
    unlockedLevels?: {
        id: string;
        unlocked: boolean;
    }[];
    language: string;
    maxNumChildren: number;
    open: boolean;
    visible: boolean;
}

export interface ClassDay {
    weekDay?: WeekDay;
    startHour?: string;
    finishHour?: string;
}

export type WeekDay =
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";

export interface GroupSummary {
    groupId: string;
    projectId: string;
    groupName: string;
    projectName: MultiLanguage<string>;
    numParticipants: number;
    schedule: ClassDay[];
}

export class Group implements GroupInformation {
    groupId: string;
    schedule: ClassDay[];
    startDate: number;
    finishDate: number;
    name: string;
    projectId: string;
    videoCallRoom?: string | undefined;
    instructorIds: string[];
    unlockedLevels?: {
        id: string;
        unlocked: boolean;
    }[];
    language: string;
    maxNumChildren: number;
    open: boolean;
    visible: boolean;
    teamsSummaries?: ParticipantsTeamSummary[];

    constructor(data: GroupInformation) {
        this.groupId = data.groupId;
        this.schedule = data.schedule;
        this.startDate = data.startDate;
        this.finishDate = data.finishDate;
        this.name = data.name;
        this.projectId = data.projectId;
        this.videoCallRoom = data.videoCallRoom;
        this.instructorIds = data.instructorIds;
        this.unlockedLevels = data.unlockedLevels;
        this.language = data.language;
        this.maxNumChildren = data.maxNumChildren;
        this.open = data.open;
        this.visible = data.visible;
        this.teamsSummaries = data.teamsSummaries;
    }

    static async getFromId(id: string) {
        const response = await functionsApp.httpsCallable("groups-getFromId")({
            groupId: id,
        });
        const data = response.data as GroupInformation;

        return new Group(data);
    }

    getGroupInformation(): GroupInformation {
        return {
            groupId: this.groupId,
            schedule: this.schedule,
            startDate: this.startDate,
            finishDate: this.finishDate,
            name: this.name,
            projectId: this.projectId,
            videoCallRoom: this.videoCallRoom,
            instructorIds: this.instructorIds,
            unlockedLevels: this.unlockedLevels,
            language: this.language,
            maxNumChildren: this.maxNumChildren,
            open: this.open,
            visible: this.visible,
            teamsSummaries: this.teamsSummaries,
        };
    }

    static async postGroup(
        newGroup: GroupInformation
    ): Promise<GroupInformation> {
        const response = await functionsApp.httpsCallable("groups-postGroup")({
            group: newGroup,
        });
        return response.data as GroupInformation;
    }

    static async postLevels(
        groupId: string,
        levels: {
            id: string;
            unlocked: boolean;
        }[]
    ) {
        const response = await functionsApp.httpsCallable("groups-postLevels")({
            groupId: groupId,
            levels: levels,
        });
        return response.data;
    }

    static async getGroupsFromInstructor(
        instructorId: string
    ): Promise<Group[]> {
        const groupsFromInstructor = (
            await functionsApp.httpsCallable("groups-getGroupsFromInstructor")({
                instructorId: instructorId,
            })
        ).data as GroupInformation[];
        return groupsFromInstructor.map((group) => new Group(group));
    }

    async getGroupSummary(): Promise<GroupSummary> {
        const project = await ApiService.getProjectInformationFromId(
            this.projectId
        );
        return {
            groupId: this.groupId,
            projectId: this.projectId,
            numParticipants: this.teamsSummaries?.length ?? 0,
            groupName: this.name,
            projectName: project?.projectName ?? { catalan: "" },
            schedule: this.schedule,
        };
    }

    static scheduleToText(schedule: ClassDay[], t: TFunction<string>) {
        return schedule
            .map(
                (day) =>
                    `${
                        t(day.weekDay!).charAt(0).toUpperCase() +
                        t(day.weekDay!).slice(1)
                    } ${t("from")} ${day.startHour} ${t("to")} ${
                        day.finishHour
                    }`
            )
            .join(` ${t("and")} `);
    }

    static async getAllGroups(): Promise<GroupInformation[]> {
        const response = await functionsApp.httpsCallable(
            "groups-getAllGroups"
        )({});
        return response.data as GroupInformation[];
    }

    static getDataTableFormat(
        groupInfo: GroupInformation,
        t: TFunction<string>
    ): GroupDataTable {
        return {
            id: groupInfo.groupId,
            groupName: groupInfo.name,
            schedule: Group.scheduleToText(groupInfo.schedule, t),
            instructorId: groupInfo.instructorIds.join(", "),
            projectId: groupInfo.projectId,
        };
    }
}

export interface GroupDataTable {
    id: string;
    groupName: string;
    schedule: string;
    instructorId: string;
    projectId: string;
}
