import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation, TFunction } from "react-i18next";
import { FaCloudDownloadAlt, FaLock, FaStar, FaUnlock } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { ApiService } from "../../../apis/ApiService";
import { GroupInformation, ClassDay, Group } from "../../../apis/Group";
import {
    Feedback,
    ParticipantsTeam,
    ParticipantsTeamInformation,
} from "../../../apis/ParticipantsTeam";
import { ProjectInformation } from "../../../apis/Project";
import { Card } from "../../../components/ui/card/Card";
import { Loading } from "../../../components/ui/loading/Loading";
import { Path } from "../../../components/ui/path/Path";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../../components/ui/snackbar/Snackbar";
import { blue, mediumGrey, red } from "../../../utils/Colors";

import styles from "./ClassProgress.module.css";
import { FeedbackModal } from "./FeedbackModal";

export const ClassProgress = () => {
    const { t } = useTranslation();
    const { classId } = useParams<{ classId: string }>();

    const [loading, setLoading] = useState(true);
    const [fatalError, setFatalError] = useState("");
    const [group, setGroup] = useState<GroupInformation>();
    const [teams, setTeams] = useState<ParticipantsTeamInformation[]>();
    const [project, setProject] = useState<ProjectInformation>();

    useEffect(() => {
        document.title = t("Class progress");

        getInformation(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onGroupChange = (newGroup: GroupInformation) => {
        setGroup(newGroup);
    };

    const onTeamChange = (team: ParticipantsTeamInformation) => {
        setTeams((teams) => {
            const index = teams!.findIndex((t) => t.teamId === team.teamId);
            let copiedArray = [...teams!];
            copiedArray[index] = team;
            return copiedArray;
        });
    };

    async function getInformation() {
        try {
            const group = await getGroup();
            await Promise.all([
                getTeamsProgression(group),
                getProject(group.projectId),
            ]);
        } catch {
            setFatalError(
                t(
                    "This group does not exist or there was a problem reaching it"
                )
            );
        } finally {
            setLoading(false);
        }
    }
    async function getGroup() {
        const group = await Group.getFromId(classId);
        setGroup(group);
        return group;
    }

    async function getTeamsProgression(group: Group) {
        const teamsIds = group.teamsSummaries?.map((team) => team.teamId);
        if (!teamsIds || teamsIds.length === 0) {
            setFatalError("We have not found any team");
            return;
        }
        const teams = await ParticipantsTeam.getMultipleTeamsInformationFromId(
            teamsIds,
            true,
            true
        );
        setTeams(teams);
    }

    async function getProject(projectId: string) {
        const project = await ApiService.getProjectInformationFromId(projectId);
        setProject(project);
    }
    if (loading) return <Loading />;
    if (fatalError) return <h1>{fatalError}</h1>;
    return (
        <div className={`app-body-container-box ${styles.container}`}>
            <div className={styles.headerContainer}>
                <Header
                    t={t}
                    groupName={group!.name}
                    schedule={group!.schedule}
                    classId={classId}
                />
            </div>
            <ChildrenTable
                group={group!}
                teams={teams!}
                project={project!}
                onGroupChange={onGroupChange}
                onTeamChange={onTeamChange}
            />
        </div>
    );
};

const Header = (props: {
    t: TFunction<string>;
    groupName: string;
    schedule: ClassDay[];
    classId: string;
}) => {
    const { t, groupName, schedule, classId } = props;

    return (
        <div className={styles.header}>
            <Path
                elements={[
                    { text: t("Classes"), linkTo: "/classes/" },
                    {
                        text: groupName,
                        linkTo: `classes/class-details/${classId}`,
                    },
                    { text: t("Class Progress") },
                ]}
            />
            <p className={styles.smallText}>
                {Group.scheduleToText(schedule, t)}
            </p>
        </div>
    );
};

const ChildrenTable = (props: {
    teams: ParticipantsTeamInformation[];
    group: GroupInformation;
    project: ProjectInformation;
    onGroupChange: (group: GroupInformation) => void;
    onTeamChange: (team: ParticipantsTeamInformation) => void;
}) => {
    const [uploading, setUploading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackbarProps>({ message: "" });
    const { t } = useTranslation();

    const onLockChange = async (levelId: string, unlocked: boolean) => {
        const copiedGroup = { ...props.group };
        if (!copiedGroup.unlockedLevels) copiedGroup.unlockedLevels = [];
        const index = copiedGroup.unlockedLevels.findIndex(
            (lev) => lev.id === levelId
        );
        const newLevelProgress = { id: levelId, unlocked: unlocked };
        if (index !== -1) copiedGroup.unlockedLevels[index] = newLevelProgress;
        else copiedGroup.unlockedLevels.push(newLevelProgress);
        try {
            setUploading(true);
            await Group.postLevels(
                copiedGroup.groupId,
                copiedGroup.unlockedLevels
            );
            props.onGroupChange(copiedGroup);
        } catch {
            setSnackbar({
                message: "There was an error updating the levels",
                type: "error",
            });
        } finally {
            setUploading(false);
        }
    };

    return (
        <Card>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} />
                            {props.project.levels
                                ? Object.values(props.project.levels)
                                      .sort((a, b) => a.number - b.number)
                                      .map((level) => (
                                          <TableCell
                                              className={`${styles.centerText} ${styles.headerText} ${styles.tableHeader}`}
                                              colSpan={2}
                                          >
                                              {`${t("Level")} ${level.number}`}
                                              {props.group.unlockedLevels?.findIndex(
                                                  (lev) =>
                                                      lev.id === level.id &&
                                                      lev.unlocked === true
                                              ) !== -1 ? (
                                                  <FaUnlock
                                                      className={styles.lock}
                                                      onClick={() =>
                                                          onLockChange(
                                                              level.id,
                                                              false
                                                          )
                                                      }
                                                  />
                                              ) : (
                                                  <FaLock
                                                      className={styles.lock}
                                                      onClick={() =>
                                                          onLockChange(
                                                              level.id,
                                                              true
                                                          )
                                                      }
                                                  />
                                              )}
                                          </TableCell>
                                      ))
                                : undefined}
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("Team Id")}</TableCell>
                            <TableCell>{t("Name and surnames")}</TableCell>

                            {Object.getOwnPropertyNames(
                                props.project.levels
                            ).map(() => (
                                <>
                                    <TableCell className={styles.centerText}>
                                        {t("Submission")}
                                    </TableCell>
                                    <TableCell className={styles.centerText}>
                                        {t("Feedback")}
                                    </TableCell>
                                </>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.teams.map((team) => {
                            return (
                                <TableRow>
                                    <TableCell className={styles.idCell}>
                                        {team.teamId}
                                    </TableCell>
                                    <TableCell>
                                        {team.participantsNames?.join(" i ")}
                                    </TableCell>
                                    {[
                                        ...Array(
                                            Object.values(props.project.levels!)
                                                .length
                                        ),
                                    ].map((_, i) => (
                                        <LevelCells
                                            team={team}
                                            levelNumber={i + 1}
                                            project={props.project}
                                            key={i}
                                        />
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {uploading ? <Loading fullScreenLoading /> : undefined}
            <CustomSnackbar
                handleSnackbarClose={(snackbar) => setSnackbar(snackbar)}
                snackbar={snackbar}
            />
        </Card>
    );
};

const LevelCells = (props: {
    team: ParticipantsTeamInformation;
    levelNumber: number;
    project: ProjectInformation;
}) => {
    const [feedback, setFeedback] = useState<{
        visible: boolean;
        feedback?: Feedback;
    }>({ visible: false });
    const { t } = useTranslation();

    const levelId = Object.values(props.project.levels!).find(
        (lev) => lev.number === props.levelNumber
    )?.id;
    const progress = props.team.progress?.find(
        (progr) => progr.levelId === levelId
    );

    function downloadFile(url: string) {
        fetch(url).then((res) => {
            res.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = "submission";
                a.click();
            });
        });
    }

    return (
        <>
            <TableCell className={styles.centerText}>
                {progress?.resultSent?.attachmentUrl ? (
                    <FaCloudDownloadAlt
                        size={"1.5rem"}
                        onClick={() =>
                            downloadFile(progress.resultSent!.attachmentUrl!)
                        }
                        className={styles.downloadButton}
                    />
                ) : (
                    <p>{t("Pending")}</p>
                )}
            </TableCell>
            <TableCell className={`${styles.centerText} ${styles.center}`}>
                {progress?.feedback ? (
                    <Stars
                        filled={progress?.feedback?.stars}
                        onClick={() =>
                            setFeedback({
                                visible: true,
                                feedback: progress.feedback,
                            })
                        }
                    />
                ) : progress?.resultSent ? (
                    <p
                        onClick={() => setFeedback({ visible: true })}
                        className={styles.feedbackButton}
                    >
                        {t("Give feedback")}
                    </p>
                ) : undefined}
            </TableCell>
            {feedback.visible ? (
                <FeedbackModal
                    visible={feedback.visible}
                    onClose={() => setFeedback({ visible: false })}
                    levelId={levelId ?? ""}
                    teamId={props.team.teamId}
                    feedback={feedback.feedback}
                />
            ) : undefined}
        </>
    );
};

const Stars = (props: { filled?: number; onClick: () => void }) => {
    return (
        <div className={styles.starsContainer} onClick={props.onClick}>
            {[...new Array(3)].map((_, i) => (
                <Star colored={(props.filled && i < props.filled) as boolean} />
            ))}
        </div>
    );
};

const Star = (props: { colored?: boolean }) => {
    return <FaStar color={props.colored ? blue : mediumGrey} />;
};
