export const lightGrey = "#f5f5f5";
export const mediumGrey = "#707070";
export const darkGrey = "#5a5a5a";
export const veryDarkGrey = "#434343";
export const red = "#d26060";
export const darkRed = "#994848";
export const white = "#ffffff";
export const blue = "#3066a4";
export const darkBlue = "#204570";
export const mediumBlue = "#7eb3f1";
export const lightBlue = "#c2dbf8";
export const veryLightBlue = "#eaf4ff";
export const blueHighOpacity = "rgba(48, 102, 164, 0.86)";
export const transparentGrey = "rgba(90, 90, 90, 0.09)";
export const yellow = "#f7b801";
export const highOpacityGrey = "rgba(90, 90, 90, 0.8)";
