import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SuccessPage } from "../../../../components/ui/success/SuccessPage";

export const TempUser = () => {
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("Edició d'usuaris");
    }, []);
    return (
        <SuccessPage
            buttons={[{ text: t("Go back"), onPress: () => history.goBack() }]}
            message={t(
                "Users creation and edition will be available very soon!"
            )}
        />
    );
};
