import { TFunction } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { WeekDay } from "../apis/Group";
import { SelectOptionProps } from "../components/ui/input/selectInput/SelectInput";

export async function sleep(time: number): Promise<true> {
    return new Promise((resolve) => {
        setTimeout(() => resolve(true), time);
    });
}

export function checkObjectEquality(data1: any, data2: any) {
    if (!data1 || !data2) return false;
    const keys1 = Object.keys(data1);
    const keys2 = Object.keys(data2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = data1[key];
        const val2 = data2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !checkObjectEquality(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object: any) {
    return object != null && typeof object === "object";
}

/** Creates a promise that resolves with the desired result
 * @param result The desired result to resolve
 * @param timer The time it takes to resolve. The default value is 2000
 */
export function temporaryAsyncMaker(result: any, timer: number = 2000) {
    return new Promise<any>((resolve) => {
        setTimeout(() => {
            resolve(result);
        }, timer);
    });
}

/** Returns an element of the provided array randomly */
export function chooseAmongOptionsRandomly(array: Array<any>) {
    return array[Math.floor(Math.random() * array.length)];
}

/** Returns the base64 string of the provided file */
export async function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) resolve(reader.result as string);
        };
        reader.onerror = (error) => reject(error);
    });
}

/** Given an array and a default value, it returns an object with the array values as object keys
 * and the default value as its value
 * @param array The array that must be converted to object keys
 * @param defaultValue The value that all the properties of the object will have.
 * @example ```arrayToObjectKeysWithDefaultValue(['a','b','c'], null)``` will return
 * ```{a: null, b: null, c: null} ``` */
export function arrayToObjectKeysWithDefaultValue(
    array: string[],
    defaultValue: any
) {
    let object: { [key: string]: typeof defaultValue } = {};

    array.forEach((key) => {
        object[key] = defaultValue;
    });

    return object;
}

/** Given a birth date in a string with format (YYYY/MM/DD), returns the age today */
export function getAge(date: string | number) {
    var today = new Date(Date.now());
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function getUuidv4() {
    return uuidv4();
}

export function deleteAndAttachUrl(
    url: string,
    upToStr: string,
    attStr: string
) {
    const splitUrl = url.split("/");
    splitUrl.length = splitUrl.indexOf(upToStr) + 1;
    let newUrl = splitUrl.join("/").concat(attStr);
    return newUrl;
}

export function toTextFieldDateString(date: number | Date) {
    let dateStr =
        typeof date === "number"
            ? new Date(date).toISOString()
            : date.toISOString();

    dateStr = dateStr.substr(0, 10);
    return dateStr;
}

/** Returns a SelectOptionProps created from the available languages in the assets
 * TODO: Should probably display the name of the language in the desired language
 * passed on the parameters  */
export function getLanguageOptions(t?: TFunction<string>): SelectOptionProps[] {
    const {
        availableLanguages,
    } = require("../assets/strings/translations/availableLanguages.json");

    const options: SelectOptionProps[] = (availableLanguages as string[]).map(
        (lan) => {
            return { display: t ? t(lan) : lan, value: lan };
        }
    );
    return options;
}

export function dayToWeekDay(number: number) {
    switch (number) {
        case 0:
            return "sunday";
        case 1:
            return "monday";
        case 2:
            return "tuesday";
        case 3:
            return "wednesday";
        case 4:
            return "thursday";
        case 5:
            return "friday";
        case 6:
            return "saturday";
        default:
            return "sunday";
    }
}

export function weekDayToDay(string: WeekDay) {
    switch (string) {
        case "sunday":
            return 0;
        case "monday":
            return 1;
        case "tuesday":
            return 2;
        case "wednesday":
            return 3;
        case "thursday":
            return 4;
        case "friday":
            return 5;
        case "saturday":
            return 6;
        default:
            return 0;
    }
}

export function codeToLan(code: string) {
    switch (code) {
        case "esp":
            return "spanish";
        case "cat":
            return "catalan";
        case "eng":
            return "english";
        default:
            return undefined;
    }
}
