import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
    PostLevelStep,
    PostSimpleVideoData,
} from "../../../../../../../apis/ProjectInterfaces";
import {
    SelectInput,
    SelectOptionProps,
} from "../../../../../../../components/ui/input/selectInput/SelectInput";
import { TextInput } from "../../../../../../../components/ui/input/textInput/TextInput";
import { checkObjectEquality } from "../../../../../../../utils/functions";
import { LoadingSaveInputDiscardButton } from "../../StepModal";

import styles from "./SimpleVideoCreator.module.css";

export const SimpleVideoCreator = (props: {
    step: PostLevelStep;
    possibleLanguages: string[];
    onSend: (newStep: PostLevelStep) => Promise<boolean>;
    onChanges: (newChanges: boolean) => void;
}) => {
    const { possibleLanguages, onSend, onChanges } = props;
    let { step } = props;
    const [loading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [changes, setChanges] = useState(false);

    const { t } = useTranslation();

    const form = useForm({ mode: "onBlur" });
    const [data, setData] = useState<PostSimpleVideoData>();

    const onDiscard = () => {
        form.reset();
        if (changes) {
            setChanges(false);
            onChanges(false);
        }
    };
    const onSubmit = form.handleSubmit(onSave);

    function checkChanges() {
        formatVideoCreatorResults(form.watch()).then((results: any) => {
            if (
                !checkObjectEquality(step.data, results) &&
                results !== undefined &&
                !changes
            ) {
                setChanges(true);
                onChanges(true);
            } else if (checkObjectEquality(step.data, results) && changes) {
                setChanges(false);
                onChanges(false);
            }
        });
    }

    function onDeleteClick(index: number) {
        let newData: PostSimpleVideoData = { videos: [...data!.videos] };
        newData.videos.splice(index, 1);
        setData(newData);
    }

    function onAddClick() {
        let newData: PostSimpleVideoData = {
            videos: data ? [...data.videos] : [],
        };
        newData.videos.push({ url: "", language: "", subtitles: "" });
        setData(newData);
    }

    async function onSave(data: any) {
        if (uploaded || uploading) return;
        setUploading(true);
        let newData = await formatVideoCreatorResults(data);
        let newStep: PostLevelStep = {
            type: step.type,
            data: newData as any,
            id: step.id,
        };
        const success = await onSend(newStep);
        if (success) setUploaded(true);
        setUploading(false);
    }

    useEffect(() => {
        if (step.type !== "SimpleVideo") return;
        setData({ videos: [...step.data.videos] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    checkChanges();

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit}>
                <div className={styles.header}>
                    <h2>{t("Videos")}</h2>
                    {
                        <IconContext.Provider
                            value={{
                                className: `${styles.addIcon} ${styles.icon}`,
                            }}
                        >
                            <FaPlusCircle onClick={() => onAddClick()} />
                        </IconContext.Provider>
                    }
                </div>
                {data?.videos.map((video, ind) => (
                    <VideoInput
                        url={video.url}
                        language={video.language}
                        subtitles={video.subtitles}
                        index={ind}
                        onDeleteClick={onDeleteClick}
                        possibleLanguages={possibleLanguages}
                    />
                ))}
                <LoadingSaveInputDiscardButton
                    onDiscard={onDiscard}
                    loadingVisible={uploading}
                    saveVisible={changes}
                />
            </form>
        </FormProvider>
    );
};

const VideoInput = (props: {
    url?: string;
    language?: string;
    subtitles?: string;
    index: number;
    onDeleteClick: Function;
    possibleLanguages: string[];
}) => {
    const {
        url,
        language,
        subtitles,
        index,
        onDeleteClick,
        possibleLanguages,
    } = props;
    const subtitleOptions = Array.from(possibleLanguages);
    subtitleOptions.push("");
    const { t } = useTranslation();

    function getSelectOptions(options: string[]): SelectOptionProps[] {
        return options.map((val) => {
            return {
                display: t(val),
                value: val,
            };
        });
    }

    return (
        <div className={styles.videoInputContainer}>
            <IconContext.Provider
                value={{
                    className: `${styles.icon} ${styles.deleteIcon}`,
                }}
            >
                <FaMinusCircle onClick={() => onDeleteClick(index)} />
            </IconContext.Provider>
            <div className={styles.videoInput}>
                <label className={`medium ${styles.inputLabel}`}>
                    {t("URL")}
                </label>

                <TextInput
                    name={"url" + index}
                    defaultValue={url}
                    mandatory
                    key={index}
                    placeholder="https://www.learnick.com/..."
                />
            </div>
            <div className={styles.videoInput}>
                <label className={`medium ${styles.inputLabel}`}>
                    {t("Language")}
                </label>
                <SelectInput
                    name={"language" + index}
                    options={getSelectOptions(possibleLanguages)}
                    defaultValue={language}
                    mandatory
                />
            </div>
            <div className={styles.videoInput}>
                <label className={`medium ${styles.inputLabel}`}>
                    {t("Subtitles")}
                </label>
                <SelectInput
                    name={"subtitles" + index}
                    options={getSelectOptions(subtitleOptions)}
                    defaultValue={subtitles}
                />
            </div>
        </div>
    );
};

async function formatVideoCreatorResults(results: { [key: string]: any }) {
    let currentStep: PostSimpleVideoData = { videos: [] };

    if (JSON.stringify(results) === "{}") return;

    Object.getOwnPropertyNames(results).forEach((val: string) => {
        let type = val.slice(0, -1);
        let num = val.slice(-1);

        if (
            type === "url" &&
            !currentStep.videos.find(
                (video) => video.language === results["language" + num]
            )
        ) {
            currentStep.videos.push({
                language: results["language" + num],
                subtitles: results["subtitles" + num],
                url: results["url" + num],
            });
        }
    });

    return currentStep;
}
