import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Link,
    Route,
    Switch,
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import { HeaderCard } from "../../components/ui/card/Card";
import { Path } from "../../components/ui/path/Path";
import { SuccessPage } from "../../components/ui/success/SuccessPage";
import styles from "./Administration.module.css";
import { AdminGroups } from "./groups/AdminGroups";
import { AdminProjects } from "./projects/AdminProjects";
import { AdminProjectsDistribution } from "./projectsDistribution/ProjectsDistribution";
import { Users } from "./users/Users";

export const Administration = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Switch>
            <Route path={`${match.path}/users`}>
                <Users />
            </Route>

            <Route path={`${match.path}/groups`}>
                <AdminGroups />
            </Route>

            <Route path={`${match.path}/projects`}>
                <AdminProjects />
            </Route>

            <Route path={`${match.path}/distributions`}>
                <AdminProjectsDistribution />
            </Route>

            <Route path={`${match.path}/avatars`}>
                <SuccessPage
                    buttons={[
                        {
                            text: t("Go back"),
                            onPress: () => history.goBack(),
                        },
                    ]}
                    message={t("Avatars will be available very soon")}
                />
            </Route>

            <Route path={`${match.path}`}>
                <Dashboard />
            </Route>
        </Switch>
    );

    function Dashboard() {
        const { t } = useTranslation();
        useEffect(() => {
            document.title = t("Admin Dashboard");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <div className={`app-body-container-box`}>
                <Path elements={[{ text: t("Administration") }]} />{" "}
                <div className={styles.container}>
                    <div className={styles.leftCardContainers}>
                        <div className={styles.cardContainer}>
                            <HeaderCard header={t("Users")} textPadding>
                                <nav>
                                    <Link
                                        to={`/administration/users/users-list`}
                                    >
                                        <p>{t("See the users list")}</p>
                                    </Link>
                                    <Link to={`${match.url}/users/new-user`}>
                                        <p>{t("Create a new user")}</p>
                                    </Link>
                                </nav>
                            </HeaderCard>
                        </div>
                        <div className={styles.cardContainer}>
                            <HeaderCard header={t("Groups")} textPadding>
                                <nav>
                                    <Link
                                        to={`${match.url}/groups/groups-list`}
                                    >
                                        <p>{t("View the groups list")}</p>
                                    </Link>
                                    <Link to={`${match.url}/groups/new-group`}>
                                        <p>{t("Create a new group")}</p>
                                    </Link>
                                </nav>
                            </HeaderCard>
                        </div>
                        <div className={styles.cardContainer}>
                            <HeaderCard header={t("Avatars")} textPadding>
                                <nav>
                                    <Link
                                        to={`${match.url}/avatars/avatars-list`}
                                    >
                                        <p>{t("See the avatars list")}</p>
                                    </Link>
                                    <Link
                                        to={`${match.url}/avatars/new-avatar`}
                                    >
                                        <p>{t("Create a new avatar")}</p>
                                    </Link>
                                </nav>
                            </HeaderCard>
                        </div>
                    </div>
                    <div className={styles.rightCardContainers}>
                        <div className={styles.cardContainer}>
                            <HeaderCard header={t("Projects")} textPadding>
                                <nav>
                                    <Link
                                        to={`${match.url}/projects/projects-list`}
                                    >
                                        <p>{t("See the list of projects")}</p>
                                    </Link>
                                    <Link
                                        to={`${match.url}/projects/new-project`}
                                    >
                                        <p>{t("Create a new project")}</p>
                                    </Link>
                                </nav>
                            </HeaderCard>
                        </div>
                        <div className={styles.cardContainer}>
                            <HeaderCard
                                header={t("Projects Distribution")}
                                textPadding
                            >
                                <nav>
                                    <Link
                                        to={`${match.url}/distributions/distributions-list`}
                                    >
                                        <p>{t("See all distributions")}</p>
                                    </Link>
                                    <Link
                                        to={`${match.url}/distributions/new-distribution`}
                                    >
                                        <p>{t("Create a new distribution")}</p>
                                    </Link>
                                </nav>
                            </HeaderCard>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
