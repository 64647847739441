import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Modal,
    ModalProps,
    Select,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { IconContext } from "react-icons/lib";
import { useHistory, useParams } from "react-router-dom";
import { ApiService } from "../../../../../apis/ApiService";
import { ProjectInformation } from "../../../../../apis/Project";
import {
    PostFileUploadData,
    PostLevelStep,
    PostSimpleInstructionsData,
    PostSimpleTextData,
    PostSimpleVideoData,
} from "../../../../../apis/ProjectInterfaces";
import { Button } from "../../../../../components/ui/button/Button";
import { Card } from "../../../../../components/ui/card/Card";
import { SimpleInstructionsCreator } from "./stepLevelComponents/simpleInstructionsCreator/SimpleInstructionsCreator";
import { SimpleTextCreator } from "./stepLevelComponents/simpleTextCreator/SimpleTextCreator";
import { SimpleVideoCreator } from "./stepLevelComponents/simpleVideoCreator/SimpleVideoCreator";
import { v4 as uuidv4 } from "uuid";

import styles from "./StepModal.module.css";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../../../../components/ui/snackbar/Snackbar";
import { Loading } from "../../../../../components/ui/loading/Loading";
import { FileUploadCreator } from "./stepLevelComponents/fileUploadCreator/FileUploadCreator";

interface StepModalProps extends Omit<ModalProps, "children"> {
    step?: PostLevelStep;
    projectInformation: ProjectInformation;
}

export const StepModal: React.ComponentType<StepModalProps> = (props) => {
    const { open, onClose, projectInformation } = props;

    const { projectId, levelId } =
        useParams<{
            projectId: string;
            levelId: string;
        }>();
    const history = useHistory();
    const { t } = useTranslation();

    const [warning, setWarning] = useState(false);
    const [snackbar, setSnackbar] = useState<{
        message: string;
        type?: "info" | "success" | "warning" | "error";
    }>({ message: "" });
    const [changes, setChanges] = useState(false);
    const [error, setError] = useState("");
    const [step, setStep] = useState(props.step);

    function handleModalClose(ev: {}) {
        if (!onClose) return;
        if (changes) setWarning(true);
        else onClose(ev, "backdropClick");
    }

    function handleChanges(newChanges: boolean) {
        if (changes !== newChanges) setChanges(newChanges);
    }

    function handleDialogClose(accepted: boolean, ev: {}) {
        setWarning(false);
        if (!accepted) return;
        if (onClose) onClose(ev, "backdropClick");
    }

    function handleSnackbarClose(snackbar: SnackbarProps) {
        setSnackbar(snackbar);
    }

    function handleTypeChange(
        ev: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) {
        let type = ev.target.value as any;
        let data = returnStepData(type);
        setStep({ id: step?.id ?? uuidv4(), type: type, data: data as any });
    }

    async function onSend(newStep: PostLevelStep): Promise<boolean> {
        return ApiService.postLevelStep(projectId, levelId, newStep)
            .then(() => {
                setSnackbar({ message: t("Step updated successfully") });
                setTimeout(() => history.go(0), 2000);
                return true;
            })
            .catch(() => {
                setSnackbar({
                    message: t("Error updating the step"),
                    type: "error",
                });
                setError(t("Error reaching our end. Please try again later"));
                return false;
            });
    }

    return (
        <Modal open={open} onClose={onClose} className={styles.modal}>
            <Card className={`${styles.cardContainer}`}>
                <DialogsAndSnackbars
                    handleDialogClose={handleDialogClose}
                    snackbar={snackbar}
                    warning={warning}
                    handleSnackbarClose={handleSnackbarClose}
                />
                <div className={styles.header}>
                    <h1>{t(props.step?.type ?? "Step Creation")}</h1>
                    <div className={styles.headerButtonsContainer}>
                        <IoClose
                            onClick={(ev: {}) => handleModalClose(ev)}
                            className={`icon ${styles.headerButtons}`}
                        />
                    </div>
                </div>
                <div className={styles.bodyContainer}>
                    {!props.step ? (
                        <>
                            <h2>{t("Type of step")}</h2>
                            <div
                                style={{
                                    marginLeft: "5%",
                                    width: "30%",
                                    marginBottom: "2rem",
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <Select
                                        value={step?.type}
                                        onChange={(ev) => handleTypeChange(ev)}
                                        fullWidth
                                    >
                                        <MenuItem value={"SimpleText"}>
                                            {t("Simple Text")}
                                        </MenuItem>
                                        <MenuItem value={"SimpleVideo"}>
                                            {t("Simple Video")}
                                        </MenuItem>
                                        <MenuItem value={"SimpleInstructions"}>
                                            {t("Simple Instructions")}
                                        </MenuItem>
                                        <MenuItem value={"FileUpload"}>
                                            {t("File Upload")}
                                        </MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </>
                    ) : undefined}
                    {step ? (
                        <>
                            {returnStepComponent(
                                step,
                                projectInformation,
                                onSend,
                                handleChanges
                            )}
                            {error !== "" ? (
                                <p className="error">{error}</p>
                            ) : undefined}
                        </>
                    ) : undefined}
                </div>
            </Card>
        </Modal>
    );
};

const DialogsAndSnackbars = (props: {
    warning: boolean;
    handleDialogClose: Function;
    snackbar: {
        message: string;
        type?: "error" | "info" | "success" | "warning";
    };
    handleSnackbarClose: (snackbar: SnackbarProps) => void;
}) => {
    const { t } = useTranslation();
    const { warning, handleDialogClose, snackbar, handleSnackbarClose } = props;
    return (
        <>
            <Dialog
                open={warning}
                onClose={(ev) => handleDialogClose(false, ev)}
            >
                <DialogTitle>{t("You have unsaved changes")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("Are you sure you want to proceed?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        text={t("No")}
                        onPress={(ev: any) => handleDialogClose(false, ev)}
                    />
                    <Button
                        text={t("Yes")}
                        onPress={(ev: any) => handleDialogClose(true, ev)}
                    />
                </DialogActions>
            </Dialog>
            <CustomSnackbar
                autoHideDuration={2000}
                handleSnackbarClose={handleSnackbarClose}
                snackbar={snackbar}
            />
        </>
    );
};

function returnStepComponent(
    step: PostLevelStep,
    projectInformation: ProjectInformation,
    onSend: (newStep: PostLevelStep) => Promise<boolean>,
    onChanges: (newChanges: boolean) => void
): JSX.Element {
    let defaultLanguage = projectInformation.languages.defaultLanguage;
    let possibleLanguages = Object.getOwnPropertyNames(
        projectInformation.languages.possibleLanguages
    );

    switch (step.type) {
        case "SimpleText":
            return (
                <SimpleTextCreator
                    step={step}
                    mandatoryLanguages={[defaultLanguage]}
                    possibleLanguages={possibleLanguages}
                    onSend={onSend}
                    onChanges={onChanges}
                />
            );
        case "SimpleVideo":
            return (
                <SimpleVideoCreator
                    step={step}
                    possibleLanguages={possibleLanguages}
                    onSend={onSend}
                    onChanges={onChanges}
                />
            );
        case "SimpleInstructions":
            return (
                <SimpleInstructionsCreator
                    step={step}
                    onSend={onSend}
                    onChanges={onChanges}
                />
            );
        case "FileUpload":
            return (
                <FileUploadCreator
                    step={step}
                    mandatoryLanguages={[defaultLanguage]}
                    possibleLanguages={possibleLanguages}
                    onSend={onSend}
                    onChanges={onChanges}
                />
            );
        default:
            return <></>;
    }
}

export const LoadingSaveInputDiscardButton = (props: {
    onDiscard: Function;
    loadingVisible: boolean;
    saveVisible: boolean;
}) => {
    const { onDiscard, loadingVisible, saveVisible } = props;
    return (
        <>
            {loadingVisible ? <Loading /> : undefined}
            {saveVisible ? (
                <SaveInputDiscardButton onDiscard={onDiscard} />
            ) : null}
        </>
    );
};

export const SaveInputDiscardButton = (props: { onDiscard: Function }) => {
    const { onDiscard } = props;
    const { t } = useTranslation();
    const saveString = t("Save");
    return (
        <div className={styles.nextBackButtonContainers}>
            <div className={styles.backButtonContainer}>
                <Button
                    text={t("Discard")}
                    onPress={onDiscard}
                    type="greyOutline"
                />
            </div>
            <input
                type="submit"
                value={saveString}
                className={`shadow ${styles.submitButton}`}
            />
        </div>
    );
};

function returnStepData(type: string): object {
    switch (type) {
        case "SimpleText":
            let simpleTextData: PostSimpleTextData = {
                text: {},
            };
            return simpleTextData;
        case "SimpleVideo":
            let simpleVideoData: PostSimpleVideoData = {
                videos: [],
            };
            return simpleVideoData;
        case "SimpleInstructions":
            let simpleInstructionsData: PostSimpleInstructionsData = {
                imageGallery: [],
            };
            return simpleInstructionsData;
        case "FileUpload":
            let fileUploadData: PostFileUploadData = {
                message: {},
            };
            return fileUploadData;
        default:
            return {};
    }
}
