import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ApiService } from "../../../../apis/ApiService";
import { Project, ProjectDataTable } from "../../../../apis/Project";
import { Card } from "../../../../components/ui/card/Card";
import { TextInput } from "../../../../components/ui/input/textInput/TextInput";
import { Loading } from "../../../../components/ui/loading/Loading";
import { Path } from "../../../../components/ui/path/Path";
import styles from "./AdminProjectsList.module.css";
import "./tableStickyHeader.css";

let downloadedProjects: ProjectDataTable[];

export const AdminProjectsList = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("Projects List");

        getListProjects();
    }, []);

    async function getListProjects() {
        try {
            let projects = (await ApiService.getListAllProjects()).map(
                (project) => {
                    return new Project(project).getDataTableFormat("catalan");
                }
            );
            downloadedProjects = projects;
        } catch {
            setError(t("Could not obtain the list of projects"));
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <Loading />;
    if (error) return <Error error={error}></Error>;

    return (
        <div className={`app-body-container-box`}>
            <Header />
            <EnhancedTable projects={downloadedProjects} />
        </div>
    );
};
const Header = () => {
    const { t } = useTranslation();
    return (
        <Path
            elements={[
                {
                    text: t("Administration"),
                    linkTo: "/administration",
                },
                {
                    text: t("Projects"),
                    linkTo: "/administration/projects/projects-list",
                },
                {
                    text: t("Projects List"),
                    linkTo: "/administration/projects/projects-list",
                },
            ]}
            className={styles.header}
        />
    );
};

interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Omit<ProjectDataTable, "id">
    ) => void;
    order: Order;
    orderBy: string;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Omit<ProjectDataTable, "id">;
    label: string;
    numeric: boolean;
}

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
    {
        id: "projectName",
        numeric: false,
        disablePadding: true,
        label: "Project Name",
    },
    {
        id: "recommendedAges",
        numeric: false,
        disablePadding: false,
        label: "Recommended ages",
    },
    {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "Type of project",
    },
    {
        id: "roboticsProgrammingTypes",
        numeric: false,
        disablePadding: false,
        label: "Type of robotics kit or software",
    },
];

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof Omit<ProjectDataTable, "id">) =>
        (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        color={"red"}
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props: { onFilterClick: Function }) => {
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const methods = useForm({ mode: "onChange" });

    // We use useEffect to skip the first render from doing this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        let currentState = methods.watch(["filter"]).filter;

        if (currentState !== filter) {
            setFilter(currentState);
            props.onFilterClick(currentState);
        }
    });

    return (
        <div className={styles.filterContainer}>
            <FormProvider {...methods}>
                <form>
                    <TextInput
                        placeholder="Filtra..."
                        key="filter"
                        name="filter"
                        style={{
                            width: "100%",
                            backgroundColor: "transparent",
                        }}
                    />
                </form>
            </FormProvider>
        </div>
    );
};

const EnhancedTable = (props: { projects: ProjectDataTable[] }) => {
    const [formattedProjects, setFormattedProjects] = useState<
        ProjectDataTable[]
    >([...props.projects]);

    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] =
        useState<keyof Omit<ProjectDataTable, "id">>("projectName");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const history = useHistory();
    const { t } = useTranslation();

    function onFilterClick(filter: string) {
        if (filter === "") setFormattedProjects([...props.projects]);
        else {
            let filteredProjects = props.projects.filter((project) =>
                Object.values(project).find((val) => val.includes(filter))
            );
            setFormattedProjects([...filteredProjects]);
        }
    }
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Omit<ProjectDataTable, "id">
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, formattedProjects.length - page * rowsPerPage);

    const goToProjectDetails = (id?: string) => {
        if (!id) return;
        history.push(`/administration/projects/${id}/levels`);
    };

    return (
        <>
            <EnhancedTableToolbar onFilterClick={onFilterClick} />
            <Card className={styles.cardContainer}>
                <TableContainer className={styles.tableContainer}>
                    <Table size="medium" stickyHeader>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(
                                formattedProjects,
                                getComparator(order, orderBy)
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.projectName}
                                            onClick={() =>
                                                goToProjectDetails(row.id)
                                            }
                                        >
                                            <TableCell
                                                component="th"
                                                id={index.toString()}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.projectName}
                                            </TableCell>
                                            <TableCell>
                                                {row.recommendedAges}
                                            </TableCell>
                                            <TableCell>{t(row.type)}</TableCell>
                                            <TableCell>
                                                {row.roboticsProgrammingTypes}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={formattedProjects.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Card>
        </>
    );
};

const Error = (props: { error: string }) => {
    return <h1>{props.error}</h1>;
};
