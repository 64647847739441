import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../components/ui/button/Button";
import { IconCard } from "../../components/ui/card/Card";
import styles from "./Home.module.css";
import { FaClock, FaScroll } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Loading } from "../../components/ui/loading/Loading";
import image from "../../assets/instructor/instructor.jpg";
import { Avatar } from "../../components/ui/avatar/Avatar";
import { Instructor, InstructorClassDay } from "../../apis/Instructor";
import { AuthContext } from "../../provider/Auth";
import { dayToWeekDay, weekDayToDay } from "../../utils/functions";
import { useHistory } from "react-router";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../components/ui/snackbar/Snackbar";
import {
    ParticipantsTeam,
    ParticipantsTeamInformation,
} from "../../apis/ParticipantsTeam";

export const Home = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const authContext = useContext(AuthContext);
    const [instructorSchedule, setInstructorSchedule] = useState<
        InstructorClassDay[]
    >([]);
    const [pendingTeams, setPendingTeams] = useState<
        ParticipantsTeamInformation[]
    >([]);
    const history = useHistory();
    const [snackbar, setSnackbar] = useState<SnackbarProps>({
        message: "",
        type: "success",
    });

    useEffect(() => {
        document.title = "Roboclick Instructors";

        Promise.all([
            getInstructorSchedule(authContext!.userId!),
            getGroupsWithPendingActivities(authContext!.userId!),
        ]).finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getInstructorSchedule(instructorId: string) {
        setInstructorSchedule(
            await Instructor.getInstructorWeekSchedule(instructorId)
        );
    }

    async function getGroupsWithPendingActivities(instructorId: string) {
        await Instructor.getGroupsWithPendingActivities(instructorId)
            .then((res) => setPendingTeams(res))
            .catch(() => {});
    }

    function getTodaySchedule() {
        const today = new Date(Date.now()).getDay();
        const todayString = dayToWeekDay(today);
        const todaySchedule = instructorSchedule.filter(
            (schedule) => schedule.weekDay === todayString
        );
        return todaySchedule;
    }

    function getNextClass() {
        let todayDay = new Date(Date.now()).getDay();
        let todayHour = new Date(Date.now()).getHours();
        let nextClass: InstructorClassDay | undefined = undefined;

        for (let i = 0; i < instructorSchedule.length; i++) {
            // We first get the number of the week day of the schedule being studied
            let schDay = weekDayToDay(instructorSchedule[i].weekDay!);
            // and the one established. If it does not exist, we set it to 14 because
            // it will be always bigger than any other number
            let nextDay = nextClass?.weekDay
                ? weekDayToDay(nextClass.weekDay)
                : 14;
            // If the day is lower than our day it needs to be added 7 in order to make
            // a correct comparison. If not, tuesday would seem nearer to wednesday
            // than friday
            if (schDay < todayDay) schDay = schDay + 7;
            if (nextDay < todayDay) nextDay = nextDay + 7;
            // If the difference in days is less than the current one we update it
            if (schDay - todayDay < nextDay - todayDay) {
                nextClass = instructorSchedule[i];
                continue;
            }
            // If it is higher, we continue to the next number
            else if (schDay - todayDay > nextDay - todayDay) continue;
            // If it is the same, we check the hours and finally set the nearest one
            let schHour = +instructorSchedule[i].startHour!.slice(0, 2);
            let nextClassHour = +(nextClass?.startHour?.slice(0, 2) ?? 24);

            if (schHour > todayHour && nextClassHour > schHour)
                nextClass = instructorSchedule[i];
        }
        return nextClass;
    }

    function onEvaluateActivityPressed() {
        if (pendingTeams.length === 0) {
            setSnackbar({
                message: "Estàs al dia amb les activitats!",
                type: "success",
            });
            return;
        }
        history.push(
            `classes/class-details/${pendingTeams[0].groupId}/class-progress`
        );
    }

    if (loading) return <Loading />;
    const nextClass = getNextClass();

    return (
        <div className={`app-body-container-box ${styles.container}`}>
            <div className={styles.header}>
                <div className={styles.buttonContainer}>
                    <Button
                        text={t("Get in the next class")}
                        fillContainer
                        onPress={
                            nextClass
                                ? () =>
                                      history.push(
                                          `/classes/class-details/${nextClass.groupId}`
                                      )
                                : undefined
                        }
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        text={t("Evaluate activities")}
                        fillContainer
                        onPress={() => onEvaluateActivityPressed()}
                    />
                </div>
                <div className={styles.avatarContainer}>
                    <Avatar img={image} />
                </div>
            </div>
            <div className={styles.cardsContainer}>
                <div className={styles.leftCardContainer}>
                    <div className={styles.cardComponent}>
                        <IconCard
                            icon={<FaClock />}
                            cardProps={{
                                style: { width: "100%" },
                            }}
                        >
                            <h2>{t("Today classes")}</h2>
                            <TodayScheduleElement
                                schedule={getTodaySchedule()}
                            />
                        </IconCard>
                    </div>
                </div>
                <div className={styles.rightCardContainer}>
                    <div className={styles.cardComponent}>
                        <IconCard
                            icon={<FaScroll />}
                            cardProps={{
                                style: { width: "100%" },
                            }}
                        >
                            <h2>{t("Activities not evaluated yet")}</h2>
                            <ActivitiesElement pendingTeams={pendingTeams} />
                        </IconCard>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <p>
                    <span className={styles.bold}>
                        {t(
                            "Education is the most powerful weapon which you can use to change the world"
                        )}
                    </span>
                    <span> — Nelson Mandela</span>
                </p>
            </div>
            <CustomSnackbar
                handleSnackbarClose={(snackbar) => setSnackbar(snackbar)}
                snackbar={snackbar}
            />
        </div>
    );
};

const TodayScheduleElement = (props: { schedule: InstructorClassDay[] }) => {
    const { t } = useTranslation();
    if (props.schedule.length === 0)
        return <p>{t("You have no classes today")}</p>;

    return (
        <>
            {props.schedule.map((sch, ind) => {
                const classString = `${sch.startHour} - ${sch.finishHour}: ${sch.groupName}`;
                return <p key={ind}>{classString}</p>;
            })}
        </>
    );
};

const ActivitiesElement = (props: {
    pendingTeams: ParticipantsTeamInformation[];
}) => {
    const [loading, setLoading] = useState(true);
    const [teams, setTeams] = useState(props.pendingTeams);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.pendingTeams.length === 0) {
            setLoading(false);
            return;
        }
        ParticipantsTeam.getParticipantsNames(props.pendingTeams)
            .then((res) => setTeams(res))
            .catch(() => {})
            .finally(() => setLoading(false));
    }, [props.pendingTeams]);

    if (loading) return <Loading />;

    if (teams.length === 0)
        return <p>{t("You are up to date with all activities!")}</p>;

    return (
        <div>
            {teams.map((team) => (
                <p>
                    {`${t("The team composed by")} ${
                        team.participantsNames?.join(" i ") ?? ""
                    } ${t("has activities not evaluated yet!")}`}
                </p>
            ))}
        </div>
    );
};
