import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyAda0mS9F8zE-cgOuTxwILmm348FWM9R0c",
    authDomain: "roboclick-15109.firebaseapp.com",
    databaseURL:
        "https://roboclick-15109-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "roboclick-15109",
    storageBucket: "roboclick-15109.appspot.com",
    messagingSenderId: "648663652774",
    appId: "1:648663652774:web:5165baa00309368b2c9767",
    measurementId: "G-GHGNWT92BG",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

/* firebaseApp.auth().useEmulator("http://192.168.0.15:9099");
 */
/** Functions app using europe-west1 as the region */
export const functionsApp = firebaseApp.functions("europe-west1");

/* functionsApp.useEmulator("192.168.0.15", 5001);
 */
export default firebaseApp;
