import React, { useEffect, useRef, useState } from "react";
import { Distribution, DistributionTable } from "../../../../apis/Distribution";
import styles from "./AdminDistributionsList.module.css";
import firebase from "firebase";
import { Loading } from "../../../../components/ui/loading/Loading";
import { useTranslation } from "react-i18next";
import { Path } from "../../../../components/ui/path/Path";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { TextInput } from "../../../../components/ui/input/textInput/TextInput";
import { Card } from "../../../../components/ui/card/Card";
import { useHistory } from "react-router-dom";

export const AdminDistributionsList = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const [distributions, setDistributions] = useState<DistributionTable[]>();
    const { t } = useTranslation();

    var cursor =
        useRef<
            firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
        >();

    useEffect(() => {
        document.title = t("Distributions List");

        getListDistributions();
    }, []);

    async function getListDistributions() {
        try {
            const response = await Distribution.getListAllDistributions();
            cursor.current = response.cursor;

            const distrPromises = response.data.map((distribution) =>
                new Distribution(distribution).getDataTableFormat("catalan")
            );
            const formattedDistributions = await Promise.all(distrPromises);

            setDistributions(formattedDistributions);
        } catch {
            setError(t("Could not obtain the list of distributions"));
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <Loading />;
    if (error) return <Error error={error} />;

    return (
        <div className={`app-body-container-box`}>
            <Header />
            <EnhancedTable distributions={distributions!} />
        </div>
    );
};

const Header = () => {
    const { t } = useTranslation();
    return (
        <Path
            elements={[
                { text: t("Administration"), linkTo: "/administration" },
                {
                    text: t("Distributions"),
                    linkTo: "/administration/distributions/",
                },
                {
                    text: t("Distributions List"),
                    linkTo: "/administration/distributions/distributions-list",
                },
            ]}
            className={styles.header}
        />
    );
};

const Error = (props: { error: string }) => {
    return <h1>{props.error}</h1>;
};

interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Omit<DistributionTable, "id">
    ) => void;
    order: Order;
    orderBy: string;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Omit<DistributionTable, "id">;
    label: string;
    numeric: boolean;
}

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
    {
        id: "distributionId",
        numeric: false,
        disablePadding: true,
        label: "Distribution ID",
    },
    {
        id: "projectName",
        numeric: false,
        disablePadding: false,
        label: "Project Name",
    },
    {
        id: "ages",
        numeric: false,
        disablePadding: false,
        label: "Ages",
    },
    {
        id: "public",
        numeric: false,
        disablePadding: false,
        label: "Public",
    },
    {
        id: "active",
        numeric: false,
        disablePadding: false,
        label: "Active",
    },
    {
        id: "price",
        numeric: false,
        disablePadding: false,
        label: "Price",
    },
    {
        id: "numOffers",
        numeric: false,
        disablePadding: false,
        label: "Offers",
    },
];

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;

    const { t } = useTranslation();

    const createSortHandler =
        (property: keyof DistributionTable) =>
        (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        color={"red"}
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props: { onFilterClick: Function }) => {
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const methods = useForm({ mode: "onChange" });

    const { t } = useTranslation();

    // We use useEffect to skip the first render from doing this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        let currentState = methods.watch(["filter"]).filter;

        if (currentState !== filter) {
            setFilter(currentState);
            props.onFilterClick(currentState);
        }
    });

    return (
        <div className={styles.filterContainer}>
            <FormProvider {...methods}>
                <form>
                    <TextInput
                        placeholder={t("Filter") + "..."}
                        key="filter"
                        name="filter"
                        style={{
                            width: "100%",
                            backgroundColor: "transparent",
                        }}
                    />
                </form>
            </FormProvider>
        </div>
    );
};

const EnhancedTable = (props: { distributions: DistributionTable[] }) => {
    const [formattedDistributions, setFormattedDistributions] = useState<
        DistributionTable[]
    >([...props.distributions]);

    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] =
        useState<keyof DistributionTable>("distributionId");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const history = useHistory();
    const { t } = useTranslation();

    function onFilterClick(filter: string) {
        if (filter === "") setFormattedDistributions([...props.distributions]);
        else {
            let filteredDistributions = props.distributions.filter((distr) =>
                Object.values(distr).find((val) => val.includes(filter))
            );
            setFormattedDistributions([...filteredDistributions]);
        }
    }
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Omit<DistributionTable, "id">
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            formattedDistributions.length - page * rowsPerPage
        );

    const goToDistributionDetails = (id?: string) => {
        if (!id) return;
        history.push(`/administration/distributions/edit-distribution/${id}/`);
    };

    return (
        <>
            <EnhancedTableToolbar onFilterClick={onFilterClick} />
            <Card className={styles.cardContainer}>
                <TableContainer className={styles.tableContainer}>
                    <Table size="medium" stickyHeader>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(
                                formattedDistributions,
                                getComparator(order, orderBy)
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    const fRow: DistributionTable =
                                        row as DistributionTable;
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={fRow.distributionId}
                                            onClick={() => {
                                                goToDistributionDetails(
                                                    fRow.distributionId
                                                );
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                id={index.toString()}
                                                scope="fRow"
                                                padding="none"
                                                className={
                                                    styles.distributionIdCell
                                                }
                                            >
                                                {fRow.distributionId}
                                            </TableCell>

                                            <TableCell>
                                                {fRow.projectName}
                                            </TableCell>
                                            <TableCell>{fRow.ages}</TableCell>
                                            <TableCell>
                                                {t(fRow.public)}
                                            </TableCell>
                                            <TableCell>
                                                {t(fRow.active)}
                                            </TableCell>
                                            <TableCell>{fRow.price}</TableCell>
                                            <TableCell>
                                                {fRow.numOffers}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={formattedDistributions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Card>
        </>
    );
};
