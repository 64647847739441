import React, { useEffect, useState } from "react";
import styles from "./AdminNewProject.module.css";
import { FormProvider, useForm } from "react-hook-form";
import { FileInput } from "../../../../components/ui/input/fileInput/FileInput";
import { Input } from "../../../../components/ui/input/Input";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, ButtonProps } from "../../../../components/ui/button/Button";
import { TextInput } from "../../../../components/ui/input/textInput/TextInput";
import {
    SelectInput,
    SelectOptionProps,
} from "../../../../components/ui/input/selectInput/SelectInput";
import { RatingInput } from "../../../../components/ui/input/ratingInput/RatingInput";

import AvailableLanguages from "../../../../assets/strings/translations/availableLanguages.json";
import { roboticsKits } from "../../../../assets/strings/roboticsKits.json";
import { programmingSoftwares } from "../../../../assets/strings/programmingSoftwares.json";
import { SliderInput } from "../../../../components/ui/input/sliderInput/SliderInput";
import { Project } from "../../../../apis/Project";
import { SuccessPage } from "../../../../components/ui/success/SuccessPage";
import { Loading } from "../../../../components/ui/loading/Loading";
import { Path } from "../../../../components/ui/path/Path";

export interface FrontCardDataProps {
    projectName: string;
    backgroundImage: [File];
    summaryImage: [File];
    possibleLanguages: string[];
}

export interface BackCardDataProps {
    defaultLanguage: string;
    projectShortDescription: string;
    projectLongDescription: string;
    difficulty: number;
    projectType: "robotics" | "programming" | "robotics and programming";
    recommendedAges: [number, number];
    minVersion: string;
    roboticsTypes: string[];
    programmingTypes: string[];
}

let frontCardData: FrontCardDataProps;
let backCardData: BackCardDataProps;

/**
 *
 * @returns Shows the project creation page and, when the project is created, a congratulations page
 */
export const AdminNewProject = () => {
    const [flipped, setFlipped] = useState(false);
    const [created, setCreated] = useState(false);

    const { t } = useTranslation();
    useEffect(() => {
        document.title = t("Project Creation");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (created) return <ProjectSuccess />;

    function onProjectCreated() {
        setCreated(true);
    }

    return (
        <div className={`app-body-container-box overflow-y`}>
            <Header />
            <div className={styles.formContainer}>
                <div
                    className={`${styles.flipper} ${
                        flipped ? styles.flip : ""
                    }`}
                >
                    <div className={`${styles.cardFrontContainer} shadow`}>
                        <FrontCard flip={flipTheCard} />
                    </div>
                    <div className={`${styles.cardBackContainer} shadow`}>
                        <BackCard
                            flip={flipTheCard}
                            onProjectCreated={onProjectCreated}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    function flipTheCard() {
        setFlipped(!flipped);
    }
};

const Header = () => {
    const { t } = useTranslation();
    return (
        <Path
            elements={[
                { text: t("Administration"), linkTo: "/administration" },
                {
                    text: t("Projects"),
                    linkTo: "/administration/projects",
                },
                { text: t("Create a new project") },
            ]}
        />
    );
};

const FrontCard = (props: { flip: () => void }) => {
    const availableLanguages = AvailableLanguages.availableLanguages;
    const { t } = useTranslation();
    const methods = useForm({
        mode: "onBlur",
    });

    const onSubmit = methods.handleSubmit((data: any) => {
        const selectedLanguages = availableLanguages.filter((val) => {
            return data[val] === true;
        });
        frontCardData = {
            summaryImage: data.summaryImage,
            backgroundImage: data.backgroundImage,
            projectName: data.projectName,
            possibleLanguages: selectedLanguages,
        };
        props.flip();
    });

    // Can't use the result of a function directly on the input
    const continueString: string = t("Continue");

    return (
        <FormProvider {...methods}>
            <form className={styles.frontCard} onSubmit={onSubmit}>
                <Input
                    inputProps={{
                        name: "projectName",
                        type: "text",
                        placeholder: t("Project Name"),
                    }}
                    mandatory
                />
                <div className={`${styles.fileInputsContainer}`}>
                    <div className={styles.fileInputAndMessageContainer}>
                        <div className={`${styles.fileInput} `}>
                            <FileInput
                                inputProps={{
                                    accept: "image/png, image/jpg, image/jpeg, image/gif",
                                    name: "summaryImage",
                                    maxLength: 1,
                                }}
                                height={250}
                                mandatory
                            />
                        </div>
                        <p className="medium">{t("Summary Image")}</p>
                    </div>
                    <div className={styles.fileInputAndMessageContainer}>
                        <div className={`${styles.fileInput} `}>
                            <FileInput
                                inputProps={{
                                    accept: "image/png, image/jpg, image/jpeg, image/gif",
                                    name: "backgroundImage",
                                    maxLength: 1,
                                }}
                                height={250}
                                mandatory
                            />
                        </div>
                        <p className="medium">{t("Background Image")}</p>
                    </div>
                </div>
                <label className={styles.mediumSizeFont}>
                    <>{t("Supported languages") + ":"}</>
                </label>
                <div className={styles.groupCheckboxes}>
                    {/** We create a checkbox input for every language available */}
                    {availableLanguages.map((val, ind) => {
                        return (
                            <div key={ind} className={styles.checkboxContainer}>
                                <Input
                                    inputProps={{
                                        type: "checkbox",
                                        name: val,
                                    }}
                                    key={ind}
                                />
                                <label>{t(val)}</label>
                            </div>
                        );
                    })}
                </div>
                <input
                    type="submit"
                    value={continueString}
                    className={`shadow ${styles.submitButton}`}
                />
            </form>
        </FormProvider>
    );
};

const BackCard = (props: { flip: Function; onProjectCreated: Function }) => {
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const methods = useForm({
        mode: "onBlur",
    });

    const onSubmit = methods.handleSubmit(async (data: BackCardDataProps) => {
        setLoading(true);
        setError("");
        backCardData = data;
        let project: Project;

        try {
            project = Project.fromForm(frontCardData, backCardData);
        } catch {
            setError(
                t("Some camps are missing. Check both sides of the card.")
            );
            setLoading(false);
            return;
        }

        const response = await project.uploadToFirebase();
        setLoading(false);

        if (response === "success") props.onProjectCreated();
        else if (response === "error")
            setError(
                t(
                    "There was an error uploading the project. Try again later. If the problem persists, contact the administrator"
                )
            );
    });

    // Can't use the result directly on the input
    const continueString: string = t("Continue");

    function getSelectOptions(options?: string[]): SelectOptionProps[] {
        if (!options) return [{ display: "", value: "" }];
        return options.map((val) => {
            return {
                display: t(val),
                value: val,
            };
        });
    }
    return (
        <FormProvider {...methods}>
            <form className={styles.backCard} onSubmit={onSubmit}>
                <div className={styles.backCardFullContainer}>
                    <div className={styles.formHalfWidthContainer}>
                        <label className={styles.mediumSizeFont}>
                            {t("Default language for the project") + ":"}
                        </label>
                        <div
                            className={`${styles.width85} ${styles.inputContainer}`}
                        >
                            <SelectInput
                                name="defaultLanguage"
                                options={getSelectOptions(
                                    frontCardData?.possibleLanguages
                                )}
                                mandatory
                            />
                            <p className={styles.smallText}>
                                {t(
                                    "The language selected now must be the one used in the following camps"
                                )}
                            </p>
                        </div>
                        <label className={styles.mediumSizeFont}>
                            {t("Project description in one sentence") + ":"}
                        </label>
                        <div
                            className={`${styles.width85} ${styles.inputContainer}`}
                        >
                            <TextInput
                                multiline
                                name="projectShortDescription"
                                mandatory
                            />
                        </div>
                        <label className={styles.mediumSizeFont}>
                            {t("Long project description") + ":"}
                        </label>
                        <div
                            className={`${styles.width85} ${styles.inputContainer}`}
                        >
                            <TextInput
                                multiline
                                name="projectLongDescription"
                                mandatory
                                rows={3}
                            />
                        </div>
                        <label className={styles.mediumSizeFont}>
                            {t("Difficulty (independently of age)") + ":"}
                        </label>
                        <div
                            className={`${styles.difficultyRating} ${styles.inputContainer}`}
                        >
                            <RatingInput
                                name="difficulty"
                                mandatory
                                size="medium"
                            />
                        </div>
                        <label className={styles.mediumSizeFont}>
                            {t("Project type") + ":"}
                        </label>
                        <div
                            className={`${styles.width85} ${styles.inputContainer}`}
                        >
                            <SelectInput
                                mandatory
                                name="projectType"
                                options={getSelectOptions([
                                    "robotics",
                                    "programming",
                                    "robotics and programming",
                                ])}
                            />
                        </div>
                    </div>
                    <div className={styles.formHalfWidthContainer}>
                        <label className={styles.mediumSizeFont}>
                            {t("Robotics kit type") + ":"}
                        </label>
                        <div
                            className={`${styles.width85} ${styles.inputContainer}`}
                        >
                            <SelectInput
                                mandatory
                                name="roboticsTypes"
                                options={getSelectOptions(roboticsKits.sort())}
                                multiple
                                chips
                            />
                        </div>
                        <label className={styles.mediumSizeFont}>
                            {t("Programming software type") + ":"}
                        </label>

                        <div
                            className={`${styles.width85} ${styles.inputContainer}`}
                        >
                            <SelectInput
                                mandatory
                                name="programmingTypes"
                                options={getSelectOptions(
                                    programmingSoftwares.sort()
                                )}
                                multiple
                                chips
                            />
                        </div>
                        <label className={styles.mediumSizeFont}>
                            {t("Minimum version") + ":"}
                        </label>
                        <div
                            className={`${styles.inputContainer} ${styles.width85}`}
                        >
                            <SelectInput
                                mandatory
                                name="minVersion"
                                options={getSelectOptions(["1"])}
                            />
                        </div>
                        <label className={styles.mediumSizeFont}>
                            {t("Recommended ages") + ":"}
                        </label>
                        <div
                            className={`${styles.inputContainer} ${styles.width85}`}
                        >
                            <SliderInput
                                name="recommendedAges"
                                sliderProps={{
                                    valueLabelDisplay: "on",
                                    min: 4,
                                    max: 18,
                                }}
                                labelDif={1}
                                margins={{
                                    recommendedMargin: 1,
                                    optimalMargin: [3, 4],
                                }}
                                mandatory
                            />
                        </div>
                    </div>
                </div>

                {error !== "" ? <p className="error">{error}</p> : null}
                {loading ? <Loading /> : null}

                <div className={styles.nextBackButtonContainers}>
                    <div className={styles.backButtonContainer}>
                        <Button
                            text={t("Back")}
                            onPress={props.flip}
                            type="greyOutline"
                        />
                    </div>
                    <input
                        type="submit"
                        value={continueString}
                        className={`shadow ${styles.submitButton}`}
                    />
                </div>
            </form>
        </FormProvider>
    );
};

const ProjectSuccess = () => {
    const { t } = useTranslation();

    const history = useHistory();

    const buttonsArray: ButtonProps[] = [
        {
            text: t("See the projects list"),
            onPress: navigateToProjectList,
            type: "greyOutline",
        },

        { text: t("Continue"), onPress: navigateToProjectDetails },
    ];
    return (
        <div className={`app-body-container-box`}>
            <Header />
            <div style={{ flexGrow: 1 }}>
                <SuccessPage
                    buttons={buttonsArray}
                    message={t("Project created successfully")}
                />
            </div>
        </div>
    );

    function navigateToProjectDetails() {
        history.push("/administration/projects/1fasd5d2a5q8er1fasdf/levels");
    }
    function navigateToProjectList() {
        history.push("/administration/projects/projects-list");
    }
};
