import React from "react";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { Loading } from "../loading/Loading";
import styles from "./Button.module.css";

export interface ButtonProps {
    text: string;
    onPress?: Function;
    divProps?: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >;
    type?: "solid" | "blueOutline" | "greyOutline";
    fillContainer?: boolean;
    disabled?: boolean;
}

export function Button(props: ButtonProps) {
    const { t } = useTranslation();
    const { text, onPress, fillContainer, disabled } = props;
    const type = props.type ?? "solid";

    return (
        <div
            className={`${styles.container} ${
                fillContainer ? styles.fillContainer : ""
            }`}
            {...props.divProps}
        >
            <div
                className={`${styles.button} ${styles[type]} ${
                    styles.fillContainer
                } ${disabled ? styles.disabled : ""}
				shadow`}
                onClick={onPress && !disabled ? () => onPress() : undefined}
            >
                <p>{t(text)}</p>
            </div>
        </div>
    );
}

export const SaveDiscardButtons = (props: {
    onDiscard: Function;
    onSave: Function;
    style?: React.CSSProperties;
    className?: string;
    uploading?: boolean;
    saveDisabled?: boolean;
}) => {
    const { onDiscard, onSave, style, className, uploading, saveDisabled } =
        props;
    return (
        <>
            {uploading ? <Loading /> : undefined}
            <div
                className={`${styles.saveDiscardButtonsContainer} ${className}`}
                style={style}
            >
                <Button text="Discard" type="greyOutline" onPress={onDiscard} />
                <Button text="Save" onPress={onSave} disabled={saveDisabled} />
            </div>
        </>
    );
};

interface IconButtonProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    text?: string;
    onPress?: () => void;
    icon: React.ReactNode;
}
export const IconButton = (props: IconButtonProps) => {
    const { text, onPress, icon, ...divProps } = props;
    return (
        <div
            {...divProps}
            className={`${divProps.className} ${styles.button} ${styles.solid} ${styles.container} ${styles.paddingButton}`}
            onClick={onPress ? () => onPress() : undefined}
        >
            {icon}
            <p>{text}</p>
        </div>
    );
};
