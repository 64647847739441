import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sidebarData } from "./SidebarData";
import styles from "./Navbar.module.css";
import { IconContext } from "react-icons/lib";
import logo from "../../assets/logo/logoRoboclickName.svg";
import firebaseApp from "../../firebase";

export function NavigationBar() {
    const { t } = useTranslation();
    const location = useLocation();

    const history = useHistory();

    const disconnect = () => {
        firebaseApp.auth().signOut();
        history.push("/login");
    };

    return (
        <div className={`${styles.container} shadow`}>
            <IconContext.Provider
                value={{
                    className: "navbar-icon",
                }}
            >
                <div className={styles.logoMenuContainer}>
                    <div className={styles.logo}>
                        <img src={logo} alt="roboclick logo" />
                    </div>
                    <div className={styles.horizontalLine} />
                    <nav className={styles.fill}>
                        <ul className={styles.navMenu}>
                            {sidebarData.map((item, index) => {
                                /* 	It's active if the path is the same of the element
								 OR if there is no path and it is the first element,
								 thus the Home element */
                                let active =
                                    location.pathname.startsWith(item.path) ||
                                    (index === 0 && location.pathname === "/");

                                return (
                                    <Link to={item.path} key={index}>
                                        <li
                                            className={`
												${styles.navMenuItem} ${active ? styles.active : ""}`}
                                        >
                                            {item.icon}
                                            <p>{t(item.title)}</p>
                                        </li>
                                    </Link>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
                <div className={styles.disconnectContainer}>
                    <div
                        className={styles.disconnectBox}
                        onClick={() => disconnect()}
                    >
                        <span>{t("Disconnect")}</span>
                    </div>
                </div>
            </IconContext.Provider>
        </div>
    );
}
