import { useEffect, useState } from "react";
import onlyRobots from "../../assets/logo/onlyRobots.svg";
import logoWords from "../../assets/logo/logoWords.svg";

import styles from "./SignIn.module.css";
import { Input, InputAdornment } from "@material-ui/core";
import { FaEye, FaEyeSlash, FaLock, FaUser } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import firebaseApp from "../../firebase";
import { Loading } from "../../components/ui/loading/Loading";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ForgottenPasswordModal } from "./forgottenPassword/ForgottenPasswordModal";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../components/ui/snackbar/Snackbar";

/** The sign in to Firebase Authentication component. Only with email */
export const SignIn = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const [forgottenModal, setForgottenModal] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackbarProps>({ message: "" });

    const history = useHistory();
    /** Used to know if it has been redirected from an invalid user. Will show an error */
    const { invalidRole } = useParams<{ invalidRole: string }>();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("Roboclick Instructors Login");

        if (invalidRole) setError(t("This account does not have permission"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShowHidePassword = () => setShowPassword(!showPassword);
    const handleForgottenPassword = () => setForgottenModal(true);
    const handleModalClose = (passwordReset?: boolean) => {
        setForgottenModal(false);
        if (passwordReset)
            setSnackbar({
                message: "An email has been sent with the password recovery",
                type: "success",
            });
    };

    const handleLogin = () => {
        setLoading(true);
        setError("");
        firebaseApp
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => history.push("/home"))
            .catch((err) => {
                setError(err.message);
                setLoading(false);
            });
    };

    /** If invalidRole is true, we show an error */

    return (
        <div className={styles.container}>
            <div className={`${styles.box} shadow`}>
                <div className={styles.imageContainer}>
                    <img
                        src={onlyRobots}
                        alt="robots"
                        className={styles.robotsImage}
                    />
                    <img
                        src={logoWords}
                        alt="roboclick logo"
                        className={styles.logoImage}
                    />
                </div>
                <div className={styles.loginContainer}>
                    <h1>{t("Log In")}</h1>
                    <IconContext.Provider
                        value={{ className: styles.fieldsIcon }}
                    >
                        <Input
                            id="username"
                            placeholder={t("Username")}
                            startAdornment={
                                <InputAdornment position="start">
                                    <FaUser />
                                </InputAdornment>
                            }
                            onChange={(ev) => setEmail(ev.target.value)}
                            className={`${styles.username} ${styles.textfield}`}
                        />
                        <Input
                            id="password"
                            placeholder={t("Password")}
                            type={showPassword ? "text" : "password"}
                            onChange={(ev) => setPassword(ev.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <FaLock />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment
                                    position="end"
                                    onClick={() => handleShowHidePassword()}
                                >
                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                </InputAdornment>
                            }
                            className={`${styles.password} ${styles.textfield}`}
                        />
                    </IconContext.Provider>
                    <p
                        className={styles.forgotten}
                        onClick={() => handleForgottenPassword()}
                    >
                        {t("Have you forgotten your password?")}
                    </p>
                    {/** If it is loading, we disable the login button and show Loading */}
                    {!loading ? (
                        <>
                            <div
                                className={styles.submitButton}
                                onClick={() => handleLogin()}
                            >
                                <p>{t("Log in")}</p>
                            </div>
                            {error !== "" ? (
                                <p className={`error ${styles.error}`}>
                                    {error}
                                </p>
                            ) : null}
                        </>
                    ) : (
                        <div className={styles.loading}>
                            <Loading />
                        </div>
                    )}
                </div>
                <CustomSnackbar
                    snackbar={snackbar}
                    handleSnackbarClose={(snack) => setSnackbar(snack)}
                />
            </div>
            <ForgottenPasswordModal
                open={forgottenModal}
                onClose={handleModalClose}
            />
        </div>
    );
};
