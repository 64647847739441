import {
    RenderDayCellEventArgs,
    CalendarComponent,
} from "@syncfusion/ej2-react-calendars";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClassDay, Group, GroupSummary } from "../../apis/Group";
import { Loading } from "../../components/ui/loading/Loading";
import { addClass } from "@syncfusion/ej2-base";
import styles from "./Calendar.module.css";
import { AuthContext } from "../../provider/Auth";
import "./calendarStyles.css";
import { format } from "path";
import { Path } from "../../components/ui/path/Path";
import { blue } from "../../utils/Colors";
import { Divider } from "@material-ui/core";

export const Calendar = () => {
    const { t } = useTranslation();
    const [classes, setClasses] = useState<GroupSummary[]>([]);
    const [loading, setLoading] = useState(true);
    const [fatalError, setFatalError] = useState("");
    const authContext = useContext(AuthContext);

    useEffect(() => {
        document.title = t("Calendar");
        getGroupsSummaries().finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getGroupsSummaries() {
        try {
            const groups = await Group.getGroupsFromInstructor(
                authContext!.userId!
            );
            const summaryGroups = await Promise.all(
                groups.map((group) => group.getGroupSummary())
            );
            setClasses(summaryGroups);
        } catch {
            setFatalError(
                t(
                    "You have no groups attached or there has been a problem reaching them"
                )
            );
        }
    }

    if (loading) return <Loading />;

    return (
        <div className={`app-body-container-box`}>
            <Header />
            <CustomCalendar
                schedule={classes.flatMap((group) => group.schedule)}
            />
        </div>
    );
};

const CustomCalendar = (props: { schedule: ClassDay[] }) => {
    const [workDays, setWorkDays] = useState<number[]>();

    useEffect(() => {
        const days = Array.from(
            new Set(
                props.schedule.map((classDay) => {
                    switch (classDay.weekDay) {
                        case "sunday":
                            return 0;
                        case "monday":
                            return 1;
                        case "tuesday":
                            return 2;
                        case "wednesday":
                            return 3;
                        case "thursday":
                            return 4;
                        case "friday":
                            return 5;
                        case "saturday":
                            return 6;
                        default:
                            return 0;
                    }
                })
            )
        );
        setWorkDays(days);
    }, [props]);

    function specialDate(args: RenderDayCellEventArgs, name: String) {
        let span = document.createElement("span");
        span.setAttribute("class", "e-icons highlight");
        args?.element?.firstElementChild?.setAttribute("title", name + "!");
        addClass([args?.element!], ["e-day", "special", name.toLowerCase()]);
        args?.element?.setAttribute("data-val", name + "!");
        args?.element?.setAttribute("title", name + "!");
        args?.element?.appendChild(span);
    }

    function customDates(args?: RenderDayCellEventArgs): void {
        let day = args?.date?.getDay();
        if (day && workDays!.includes(day)) {
            specialDate(args!, "Class");
        }
    }

    if (!workDays) return <Loading />;

    return (
        <div className={`${styles.calendarContainer} shadow big-calendar`}>
            <CalendarComponent
                id="calendar"
                renderDayCell={customDates.bind(this)}
                className={`e-customStyle ${styles.calendar}`}
                firstDayOfWeek={1}
            />
            <Divider orientation="vertical" />

            <Legend />
        </div>
    );
};

const Legend = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.legendContainer}>
            <div className={styles.legendElementContainer}>
                <div
                    className={styles.legendColor}
                    style={{ backgroundColor: blue }}
                />
                <p className={styles.legendElementText}>{t("Class day")}</p>
            </div>
        </div>
    );
};
const Header = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.headerContainer}>
            <Path elements={[{ text: t("School year calendar") }]} />
        </div>
    );
};
