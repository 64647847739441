import { Rating, RatingProps } from "@material-ui/lab";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./RatingInput.module.css";

export const RatingInput: React.FC<{
    name: string;
    mandatory?: boolean;
    size?: RatingProps["size"];
}> = (props) => {
    const { control, errors } = useFormContext();
    const errorMessage = errors[props.name];
    const { t } = useTranslation();
    return (
        <div>
            <Controller
                name={props.name}
                control={control}
                rules={{ required: props.mandatory }}
                as={
                    <Rating
                        name={props.name}
                        className={styles.rating}
                        size={props.size}
                    />
                }
            ></Controller>
            {errorMessage ? (
                <p className="error ">{t("This is mandatory")}</p>
            ) : null}
        </div>
    );
};
