import { Mark, Slider, SliderProps } from "@material-ui/core";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./SliderInput.module.css";

/**
 * Must be used inside FormProvider or will throw
 */
export const SliderInput: React.FC<{
    sliderProps?: SliderProps;
    mandatory?: boolean;
    labelDif?: number;
    margins?: {
        optimalMargin: [number, number];
        recommendedMargin: number;
    };
    name: string;
    className?: string;
    defaultValue?: [number, number];
}> = (props) => {
    const min = props.sliderProps?.min ?? 0;
    const max = props.sliderProps?.max ?? 100;
    const [value, setValue] = useState<[number, number]>(
        props.defaultValue ?? [min, max]
    );

    const { control } = useFormContext();

    const handleChange = (event: any, newValue: number | number[]) => {
        setValue(newValue as [number, number]);
    };

    const color = chooseColor(value);

    return (
        <div className={`${styles.sliderContainer} ${props.className}`}>
            <Controller
                name={props.name}
                control={control}
                defaultValue={props.defaultValue ?? [min, max]}
                render={(controllerProps) => (
                    <Slider
                        {...props.sliderProps}
                        marks={
                            props.labelDif
                                ? getMarks(min, max, props.labelDif)
                                : undefined
                        }
                        className={styles[color]}
                        value={controllerProps.value}
                        onChange={(events, number) => {
                            handleChange(events, number);
                            controllerProps.onChange(number);
                        }}
                    />
                )}
            ></Controller>
        </div>
    );

    /**
     * @param sNum The smallest number to get a mark
     * @param hNum The highest number to get a mark
     * @param dif Every how many numbers we want a mark
     * @returns Returns an array with the calculated marks from the minimum to the maximum number indicated, both included
     */
    function getMarks(sNum: number, hNum: number, dif: number): Mark[] {
        let marks: Mark[] = [];

        for (let i = sNum; i <= hNum; i = i + dif) {
            marks.push({ value: i, label: i });
        }

        if (marks[marks.length - 1].value !== hNum) {
            marks.push({ value: hNum, label: hNum });
        }
        return marks;
    }

    /**
     * Depending on the difference of ages indicated, it will return a color or another
     * @param numberArray The ages selected to compare
     * @returns A string with the color, i.e. blue, yellow or red
     */
    function chooseColor(numberArray: [number, number]) {
        if (!props.margins) return "blue";

        const difference = numberArray[1] - numberArray[0];
        const optimalArray = props.margins.optimalMargin.sort((a, b) => a - b);

        if (isNumInsideRange(difference, optimalArray)) return "blue";

        const { recommendedMargin } = props.margins;
        const recommendedArray: [number, number] = [
            optimalArray[0] - recommendedMargin,
            optimalArray[1] + recommendedMargin,
        ];

        if (isNumInsideRange(difference, recommendedArray)) return "yellow";

        return "red";

        function isNumInsideRange(num: number, array: [number, number]) {
            if (num >= array[0] && num <= array[1]) return true;
            else return false;
        }
    }
};
