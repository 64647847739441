import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { functionsApp } from "../firebase";
import { getAge, getBase64 } from "../utils/functions";
import { ParticipantsTeamSummary } from "./ParticipantsTeam";

/** The information that a user has */
export interface UserInformation {
    children: { [key: string]: KidInfo };
    parentsIds?: string[];
    role?: "kid" | "instructor" | "admin";
    userId: string;
    parentsInformation?: ParentInformation[];
    authUid?: string[];
    province: string;
    avatarUrl?: string;
    //subscription?: Subscription[];
}

export interface KidInfo extends KidBasicInfo {
    kidId: string;
    avatar?: string;
    specialProjectDistributionsIds?: string[];
    teams?: {
        onGoing?: ParticipantsTeamSummary[];
        finished?: ParticipantsTeamSummary[];
    };
    stars?: number;
    defaultTextLanguage?: string;
    defaultAudioLanguage?: string;
    permissions: {
        commercialInformation: boolean;
        imageRights: boolean;
    };
}

export interface KidBasicInfo {
    name: string;
    surname: string;
    birthDate: number;
    gender: "male" | "female";
}

/** A project participation is a bridge between users, groups, subscriptions and projects.
 * Includes the projectId, the groupId, the subscriptionId and whether this participation
 * is active or not. Should usually be attached to a user.
 */
export interface ProjectParticipation {
    projectId: string;
    groupId: string;
    subscriptionId: string;
    participantsTeamId: string;
    active: boolean;
}
export interface SubscriptionInformation {
    type: "project" | "extraescolar";
    subscriptionId: string;
    product: string[];
    paymentType: "single" | "recurrent";
    price: number;
    times?: number;
    period?: "week" | "month" | "year";
    creationDate: Date;
    startingDate?: Date;
    finishingDate?: Date;
    paymentIds?: string[];
    state: "active" | "error" | "finished" | "suspended";
    lastPaymentDate?: Date;
}

/** A subscription is what allows a user to participate in a group. There are different
 * types of subscription and different payment methods.  */
export class Subscription implements SubscriptionInformation {
    type: "project" | "extraescolar";
    subscriptionId: string;
    product: string[];
    paymentType: "single" | "recurrent";
    price: number;
    times?: number;
    period?: "week" | "month" | "year";
    creationDate: Date;
    startingDate?: Date;
    finishingDate?: Date;
    paymentIds?: string[];
    state: "active" | "error" | "finished" | "suspended";
    lastPaymentDate?: Date;

    constructor(subscriptionProps: SubscriptionInformation) {
        this.type = subscriptionProps.type;
        this.subscriptionId = subscriptionProps.subscriptionId;
        this.product = subscriptionProps.product;
        this.paymentType = subscriptionProps.paymentType;
        this.price = subscriptionProps.price;
        this.times = subscriptionProps.times;
        this.period = subscriptionProps.period;
        this.creationDate = subscriptionProps.creationDate;
        this.startingDate = subscriptionProps.startingDate;
        this.finishingDate = subscriptionProps.finishingDate;
        this.paymentIds = subscriptionProps.paymentIds;
        this.state = subscriptionProps.state;
        this.lastPaymentDate = subscriptionProps.lastPaymentDate;
    }

    static fromId(id: string): Subscription {
        let any: any;
        return new Subscription(any);
    }

    getNextPayment() {
        return new Date(Date.now());
    }
}

export interface ParentInformation {
    parentId: string;
    name?: string;
    surname?: string;
    city?: string;
    cp?: number;
    email: string;
    childrenIds?: string[];
    preferredLanguage?: string;
    phoneNumber?: string;
}

export interface ParentSummary {
    name?: string;
    email: string;
    phoneNumber?: string;
    preferredLanguage?: string;
}

export class Parent implements ParentInformation, ParentSummary {
    parentId: string;
    name?: string;
    surname?: string;
    city?: string;
    cp?: number;
    email: string;
    childrenIds?: string[];
    preferredLanguage?: string;
    phoneNumber?: string;

    constructor(parent: ParentInformation) {
        this.parentId = parent.parentId;
        this.name = parent.name;
        this.surname = parent.surname;
        this.city = parent.city;
        this.cp = parent.cp;
        this.email = parent.email;
        this.childrenIds = parent.childrenIds;
        this.preferredLanguage = parent.preferredLanguage;
        this.phoneNumber = parent.phoneNumber;
    }

    /** Creates an id for the Parent and returns this class */
    static fromForm(data: Omit<ParentInformation, "parentId">) {
        let parentId = uuidv4();
        let parentInformation: ParentInformation = {
            ...data,
            parentId: parentId,
        };
        return new Parent(parentInformation);
    }

    /** Tries to post the parent to the database */
    static async postParent(parent: ParentInformation) {
        const response = await functionsApp.httpsCallable("users-postParent")({
            parent: parent,
        });
        return response;
    }

    /** Tries to delete the parent in the database */
    static async deleteParent(parentId: string) {
        const response = await functionsApp.httpsCallable("users-deleteParent")(
            {
                parentId: parentId,
            }
        );
        return response;
    }
}

export class User implements UserInformation {
    children: { [key: string]: KidInfo };
    parentsIds?: string[];
    role?: "kid" | "instructor" | "admin" | undefined;
    userId: string;
    parentsInformation?: ParentInformation[] | undefined;
    authUid?: string[] | undefined;
    province: string;
    avatarUrl?: string;

    constructor(user: UserInformation | PostUserInformation) {
        this.userId = user.userId;
        this.children = user.children;
        this.parentsIds = user.parentsIds;
        this.parentsInformation =
            (user as UserInformation).parentsInformation ?? undefined;
        this.role = user.role;
        this.authUid = user.authUid;
        this.province = user.province;
    }

    /** Will create a new userId and return this class with that id */
    static createNewUserFromForm(user: Omit<UserInformation, "userId">) {
        const userId = uuidv4();
        return new User({ ...user, userId: userId });
    }

    /** Will try to get a user from the API and will return a User class with that data */
    static async getFromId(id: string): Promise<User> {
        const response = await functionsApp.httpsCallable("users-getUser")({
            userId: id,
        });
        const information: PostUserInformation =
            response.data as PostUserInformation;
        return new User(information);
    }

    static async getProjectNames() {}

    /** Will try to get the Parents Information from the parentsIds that this user has */
    static async getParentsInformation(userId: string) {
        const responses = await functionsApp.httpsCallable(
            "users-getParentsFromUserId"
        )({ userId: userId });
        return responses.data as ParentInformation[];
    }

    /** Tries to get a list of all users with pagination activated.
     * @param cursor If provided, it will start to return results from this cursor
     * @param limit If provided, only this number of documents will be returned. The *default value* is 20
     * @param orderBy If provided, this will be the order. If combined with a cursor, should be
     * the same than the previous query. The *default value* is "name" */
    static async getListAllUsers(
        cursor?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        limit?: number,
        orderBy?: string
    ) {
        var data: { [key: string]: any } = {};
        if (cursor) data.cursor = cursor;
        if (limit) data.limit = limit;
        if (orderBy) data.orderBy = orderBy;

        const response = await functionsApp.httpsCallable("users-getUsers")(
            data
        );
        return response.data as {
            data: PostUserInformation[];
            cursor: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
        };
    }

    static async deleteUser() {}

    static async suspendUser() {}

    /** Checks if the user has an avatar File. If it has, it converts it to base64, deletes
     * the previous and tries to upload it overriding the previous user if there was one*/
    static async postNewUser(user: PostUserInformation) {
        if (user.avatarFile) {
            user.avatarBase64 = await getBase64(user.avatarFile);
            delete user.avatarFile;
        }
        const response = await functionsApp.httpsCallable("users-postNewUser")({
            user: user,
        });
        return response.data as PostUserInformation;
    }

    /** Checks if the user has an avatar File. If it has, it converts it to base64, deletes
     * the previous and tries to upload it updating only the camps of this user*/
    static async postUserInformationUpdate(user: PostUserInformation) {
        if (user.avatarFile) {
            user.avatarBase64 = await getBase64(user.avatarFile);
            delete user.avatarFile;
        }

        await functionsApp.httpsCallable("users-postUserInformationUpdate")({
            user: user,
        });
        return;
    }

    /** Returns a PostUserInformation */
    getPostUserInformation(): PostUserInformation {
        let userInformation: PostUserInformation = {
            children: this.children,
            parentsIds: this.parentsIds,
            role: this.role,
            userId: this.userId,
            parentsInformation: this.parentsInformation,
            authUid: this.authUid,
            province: this.province,
        };

        Object.keys(userInformation).forEach((key) => {
            if ((userInformation as any)[key] === undefined)
                delete (userInformation as any)[key];
        });

        return userInformation;
    }

    /** Returns only the required data to show in a table.  */
    getDataTableFormat(): UserDataTable {
        let childrenNames = this.children
            ? Object.values(this.children)
                  .map((kid) => kid.name)
                  .join(", ")
            : "No té fills";

        let ages = this.children
            ? Object.values(this.children)
                  .map((kid) => getAge(kid.birthDate))
                  .join(", ")
            : " ";

        return {
            id: this.userId,
            childrenNames: childrenNames,
            ages: ages,
            province: this.province ?? "",
            subscriptionType: "projecte",
        };
    }

    async getProfileImage(): Promise<string> {
        const response = await functionsApp.httpsCallable(
            "users-getProfileImage"
        )({ userId: this.userId });
        this.avatarUrl = response.data as string;
        return response.data as string;
    }

    static async getKidInfoFromId(kidId: string[]) {
        const response = await functionsApp.httpsCallable(
            "users-getKidsFromIds"
        )({ kidsIds: kidId });
        return response.data as KidInfo[];
    }
}

export interface UserDataTable {
    id: string;
    childrenNames: string;
    ages: string;
    province: string;
    subscriptionType: string;
}

export interface PostUserInformation {
    children: { [key: string]: KidInfo };
    parentsIds?: string[];
    role?: "kid" | "instructor" | "admin";
    userId: string;
    parentsInformation?: ParentInformation[];
    authUid?: string[];
    province: string;
    subscription?: Subscription[];
    avatarFile?: File;
    avatarBase64?: string;
}
