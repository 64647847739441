import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { AdminNewProject } from "./newProject/AdminNewProject";
import { AdminProjectLevels } from "./projectLevels/AdminProjectLevels";
import { AdminProjectsList } from "./projectsList/AdminProjectsList";

export const AdminProjects = () => {
    const match = useRouteMatch();
    return (
        <Switch>
            <Route path={`${match.path}/projects-list`}>
                <AdminProjectsList />
            </Route>
            <Route path={`${match.path}/new-project`}>
                <AdminNewProject />
            </Route>
            <Route
                path={`${match.path}/:projectId/levels`}
                component={AdminProjectLevels}
            />
            <Route
                path={`${match.path}/:projectId`}
                render={(props) => (
                    <Redirect
                        to={`${match.path}/${props.match.params.projectId}/levels`}
                    />
                )}
            ></Route>

            <Route path={`${match.path}`}>
                <Redirect to={`${match.path}/projects-list`} />
            </Route>
        </Switch>
    );
};
