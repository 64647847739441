import React, { useEffect, useState } from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvidedDragHandleProps,
    Droppable,
} from "react-beautiful-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { ApiService } from "../../../../../../../apis/ApiService";
import { PostLevelStep } from "../../../../../../../apis/ProjectInterfaces";
import {
    Button,
    SaveDiscardButtons,
} from "../../../../../../../components/ui/button/Button";
import { FileInput } from "../../../../../../../components/ui/input/fileInput/FileInput";
import styles from "./SimpleInstructionsCreator.module.css";
import { v4 as uuidv4 } from "uuid";
import {
    checkObjectEquality,
    getBase64,
} from "../../../../../../../utils/functions";
import { Loading } from "../../../../../../../components/ui/loading/Loading";
import { useTranslation } from "react-i18next";

interface GalleryImage {
    file?: File;
    firestoreUrl?: string;
    internalUrl?: string;
    filename?: string;
    old?: boolean;
}
export const SimpleInstructionsCreator = (props: {
    step: PostLevelStep;
    onSend: (newStep: PostLevelStep) => Promise<boolean>;
    onChanges: (newChanges: boolean) => void;
}) => {
    const { onSend, onChanges } = props;
    const [images, setImages] = useState<GalleryImage[]>([]);
    const [loading, setLoading] = useState(false);
    const [changes, setChanges] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    const imgUploadForm = useForm({ mode: "onBlur" });
    const { projectId, levelId } =
        useParams<{
            projectId: string;
            levelId: string;
        }>();
    const { t } = useTranslation();

    const onImageGalleryChange = (newImages: GalleryImage[]) => {
        setImages(newImages);
    };

    async function addNewImages() {
        let newImages: GalleryImage[] = imgUploadForm
            .watch()
            .newImages.map((val: File) => ({ file: val }));

        let formattedImages = await getImagesProperties(newImages);
        setImages([...images, ...formattedImages]);

        imgUploadForm.reset();
    }

    async function checkChanges() {
        let formatData = await formatInstructionsCreatorResults(images);
        if (checkObjectEquality(props.step.data, formatData) && changes) {
            setChanges(false);
            onChanges(false);
        } else if (
            !checkObjectEquality(props.step.data, formatData) &&
            !changes
        ) {
            setChanges(true);
            onChanges(true);
        }
    }

    function formatInstructionsCreatorResults(data: GalleryImage[]) {
        return { imageGallery: data.map((image) => image.filename) };
    }

    async function getImagesProperties(newImages: GalleryImage[]) {
        let promises: Promise<any>[] = [];
        newImages.forEach((image, i) => {
            if (!image.filename && image.file) {
                var dotIndex = image.file.name.indexOf(".");
                newImages[i].filename =
                    uuidv4() + image.file.name.substring(dotIndex);
            }
            if (image.filename && !image.firestoreUrl && image.old) {
                promises.push(
                    ApiService.getStepFile(
                        projectId,
                        image.filename,
                        levelId,
                        props.step.id!
                    ).then((res) => (newImages[i].firestoreUrl = res))
                );
            }
            if (image.file && !image.internalUrl) {
                newImages[i].internalUrl = URL.createObjectURL(image.file);
            }
        });
        return await Promise.all(promises).then(() => newImages);
    }

    function onDiscard() {}
    async function onSave() {
        if (uploaded || uploading) return;
        setUploading(true);
        let newData = await formatInstructionsCreatorResults(images);
        let newStep: PostLevelStep = {
            type: props.step.type,
            data: newData as any,
            id: props.step.id,
        };
        let promises: Promise<any>[] = [];
        images
            .filter((image) => !image.old && image.file)
            .forEach((image) => {
                promises.push(
                    getBase64(image.file!).then((base64) =>
                        ApiService.postStepFile(
                            projectId,
                            levelId,
                            props.step.id!,
                            base64,
                            image.filename!
                        )
                    )
                );
            });
        await Promise.all(promises);
        const success = onSend(newStep);
        if (success) setUploaded(true);
        setUploading(false);
    }

    useEffect(() => {
        setLoading(true);
        if (props.step.type === "SimpleInstructions") {
            getImagesProperties(
                props.step.data.imageGallery.map((val) => {
                    return { filename: val, old: true };
                })
            )
                .then((res) => setImages(res))
                .catch(() => {})
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    checkChanges();

    return (
        <>
            {images.length > 0 ? (
                <>
                    <h2>{t("Sort the instructions")}</h2>
                    {loading ? <Loading /> : undefined}
                    <ImageGallery
                        imageGallery={images}
                        onImageGalleryChange={onImageGalleryChange}
                    />
                </>
            ) : null}
            <h2>{t("Add new images")}</h2>
            <FormProvider {...imgUploadForm}>
                <form>
                    <div className={styles.newImagesContainer}>
                        <FileInput
                            inputProps={{
                                name: "newImages",
                                multiple: true,
                                accept: "image/jpeg",
                            }}
                            height={200}
                            maxFiles={20}
                        />
                        <Button
                            text={t("Add the selected images")}
                            onPress={addNewImages}
                            divProps={{
                                className: styles.uploadImagesButton,
                            }}
                            disabled={
                                imgUploadForm.watch().newImages === undefined
                            }
                        />
                    </div>
                </form>
            </FormProvider>
            {changes ? (
                <SaveDiscardButtons
                    onDiscard={onDiscard}
                    onSave={onSave}
                    uploading={uploading}
                />
            ) : undefined}
        </>
    );
};

const ImageGallery = (props: {
    imageGallery: GalleryImage[];
    onImageGalleryChange: Function;
}) => {
    let { imageGallery, onImageGalleryChange } = props;

    function reorder(list: any[], startIndex: number, endIndex: number) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        let changedSteps = reorder(
            imageGallery,
            result.source.index,
            result.destination.index
        );

        onImageGalleryChange(changedSteps);
    }

    return (
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            <Droppable droppableId="imagesDroppable" direction="horizontal">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{}}
                        className={styles.gridList}
                    >
                        {imageGallery.map(
                            (image: GalleryImage, ind: number) => (
                                <Draggable
                                    key={ind}
                                    draggableId={ind.toString()}
                                    index={ind}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <InstructionsStep
                                                url={
                                                    image.firestoreUrl ??
                                                    image.internalUrl!
                                                }
                                                index={ind}
                                                dragHandleProps={
                                                    provided.dragHandleProps
                                                }
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            )
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

const InstructionsStep = (props: {
    url: string;
    index: number;
    dragHandleProps?: DraggableProvidedDragHandleProps;
}) => {
    const { url, index, dragHandleProps } = props;
    return (
        <div>
            <img
                src={url}
                {...dragHandleProps}
                alt={index.toString()}
                className={styles.imageContainer}
            />
        </div>
    );
};
