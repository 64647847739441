import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { AdminDistributionsList } from "./distributionsList/AdminDistributionsList";
import { AdminNewDistribution } from "./newDistribution/AdminNewDistribution";

export const AdminProjectsDistribution = () => {
    const match = useRouteMatch();
    return (
        <Switch>
            <Route path={`${match.path}/distributions-list`}>
                <AdminDistributionsList />
            </Route>
            <Route path={`${match.path}/new-distribution`}>
                <AdminNewDistribution />
            </Route>
            <Route path={`${match.path}/edit-distribution/:distributionId`}>
                <AdminNewDistribution />
            </Route>
            <Route path={`${match.path}`}>
                <Redirect to={`${match.path}/distributions-list`} />
            </Route>
        </Switch>
    );
};
