import { Modal, TextField } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Feedback, ParticipantsTeam } from "../../../apis/ParticipantsTeam";
import { SaveDiscardButtons } from "../../../components/ui/button/Button";
import { Card } from "../../../components/ui/card/Card";
import {
    CustomSnackbar,
    SnackbarProps,
} from "../../../components/ui/snackbar/Snackbar";
import { AuthContext } from "../../../provider/Auth";

import styles from "./FeedbackModal.module.css";

export const FeedbackModal = (props: {
    visible: boolean;
    onClose: () => void;
    feedback?: Feedback;
    levelId: string;
    teamId: string;
}) => {
    const [stars, setStars] = useState(props.feedback?.stars ?? 0);
    const [feedbackType] = useState("text");
    const [textFeedback, setTextFeedback] = useState(
        props.feedback?.content?.type === "text"
            ? props.feedback.content.text
            : ""
    );
    const [uploading, setUploading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackbarProps>({ message: "" });

    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { t } = useTranslation();

    const saveDisabled = stars === 0 || textFeedback === "";

    async function onSave() {
        if (stars === 0 || textFeedback === "") return;
        const feedback: Feedback = {
            content: {
                type: "text",
                text: textFeedback,
            },
            stars: stars,
            instructorId: authContext!.userId!,
        };
        setUploading(true);
        ParticipantsTeam.postFeedback(props.teamId, props.levelId, feedback)
            .then(() => {
                setSnackbar({
                    message: t("Feedback sent correctly!"),
                    type: "success",
                });
                setTimeout(() => history.go(0), 3000);
            })
            .catch(() =>
                setSnackbar({
                    message: t(
                        "We can't reach our end. Please try it again later"
                    ),
                    type: "error",
                })
            )
            .finally(() => setUploading(false));
    }

    function onDiscard() {
        props.onClose();
    }

    return (
        <Modal
            open={props.visible}
            onClose={props.onClose}
            className={styles.container}
        >
            <Card className={styles.card}>
                <h1>{t("Stars")}</h1>
                <Rating
                    value={stars}
                    onChange={(_, val) => setStars(val!)}
                    max={3}
                    size="large"
                    className={styles.rating}
                />
                <h1 className={styles.feedbackTitle}>{t("Message")}</h1>
                {feedbackType === "text" ? (
                    <TextField
                        multiline
                        className={styles.textFeedback}
                        onChange={(ev) => setTextFeedback(ev.target.value)}
                        value={textFeedback}
                    />
                ) : undefined}
                <SaveDiscardButtons
                    onSave={onSave}
                    onDiscard={onDiscard}
                    uploading={uploading}
                    saveDisabled={saveDisabled}
                />
                <CustomSnackbar
                    handleSnackbarClose={(snackbar) => setSnackbar(snackbar)}
                    snackbar={snackbar}
                />
            </Card>
        </Modal>
    );
};
