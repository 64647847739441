import { Chip, MenuItem, Select } from "@material-ui/core";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next/";

import styles from "./SelectInput.module.css";

export interface SelectOptionProps {
    display: string;
    value: any;
}

export interface SelectInputProps {
    /** Must be a string[] if multiple is true */
    defaultValue?: string | string[];
    /** The name of the input, will be used to get results */
    name: string;
    /** Whether it is required or not */
    mandatory?: boolean;
    /** An array of options */
    options: SelectOptionProps[];
    /** Multiple options may be selected or not. If true, default value must be a string[] */
    multiple?: boolean;
    /** The selected option/s to be styled as chips or not */
    chips?: boolean;
    /** Style a box surrounding the input or not */
    box?: boolean;
    style?: React.CSSProperties;
    className?: string;
    changeValue?: string;
}

export const SelectInput: React.FC<SelectInputProps> = (props) => {
    const { errors, setValue } = useFormContext();
    const errorMessage = errors[props.name];
    const { t } = useTranslation();

    useEffect(() => {
        if (props.changeValue) setValue(props.name, props.changeValue);
    }, [props.changeValue]);

    function getDisplay(values?: string | string[]) {
        if (!values) return "";
        if (typeof values === "string")
            return (
                props.options.find((option) => option.value === values)
                    ?.display ?? ""
            );
        else
            return values.map(
                (value) =>
                    props.options.find((option) => option.value === value)
                        ?.display ?? ""
            );
    }

    return (
        <div
            className={` ${styles.formControl} ${props.className}`}
            style={props.style}
        >
            <Controller
                name={props.name}
                rules={{
                    required: props.mandatory,
                }}
                defaultValue={props.defaultValue ?? (props.multiple ? [] : "")}
                render={(field) => (
                    <Select
                        {...field}
                        labelId={props.name}
                        inputProps={{ id: props.name }}
                        multiple={props.multiple}
                        className={` ${styles.fullWidth} ${
                            props.box ? `${styles.selectBorder} shadow` : ""
                        }`}
                        renderValue={(selected: any) => (
                            <div className={styles.selected}>
                                {props.chips ? (
                                    returnChips(getDisplay(selected))
                                ) : (
                                    <p>{returnWords(getDisplay(selected))}</p>
                                )}
                            </div>
                        )}
                    >
                        {props.options.map((option, ind) => (
                            <MenuItem
                                key={ind}
                                value={option.value}
                                className="capitalize"
                            >
                                {option.display}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
            {errorMessage ? (
                <p className="error">{t("This is mandatory")}</p>
            ) : null}
        </div>
    );

    function returnChips(values: string | string[]) {
        if (typeof values == "string") {
            return <Chip key={values} label={values} className={styles.chip} />;
        } else
            return values.map((value) => {
                return (
                    <Chip key={value} label={value} className={styles.chip} />
                );
            });
    }

    function returnWords(values: string | string[]) {
        if (typeof values == "string") {
            return values;
        } else return values.join(", ");
    }
};
