import React, { useEffect } from "react";
import "./App.css";
import { NavigationBar } from "./components/menu/Navbar";
import {
    Route,
    BrowserRouter as Router,
    Switch,
    Redirect,
    useHistory,
} from "react-router-dom";
import { Administration } from "./pages/administration/Administration";
import { Calendar } from "./pages/calendar/Calendar";
import { Configuration } from "./pages/configuration/Configuration";
import { Home } from "./pages/home/Home";
import { Messages } from "./pages/messages/Messages";
import { Payrolls } from "./pages/payrolls/Payrolls";
import { Projects } from "./pages/projects/Projects";
import { AuthProvider } from "./provider/Auth";
import { PrivateRoute } from "./PrivateRoute";
import { SignIn } from "./pages/login/SignIn";
import { Classes } from "./pages/classes/Classes";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { caES } from "@material-ui/core/locale";
import { SuccessPage } from "./components/ui/success/SuccessPage";
import { Project } from "./apis/Project";
import { ApiService } from "./apis/ApiService";

const theme = createMuiTheme({}, caES);
/** The Auth Provider *provides* the whole app with authentication. The login page is always
 * available. Everything under PrivateRoute is protected and can only be accessed if the
 * user is logged in and has enough permissions
 */
function App() {
    useEffect(() => {
        document.title = "Roboclick Instructors";
    });
    return (
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <div className="app-container">
                    <Router>
                        <Switch>
                            <Route path="/login/:invalidRole?">
                                <SignIn />
                            </Route>
                            <PrivateRoute routeProps={{ path: "/" }}>
                                <AppContent />
                            </PrivateRoute>
                        </Switch>
                    </Router>
                </div>
            </ThemeProvider>
        </AuthProvider>
    );
}

/** This is the content of the App once the user has logged in and has enough permissions */
function AppContent() {
    const history = useHistory();
    return (
        <>
            <div className="app-navigation-container">
                <NavigationBar />
            </div>
            <div className="app-body-container">
                <Switch>
                    <Route path="/" exact>
                        <Redirect to="/home" />
                    </Route>
                    <Route path="/home">
                        <Home />
                    </Route>
                    <Route path="/projects">
                        <SuccessPage
                            buttons={[
                                {
                                    onPress: () => history.goBack(),
                                    text: "Torna enrere",
                                },
                            ]}
                            message={
                                "Ben aviat podrem consultar els projectes a la plataforma!"
                            }
                        />
                    </Route>
                    <Route path="/classes">
                        <Classes />
                    </Route>
                    <Route path="/messages">
                        <SuccessPage
                            buttons={[
                                {
                                    onPress: () => history.goBack(),
                                    text: "Torna enrere",
                                },
                            ]}
                            message={
                                "Ben aviat podrem rebre i enviar missatges!"
                            }
                        />
                    </Route>
                    <Route path="/payrolls">
                        <SuccessPage
                            buttons={[
                                {
                                    onPress: () => history.goBack(),
                                    text: "Torna enrere",
                                },
                            ]}
                            message={
                                "Properament podrem consultar les nòmines aquí!"
                            }
                        />
                    </Route>
                    <Route path="/calendar">
                        <Calendar />
                    </Route>
                    <Route path="/configuration">
                        <SuccessPage
                            buttons={[
                                {
                                    onPress: () => history.goBack(),
                                    text: "Torna enrere",
                                },
                            ]}
                            message={
                                "Imminentment podrem configurar la nostra experiència!"
                            }
                        />
                    </Route>
                    <Route path="/administration">
                        <Administration />
                    </Route>
                    <Route path="*">
                        <h1>Could not locate this page</h1>
                    </Route>
                </Switch>
            </div>
        </>
    );
}
export default App;
