import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

export interface SnackbarProps {
    message: string;
    type?: "error" | "info" | "success" | "warning";
}

export interface CustomSnackbarProps {
    /** If its message is empty, it won't show */
    snackbar: SnackbarProps;
    /** Should probably be:
     * ```ts
     * setSnackbar(snackbar)
     * ```  */
    handleSnackbarClose: (snackbar: SnackbarProps) => void;
    /** @defaultValue 3000 */
    autoHideDuration?: number;
}

/** A custom snackbar. It will appear at the bottom of the page and will show the message
 * passed on the props. If snackbar's message is '' the snackbar will close */
export const CustomSnackbar = (props: CustomSnackbarProps) => {
    const { snackbar, handleSnackbarClose, autoHideDuration } = props;
    let emptySnackbar: SnackbarProps = { ...props.snackbar, message: "" };
    return (
        <Snackbar
            open={snackbar.message !== ""}
            autoHideDuration={autoHideDuration ?? 3000}
            onClose={() => handleSnackbarClose(emptySnackbar)}
        >
            <Alert
                onClose={() => handleSnackbarClose(emptySnackbar)}
                severity={snackbar.type}
            >
                {snackbar.message}
            </Alert>
        </Snackbar>
    );
};
