import { InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./TextInput.module.css";

export const TextInput: React.FC<{
    name: string;
    defaultValue?: string;
    mandatory?: boolean;
    multiline?: boolean;
    placeholder?: string;
    style?: React.CSSProperties;
    className?: string;
    label?: string;
    rows?: number;
    inputAdornments?: {
        start?: any;
        end?: any;
    };
    type?: string;
    pattern?: RegExp;
    errorMessage?: string;
    short?: boolean;
    changeValue?: string;
}> = (props) => {
    const { errors, register, setValue } = useFormContext();
    const errorMessage = errors[props.name];
    const { t } = useTranslation();

    useEffect(() => {
        if (props.changeValue) setValue(props.name, props.changeValue);
    }, [props.changeValue]);

    return (
        <div
            className={`${styles.textInputContainer} ${props.className} ${
                props.short ? styles.short : ""
            }`}
            style={props.style}
        >
            <TextField
                label={props.label}
                placeholder={props.placeholder}
                name={props.name}
                id={props.name}
                multiline={props.multiline}
                inputRef={register({
                    required: props.mandatory,
                    pattern: props.pattern
                        ? props.errorMessage
                            ? {
                                  value: props.pattern,
                                  message: props.errorMessage,
                              }
                            : props.pattern
                        : undefined,
                })}
                defaultValue={props.defaultValue}
                className={`${styles.textField}`}
                rows={props.rows}
                type={props.type}
                InputProps={{
                    startAdornment: props.inputAdornments?.start ? (
                        <InputAdornment position="start">
                            {props.inputAdornments.start}
                        </InputAdornment>
                    ) : undefined,
                    endAdornment: props.inputAdornments?.end ? (
                        <InputAdornment position="end">
                            {props.inputAdornments.end}
                        </InputAdornment>
                    ) : undefined,
                }}
            />
            {errorMessage ? (
                <p className="error" id={`${props.name}Error`}>
                    {errorMessage.message
                        ? errorMessage.message
                        : errorMessage.type === "pattern"
                        ? t("There is an error with the introduced values")
                        : t("This is mandatory")}
                </p>
            ) : null}
        </div>
    );
};
