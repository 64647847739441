import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";
import { ClassDetails } from "./classDetails/ClassDetails";
import { ClassList } from "./classList/ClassList";
import { ClassProgress } from "./classProgress/ClassProgress";

export const Classes = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/class-details/:classId/class-progress`}>
                <ClassProgress />
            </Route>

            <Route path={`${match.path}/class-details/:classId`}>
                <ClassDetails />
            </Route>
            <Route path={`${match.path}/class-list`}>
                <ClassList />
            </Route>
            <Route path={`${match.path}`}>
                <Redirect to={`${match.path}/class-list`} />
            </Route>
        </Switch>
    );
};
